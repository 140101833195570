import { Modal } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { ml } from '../../../services/utils';
import OnDeckReferralForm from './OnDeckReferralFormComponent.jsx';

class OnDeckReferralModalComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			autoCompleteResult: [],
			isSubmitting: false,
			error: false,
			buttonState: '',
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
		};
	}

	componentDidMount() {
		if (this.props.matchFound) {
			this.setState({ autoCompleteResult: [this.props.matchFound] });
		}

		if (this.props.contact) {
			this.setState({ autoCompleteResult: [this.props.contact] });
		}
	}

	handleError = () => {
		this.setState({ error: true });
		this.setState({ buttonState: 'error' });
	};

	toggleIsSubmitting = () => {
		this.setState({ buttonState: 'loading' });
		this.setState((prevState) => ({ isSubmitting: !prevState.isSubmitting }));
		setTimeout(() => {
			this.setState({ buttonState: 'success' });
		}, 5400);
	};

	render() {
		const {
			contacts,
			onDeckContacts,
			referrals,
			onCreateContact,
			onCreateReferral,
			onUpdateContact,
			currentUser,
			customGeneralModal = {},
			onUpdateNotification,
			visible,
			enterReferralText,
			enterReferralInformationText,
			firstNameText,
			lastNameText,
			emailText,
			textPlaceHolderText,
			orText,
			clickHereText,
			toAddExistingContactText,
			includeMessageToContactText,
			optionalText,
			personalizeMessageText,
			messageHiringContactText,
			howKnowThemText,
			clickHereResumeText,
			attachResumeText,
			submitReferralText,
			companyData,
			filteredJobs,
			client,
			onUpdateJob,
			onCreateUserJobShare,
			onUpdateUserJobShare,
		} = this.props;
		const {
			isSubmitting,
			error,
			buttonState,
		} = this.state;

		const pointsSettings = get(companyData, 'pointsSettings');
		let generalReferralPoints = null;
		if (pointsSettings !== null && pointsSettings !== undefined) {
			const pointsSettingsData = JSON.parse(pointsSettings);
			if (pointsSettingsData.enabled) {
				generalReferralPoints = get(pointsSettingsData, 'generalReferral');
			}
		}

		const headerText =
			customGeneralModal?.headerText ??
			ml(
				'Submit General Referral',
				currentUser,
				this.props.allMultiLingualData
			);
		const subText =
			customGeneralModal?.subText ??
			'General Referrals will allow your contact to accept and apply for a generic position and be considered for other roles within the organization';

		return (
			<Modal
				centered
				open={visible}
				footer={null}
				title={headerText}
				width={600}
				onCancel={this.props.handleCancel}
			>
				<>
					<p className="text-center small">
						<strong style={{ color: '#000' }}>{subText}</strong>
					</p>
					<OnDeckReferralForm
						client={client}
						contacts={contacts}
						customGeneralModal={customGeneralModal}
						isSubmitting={isSubmitting}
						toggleIsSubmitting={this.toggleIsSubmitting}
						error={error}
						handleError={this.handleError}
						handleCancel={this.props.handleCancel}
						currentUser={currentUser}
						matchFound={this.props.matchFound}
						notification={this.props.notification}
						referrals={referrals}
						visible={visible}
						buttonState={buttonState}
						enterReferralText={enterReferralText}
						enterReferralInformationText={enterReferralInformationText}
						firstNameText={firstNameText}
						lastNameText={lastNameText}
						emailText={emailText}
						textPlaceHolderText={textPlaceHolderText}
						orText={orText}
						clickHereText={clickHereText}
						toAddExistingContactText={toAddExistingContactText}
						includeMessageToContactText={includeMessageToContactText}
						optionalText={optionalText}
						personalizeMessageText={personalizeMessageText}
						messageHiringContactText={messageHiringContactText}
						howKnowThemText={howKnowThemText}
						clickHereResumeText={clickHereResumeText}
						attachResumeText={attachResumeText}
						submitReferralText={submitReferralText}
						generalReferralPoints={generalReferralPoints}
						allMultiLingualData={this.props.allMultiLingualData}
						filteredJobs={filteredJobs}
						companyData={companyData}
						onDeckContacts={onDeckContacts}
						onCreateContact={onCreateContact}
						onCreateReferral={onCreateReferral}
						onUpdateContact={onUpdateContact}
						onUpdateNotification={onUpdateNotification}
						onUpdateJob={onUpdateJob}
						onCreateUserJobShare={onCreateUserJobShare}
						onUpdateUserJobShare={onUpdateUserJobShare}
					/>
				</>
			</Modal>
		);
	}
}

export default withApollo(OnDeckReferralModalComponent);
