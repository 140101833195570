import '@ant-design/compatible/assets/index.css';
import { Editor } from '@tinymce/tinymce-react';
import {
	Button,
	Checkbox,
	Form,
	Input,
	Modal,
	Popconfirm,
	Select,
	message,
} from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dompurify from 'dompurify';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _, { get } from 'lodash';
import { Component, createRef } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import ProgressButton from 'react-progress-button';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { USER_ROLES } from 'src/_shared/constants/';
import { StringParser } from 'src/_shared/services/';
import { getSetErrorImageURL, logout, ml } from 'src/_shared/services/utils.js';
import fileIcon from '../_shared/assets/erin_lightgray.png';

const sanitizer = dompurify.sanitize;
const { confirm } = Modal;
dayjs.extend(customParseFormat);

class AnnouncementsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			visible: false,
			lengthError: false,
			loaded: false,
			buttonState: '',
			edit: false,
			editorKey: 4,
			announcementContent: '',
			filteredGroups: [],
			selectedGroupsByUser: [],
			editedGroup: [],
			displayGroupSelect: false,
			errorImageURL: '',
			userGroups: [],
		};
		this.formRef = createRef();
	}

	async componentDidMount() {
		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		const { currentUser } = this.props;
		const errorImageURL = await getSetErrorImageURL(
			currentUser?.company?.errorImage?.key
		);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}
	}

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.error(error);
		}
	}

	GroupItem = (announcement) => {
		const { onUpdateAnnouncement } = this.props;
		let multiSelectDefaultValue = [];
		const { userGroups } = announcement;
		multiSelectDefaultValue = JSON.parse(userGroups);
		const removeJob = (jobId) => {
			const uGrp = multiSelectDefaultValue.filter(
				(group) => group.key != jobId
			);
			const input = {
				input: {
					id: announcement.id,
					companyId: announcement.companyId,
					title: announcement.title,
					content: announcement.content,
					hide: announcement.hide,
					userGroups: JSON.stringify(uGrp),
				},
			};
			onUpdateAnnouncement(input);
		};

		return (
			<ul className="tag-wrap">
				{multiSelectDefaultValue
					? multiSelectDefaultValue.map((group) => {
							return (
								<li key={group.key} className="tag green">
									<span className="tag-name"> {group.label}</span>
									<Popconfirm
										placement="left"
										title="Are you sure？"
										okText="Delete"
										cancelText="Cancel"
										onConfirm={() => removeJob(group.key, group.label)}
									>
										<i className="tag-close icon-cross" />
									</Popconfirm>
								</li>
							);
						})
					: null}
			</ul>
		);
	};

	onFinishFailed = () => {
		this.setState({
			buttonState: 'error',
		});
	};

	addAnnouncement = (values) => {
		const { onCreateAnnouncement, onUpdateAnnouncement, currentUser } =
			this.props;
		const {
			announcementContent,
			edit,
			announcementModal,
			filteredGroups,
			userGroups,
			displayGroupSelect,
		} = this.state;
		this.setState({
			buttonState: 'loading',
		});
		if (edit) {
			const input = {
				input: {
					id: get(announcementModal, 'id'),
					companyId: get(currentUser, 'companyId'),
					title: values.title,
					content: announcementContent,
					hide: this.state.hide,
					displayGroupSelect: this.state.displayGroupSelect,
				},
			};
			if (displayGroupSelect === false) {
				input.input.userGroups = JSON.stringify([]);
			} else if (userGroups.length > 0) {
				input.input.userGroups = JSON.stringify(userGroups);
			} else {
				input.input.userGroups = '[]';
			}

			onUpdateAnnouncement(input);
			message.success('Announcement is updated successfully.', 2);
		} else {
			const input = {
				input: {
					companyId: get(currentUser, 'companyId'),
					title: values.title,
					content: announcementContent,
					hide: this.state.hide,
					displayGroupSelect: this.state.displayGroupSelect,
				},
			};
			if (displayGroupSelect === false) {
				input.input.userGroups = JSON.stringify([]);
			} else if (userGroups.length > 0) {
				input.input.userGroups = JSON.stringify(userGroups);
			} else {
				input.input.userGroups = '[]';
			}

			onCreateAnnouncement(input);
			message.success('Announcement is added successfully.', 2);
		}

		this.handleClose();
	};

	cancelEdit = () => {
		message.error('Edit Cancelled');
		this.setState({
			edit: false,
		});
	};

	clearEditor = () => {
		const newKey = this.state.editorKey * 43;
		this.setState({
			editorKey: newKey,
		});
	};

	deleteAnnouncement = (announcement) => {
		const { onDeleteAnnouncement } = this.props;
		const { id } = announcement;
		const input = { input: { id } };
		try {
			onDeleteAnnouncement(input);
			message.success('Announcement Deleted', 2);
		} catch (error) {
			console.error('>> DELETE ANNOUNCEMENT ERROR', error);
		}
	};

	deleteIcon = (announcement) => (
		<Button
			type="link"
			className="btn-link danger"
			onClick={() => this.showDeleteAnnouncementConfirm(announcement)}
		>
			<i className="icon-bin" />
			Delete
		</Button>
	);

	editIcon = (announcement) => (
		<Button
			type="link"
			className="btn-link"
			onClick={() => this.editInfo(announcement)}
		>
			<i className="icon-edit" />
			Edit
		</Button>
	);

	editInfo = (announcement) => {
		const groups = [];
		const groupsData = get(announcement, 'userGroups')
			? JSON.parse(announcement.userGroups)
			: [];
		if (groupsData.length > 0) {
			for (const item of groupsData) {
				groups.push({ key: item.key, label: item.label });
			}
		}

		this.setState({
			visible: true,
			edit: true,
			announcementModal: announcement,
			hide: get(announcement, 'hide', false),
			announcementContent:
				announcement.content === undefined ? '' : announcement.content,
			userGroups: groups,
			displayGroupSelect: get(announcement, 'displayGroupSelect', false),
		});
	};

	formatDate(date, languageCode) {
		if (!date) return;
		const isoDate = new Date(date);
		const month = isoDate.getMonth() + 1;
		const day = isoDate.getDate();
		const year = isoDate.getFullYear();
		let hours = isoDate.getHours();
		const mins = ('0' + isoDate.getMinutes()).slice(-2);
		const ampm = hours >= 12 ? 'pm' : 'am';
		hours %= 12;
		hours = hours ? hours : 12;
		if (
			(date !== null && languageCode === 'FR') ||
			languageCode === 'DE' ||
			languageCode === 'ES' ||
			languageCode === 'RU'
		) {
			return `${day}/${month}/${year} ${hours}${':'}${mins}`;
		}

		return `${month}/${day}/${year} ${hours}${':'}${mins}${' '}${ampm}`;
	}

	handleCheckboxOnChange = ({ setting, value }) => {
		this.setState({
			[setting]: value,
		});
	};

	handleClose = () => {
		this.clearEditor();
		this.formRef.current.resetFields();
		this.setState({
			visible: false,
			edit: false,
			announcementContent: '',
			userGroups: [],
			hide: false,
			buttonState: '',
			displayGroupSelect: false,
		});
	};

	handleEditorChange = (content) => {
		this.setState({
			announcementContent: content,
		});
	};

	handleFilterGroups = (group) => {
		const { filteredGroups } = this.state;
		if (filteredGroups.includes(group)) return;

		this.setState((prevState) => ({
			filteredGroups: [...prevState.filteredGroups, group],
		}));
	};

	handleHideAnnouncement = (value) => {
		this.setState({
			hide: value.target.checked,
		});
	};

	handleRemoveGroupsFilter = (group) => {
		this.setState((prevState) => ({
			filteredGroups: prevState.filteredGroups.filter((item) => item !== group),
		}));
	};

	handleRemoveGroupsFilter = (group) => {
		const { selectedGroupsByUser, filteredGroups } = this.state;
		const index = filteredGroups.indexOf(group);
		const filterGroups = (item) => item !== filteredGroups[index];
		const groups =
			filteredGroups.length === 1 ? [] : filteredGroups.filter(filterGroups);
		const allSelectedGroups = selectedGroupsByUser.filter(
			(item) => item.id !== group
		);
		this.setState({
			filteredGroups: groups,
			selectedGroupsByUser: allSelectedGroups,
		});
	};

	handleRemoveSelect = (group, multiSelectDefaultValue) => {
		const { userGroups } = this.state;
		const values = userGroups.filter((item) => {
			return item.key !== group.key;
		});

		this.setState({
			userGroups: values,
		});
	};

	handleSelect = (multiSelectDefaultValue, value) => {
		const { userGroups } = this.state;
		if (userGroups.includes(value)) return;

		this.setState((prevState) => ({
			userGroups: [...prevState.userGroups, value],
		}));
	};

	newTinyMce = () => {
		return (
			<div
				style={{
					height: 350,
					fontFamily: 'inherit',
					width: '100%',
					marginTop: 20,
				}}
			>
				<Editor
					key={this.state.editorKey}
					apiKey="folpz5iy2cdn4oje0s8vqakhxncbe6voeqvmbjk88k9127te"
					init={{
						height: 350,
						menubar: false,
						branding: false,
						plugins: ['image code media link'],
						toolbar:
							'undo redo | styleselect | fontselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent  | \
             media link image ',
						automatic_uploads: true,
						file_picker_types: 'image',
						max_width: '100%',
						placeholder: 'Content',
						content_style: 'img {max-width: 100%;}',
					}}
					value={sanitizer(this.state.announcementContent)}
					onEditorChange={this.handleEditorChange}
				/>
			</div>
		);
	};

	showDeleteAnnouncementConfirm = (announcement) => {
		confirm({
			title: 'Are you sure you want to delete this Announcement?',
			content: 'Deleting this announcement can not be undone.',
			okText: 'Confirm Delete',
			okType: 'danger',
			autoFocusButton: null,
			okButtonProps: { type: 'primary', danger: true },
			width: 500,
			cancelText: 'Cancel',
			onOk: () => this.deleteAnnouncement(announcement),
		});
	};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	renderAnnouncements = () => {
		const { currentUser, announcements, allMultiLingualData } = this.props;
		const languageCode = get(currentUser, 'languageCode');
		const displayAs = get(currentUser, 'displayAs');
		const userGroupId = get(currentUser, 'userGroupId');
		return announcements.map((announcement) => {
			const { userGroups } = announcement;
			let hasAccess = true;
			const multiSelectDefaultValue = [];
			const groups = userGroups ? JSON.parse(userGroups) : [];

			if (groups.length > 0) {
				const groupIdArray = groups.map((item) => {
					return item.key;
				});
				hasAccess = Boolean(groupIdArray.includes(userGroupId));
			}

			if (groups) {
				groups.map((item) => {
					multiSelectDefaultValue.push({ key: item.key, label: item.label });
				});
			}

			return (announcement.hide || !hasAccess) &&
				displayAs === USER_ROLES.EMPLOYEE ? null : (
				<div key={announcement.id} className="custom-card announcements-card">
					<div className="announcements-head">
						<h3>{announcement.title}</h3>
						<span className="announcements-time">
							{dayjs(new Date(announcement.dateCreated)).isValid
								? this.formatDate(announcement.dateCreated, languageCode)
								: this.formatDate(announcement.dateCreated, languageCode)}
						</span>
					</div>
					<div className="announcements-body">
						<div className="announcements-content-wrap">
							{displayAs === USER_ROLES.ADMIN &&
								multiSelectDefaultValue.length > 0 && (
									<div className="announcements-group">
										<span className="announcements-text">
											{ml('User Groups', currentUser, allMultiLingualData)} :
										</span>
										{this.GroupItem(announcement)}
									</div>
								)}
							<div className="announcements-content">
								<StringParser
									text={announcement.content}
									breakOn="2"
									showMoreText={ml(
										'view more',
										currentUser,
										allMultiLingualData
									)}
									showLessText={ml(
										'view less',
										currentUser,
										allMultiLingualData
									)}
								/>
							</div>
						</div>
						<div className="announcements-action">
							{displayAs === USER_ROLES.ADMIN && this.editIcon(announcement)}
							{displayAs === USER_ROLES.ADMIN && this.deleteIcon(announcement)}
						</div>
					</div>
				</div>
			);
		});
	};

	render() {
		const {
			allMultiLingualData,
			currentUser,
			announcements,
			userGroups = [],
		} = this.props;
		const options = [];
		for (const [index, group] of _.sortBy(userGroups, [
			(group) => group.name,
		]).entries()) {
			const option = <Select.Option key={group.id}>{group.name}</Select.Option>;
			options.push(option);
		}

		const { visible, edit, announcementModal, errorImageURL } = this.state;
		const multiSelectDefaultValue = [];
		const groups = get(announcementModal, 'userGroups')
			? JSON.parse(get(announcementModal, 'userGroups'))
			: [];
		if (groups) {
			groups.map((item) => {
				multiSelectDefaultValue.push({ key: item.key, label: item.label });
			});
		}

		const displayAs = get(currentUser, 'displayAs');
		const whiteLabel = get(currentUser, 'company.whiteLabel');

		if (!get(currentUser, 'company')) return <Spinner />;
		return (
			<main>
				<div className="page-title">
					<h2 className="page-heading">
						{ml('Announcements', currentUser, allMultiLingualData)}
					</h2>
					<ul className="info-action">
						<li>
							{displayAs === USER_ROLES.ADMIN && (
								<Button
									type="link"
									className="add-btn btn-lg"
									onClick={this.showModal}
								>
									<span className="icon-circle">
										<i className="icon-plus" />
									</span>
									{ml('Add Announcement', currentUser, allMultiLingualData)}
								</Button>
							)}
						</li>
					</ul>
				</div>
				{announcements.length > 0 ? (
					this.renderAnnouncements()
				) : (
					<div className="no-content">
						{errorImageURL && whiteLabel ? (
							<img
								src={errorImageURL}
								alt="error image"
								className="no-content-icon"
							/>
						) : (
							<img alt="erin-logo" className="no-content-icon" src={fileIcon} />
						)}
						<p className="no-content-text">
							{ml('No', currentUser, allMultiLingualData)}{' '}
							{ml('Announcements', currentUser, allMultiLingualData)}
						</p>
					</div>
				)}
				<Modal
					destroyOnClose
					title={
						this.state.edit
							? ml('Update an Announcement', currentUser, allMultiLingualData)
							: ml('Create an Announcement', currentUser, allMultiLingualData)
					}
					open={visible}
					footer={null}
					onCancel={() => this.handleClose()}
				>
					<Form
						ref={this.formRef}
						initialValues={{
							userGroups: multiSelectDefaultValue,
							title: edit === true ? announcementModal.title : null,
						}}
						onFinish={this.addAnnouncement}
						onFinishFailed={this.onFinishFailed}
					>
						<div className="custom-form-group">
							<Form.Item name="title">
								<Input className="custom-input" placeholder="Title" />
							</Form.Item>
						</div>
						<div className="custom-form-group">
							<Checkbox
								checked={this.state.hide}
								onChange={(event) =>
									this.handleCheckboxOnChange({
										setting: 'hide',
										value: event.target.checked,
									})
								}
							>
								{ml('Hide From Employees', currentUser, allMultiLingualData)}
							</Checkbox>
						</div>
						<div className="custom-form-group">
							<Form.Item name="content">{this.newTinyMce()}</Form.Item>
						</div>
						<div className="custom-form-group">
							<Checkbox
								checked={this.state.displayGroupSelect}
								onChange={(event) =>
									this.handleCheckboxOnChange({
										setting: 'displayGroupSelect',
										value: event.target.checked,
									})
								}
							>
								{ml(
									'Only Show to Specific Employee Groups',
									currentUser,
									allMultiLingualData
								)}
							</Checkbox>
						</div>

						{this.state.displayGroupSelect ? (
							<div className="custom-form-group">
								<label className="custom-label">
									Choose Employee Group(s):
								</label>
								<div className="custom-form-green-select">
									<Form.Item name="userGroups">
										<Select
											showSearch
											labelInValue
											filterOption={(input, option) => {
												return option.props.children
													.toLowerCase()
													.includes(input.toLowerCase());
											}}
											showArrow={false}
											className="custom-input"
											maxTagCount={4}
											maxTagTextLength={8}
											dropdownMatchSelectWidth={false}
											placeholder="None"
											mode="multiple"
											onSelect={(value) =>
												this.handleSelect(multiSelectDefaultValue, value)
											}
											onDeselect={(group) =>
												this.handleRemoveSelect(group, multiSelectDefaultValue)
											}
										>
											{options}
										</Select>
									</Form.Item>
								</div>
							</div>
						) : null}

						<div className="modal-footer-btn">
							<ProgressButton
								controlled
								durationSuccess={3000}
								state={this.state.buttonState}
								type="submit"
							>
								{this.state.edit
									? ml('Update Announcement', currentUser, allMultiLingualData)
									: ml('Add Announcement', currentUser, allMultiLingualData)}
							</ProgressButton>
						</div>
					</Form>
				</Modal>
			</main>
		);
	}
}

export default withApollo(AnnouncementsComponent);
