import React, { Component } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Collapse, Select, message } from 'antd';
import { withApollo } from 'react-apollo';
import { Link, withRouter } from 'react-router-dom';
import {
	UpdateUserAside,
} from 'src/_shared/api/graphql/custom/users';
import { ListCompanies } from './listCompaniesGraphQL';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import { USER_ROLES } from 'src/_shared/constants';
import { COLORS } from 'src/_shared/styles/colors';
import { downloadFromS3, ml, parse } from 'src/_shared/services/utils.js';
import Logo from '../../assets/erinwhite.png';
import PoweredBy from '../../assets/poweredbyERIN.png';
import { ConditionalWrapper } from 'src/helpers/index.js';
import { withQueryParams } from 'src/_shared/services/index.js';

class BaseAside extends Component {
	constructor(props) {
		super(props);
		this.state = {
			companyData: props.companyData || props.currentUser?.company,
			textToCopy: '',
			companies: [],
		};
		this._isMounted = false;
	}

	async componentDidMount() {
		this._isMounted = true;
		const { currentUser, queryParams, updateDisplay, updatePathname, location } = this.props;

		const pathname = location.pathname === '/'
			? '/dashboard'
			: location.pathname;
		updatePathname(pathname);
		const allCompaniesData = parse(currentUser.admin)?.permissions === '*' ?
			await this.listCompanies() : [];
		if (
			queryParams?.has('showGeneral') &&
			currentUser?.role === USER_ROLES.ADMIN
		) {
			updateDisplay(USER_ROLES.EMPLOYEE);
		} else {
			updateDisplay(currentUser.role);
		}

		if (this._isMounted) {
			this.setState({
				companies: allCompaniesData,
			});
		}
	}

	componentDidUpdate(prevProps) {
		// If the `companyData` changed in props, update local state
		if (prevProps.companyData !== this.props.companyData) {
			this.setState({
				companyData: this.props.companyData,
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	async listCompanies(nextToken = null, companiesList = []) {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: ListCompanies,
				variables: {
					id: currentUser.id,
					limit: 1000,
					nextToken,
				},
				fetchPolicy: 'network-only',
			});
			const fetched = data?.listCompanies?.items || [];
			const token = data?.listCompanies?.nextToken;
			let newCompaniesList = [...companiesList, ...fetched];

			if (token) {
				return await this.listCompanies(token, newCompaniesList);
			}

			newCompaniesList = newCompaniesList.sort((a, b) => {
				if (!a.disableSite !== !b.disableSite) {
					return a.disableSite ? 1 : -1;
				}
				const order = ['pro', 'partner', null, 'free'];
				const accountComparison = order.indexOf(a.accountType) - order.indexOf(b.accountType);
				if (accountComparison !== 0) {
					return accountComparison;
				}
				return a.name.localeCompare(b.name);
			});
			return newCompaniesList;
		} catch (error) {
			console.log('listCompanies Error:', error);
			return companiesList;
		}
	}

	handleCompanyLogo() {
		const { currentUser } = this.props;
		try {
			const s3Key = currentUser?.subCompany?.logo?.key || currentUser?.company?.logo?.key;
			return s3Key ? downloadFromS3(s3Key) : '';
		} catch (err) {
			console.log('handleCompanyLogo error:', err);
		}
	}

	onChangeCompany = async (companyId) => {
		const { client, currentUser, setCurrentUser } = this.props;
		this.handleClearCache();

		[
			'whiteLabel',
			'loaderURL',
			'supportsSVG',
			'errorImageURL',
			'badgeList-Anniversary',
			'badgeList-Nominated',
			'badgeList-On-the-Spot',
			'badgeList-myBadges'
		].forEach((key) => localStorage.removeItem(key));

		try {
			const { data } = await client.mutate({
				mutation: UpdateUserAside,
				variables: {
					input: {
						id: currentUser.id,
						companyId,
					},
				},
			});
			const userUpdatedCompany = {
				...currentUser,
				companyId: data.updateUser.companyId,
				company: data.updateUser.company
			};
			setCurrentUser(userUpdatedCompany);

			this.props.history.push('/dashboard');
		} catch (error) {
			console.error('Error changing company:', error);
		}
	};

	onChangeRole = async (role) => {
		const { client, currentUser, setCurrentUser, updateDisplay } = this.props;
		this.handleClearCache();

		try {
			await client.mutate({
				mutation: UpdateUserAside,
				variables: {
					input: {
						id: currentUser.id,
						role,
					},
				},
			});

			updateDisplay(role);
			setCurrentUser({ ...currentUser, role: role });
			this.props.history.push('/dashboard');
		} catch (error) {
			console.error('Error changing role:', error);
		}
	};

	handleChangePermission = (e) => {
		const newRole = e.target.checked ? USER_ROLES.EMPLOYEE : USER_ROLES.ADMIN;
		this.props.updateDisplay(newRole);

		if (this.props.currentUser.role === newRole) {
			localStorage.setItem('userDisplayAs', '');
		} else {
			localStorage.setItem('userDisplayAs', newRole);
		}
	};

	handleClearCache = () => {
		const { client } = this.props;
		client.clearStore();
	};

	handleLinkClick = (location) => {
		this.props.updatePathname(location);
	};

	handleCopyCompanyId = () => {
		const { currentUser } = this.props;
		navigator.clipboard.writeText(currentUser.companyId);
		message.success('Company ID copied to your clipboard');
	}

	logoClick = async () => {
		const { client, currentUser, setCurrentUser } = this.props;
		if (!currentUser?.admin) {
			return this.props.history.push('/dashboard');
		}
		try {
			const supportAdmin = parse(currentUser.admin);
			const hidden = supportAdmin?.hidden || false;
			const admin = JSON.stringify({ ...supportAdmin, hidden: !hidden });

			this.handleClearCache();
			await client.mutate({
				mutation: UpdateUserAside,
				variables: {
					input: {
						id: currentUser.id,
						admin: admin,
					},
				},
			});
			setCurrentUser({...currentUser, admin: admin});

		} catch (error) {
			console.error('Error toggling admin hidden:', error);
		}
	};

	renderDownload = (company) => {
		const iOSURL = company.whiteLabel ? company?.appStoreUrls?.ios :
			'https://apps.apple.com/us/app/erin-employee-referrals/id1451343403';
		const androidUrl = company.whiteLabel ? company?.appStoreUrls?.android :
			'https://play.google.com/store/apps/details?id=com.erinmobile&hl=en_US&gl=US';
		if (!iOSURL || !androidUrl) return null;
		return (
			<p className="sidebar-info">
				Download for <br />
				<a href={iOSURL} target="_blank" rel="noopener noreferrer">
					iOS
				</a>
				and
				<a href={androidUrl} target="_blank" rel="noopener noreferrer">
					Android
				</a>
			</p>
		);
	};

	render() {
		const { companyData, currentUser, show, location, allMultiLingualData, companyId } = this.props;
		const { companies } = this.state;

		if (!currentUser?.company) return null;
		const logoUrl = this.handleCompanyLogo();
		// Defining disableManagerPermissionsByType to use to determine if managers can see aside options
		const disableManagerPermissionsByType = parse(currentUser?.company?.disableManagerPermissionsByType) ||
			{
				managerReferrals: 'edit',
				managerJobs: 'edit',
				managerBonuses: 'hidden',
				managerEmployees: 'hidden',
			};
		const platformConfig = parse(currentUser?.company?.platformConfig) ||
			{
				primaryPlatform: 'referrals',
				filterMode: 'exclusionary',
				filterList: [],
			};
		const checkFiltered = (filteredPage) => {
			if (currentUser.role === USER_ROLES.SUPER_ADMIN) return false;
			return platformConfig?.filterList?.some(
				(value) => value === filteredPage
			);
		};

		const supportAdmin = parse(currentUser.admin);
		const supportAdminPermissions = supportAdmin?.permissions === '*';
		const supportMenuHidden = supportAdmin?.hidden;
		const hideBonus = companyData?.hideBonus;
		const company = currentUser?.company;
		const IMRoles = [
			USER_ROLES.ADMIN,
			USER_ROLES.MANAGER,
			USER_ROLES.SUPER_ADMIN,
			USER_ROLES.SUPPORT_ADMIN,
			USER_ROLES.EXTENDED_USER,
		];

		if (
			currentUser.company.enableCareerProfile ||
			currentUser.company.allowInternalMobility ||
			currentUser.company.allowSelfReferrals ||
			currentUser.role === USER_ROLES.EXTENDED_USER
		) {
			IMRoles.push(USER_ROLES.EMPLOYEE);
		}


		const isAuthorizedToSeeERINCompanyId =
			(currentUser.role === USER_ROLES.SUPER_ADMIN ||
				(supportAdminPermissions && !supportMenuHidden)) &&
			(window.location.hostname.includes('erinapp') ||
				window.location.hostname.includes('localhost'));
		const options = companies.map((company) => {
			const color = COLORS.epicPurple;
			let accountType = company.accountType ? `(${company.accountType})` : '';
			if (company?.disableSite) accountType = `${accountType} (disabled)`;
			const option = (
				<Select.Option
					key={company.id}
					value={company.id}
					label={company.name}
					style={{ color }}
				>
					{company.name}
					<strong>{`  ${accountType}`}</strong>
				</Select.Option>
			);

			return option;
		});
		const host = window.location.hostname;
		const enableByHost =
			host === 'erin-app-demo.netlify.app' || host === 'localhost';
		const roleOptions = [
			<Select.Option key={USER_ROLES.EMPLOYEE} value={USER_ROLES.EMPLOYEE}>
				Employee
			</Select.Option>,
			<Select.Option key={USER_ROLES.MANAGER} value={USER_ROLES.MANAGER}>
				Manager
			</Select.Option>,
			<Select.Option key={USER_ROLES.ADMIN} value={USER_ROLES.ADMIN}>
				Admin
			</Select.Option>,
			<Select.Option
				key={USER_ROLES.SUPER_ADMIN}
				value={USER_ROLES.SUPER_ADMIN}
			>
				Super Admin
			</Select.Option>,
		];

		const metabaseReports = companyData?.metabaseReports || [];

		const menuItems = [
			{
				title: ml('Admin Console', currentUser, allMultiLingualData),
				iconType: 'code',
				link: 'admin-console',
				hasAccess: supportAdminPermissions && !supportMenuHidden,
				roles: [USER_ROLES.SUPER_ADMIN],
			},
			platformConfig?.primaryPlatform === 'recognition'
				? {
					title: ml('Dashboard', currentUser, allMultiLingualData),
					iconType: 'dashboard',
					link: '/dashboard',
					hasAccess: true,
					roles: [USER_ROLES.SUPER_ADMIN],
				}
				: {},
			platformConfig?.primaryPlatform === 'recognition' &&
			(currentUser?.role === USER_ROLES.ADMIN ||
				currentUser.role === USER_ROLES.SUPER_ADMIN) &&
			currentUser?.displayAs !== USER_ROLES.EMPLOYEE
				? {}
				: {
					title:
						platformConfig?.primaryPlatform === 'recognition'
							? currentUser?.company?.recognitionCustomTitle ||
							ml('RecHub', currentUser, allMultiLingualData)
							: ml('Dashboard', currentUser, allMultiLingualData),
					iconType: 'dashboard',
					link:
						platformConfig?.primaryPlatform === 'recognition'
							? '/recognition-hub'
							: '/dashboard',
					hasAccess: true,
					roles: [
						USER_ROLES.EMPLOYEE,
						USER_ROLES.ADMIN,
						USER_ROLES.MANAGER,
						USER_ROLES.EXTENDED_USER,
						USER_ROLES.SUPER_ADMIN,
						USER_ROLES.SUPPORT_ADMIN,
					],
				},
			{
				title: ml('Recognition Admin', currentUser, allMultiLingualData),
				iconType: 'award',
				link: '/rewards',
				hasAccess: true,
				isAllowFreeUser: true,
				roles:
					currentUser?.enableRecognition ||
					currentUser?.company?.enableRecognition ||
					enableByHost
						? [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]
						: [],
				activeKey: 'recognition',
				panels: [
					{
						title: ml('All Recognitions', currentUser, allMultiLingualData),
						link: '/recognitions-admin',
						hasAccess: true,
						isAllowFreeUser: true,
						roles: [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
					},
					{
						title: ml('Manage Badges', currentUser, allMultiLingualData),
						link: '/manage-badges',
						hasAccess: true,
						isAllowFreeUser: true,
						roles: [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
					},
					{
						title: ml(
							'Recognition Approvals',
							currentUser,
							allMultiLingualData
						),
						link: '/recognition-approvals',
						hasAccess: true,
						isAllowFreeUser: true,
						roles: [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
					},
					{
						title: ml('Physical Rewards', currentUser, allMultiLingualData),
						link: '/physical-rewards',
						hasAccess: true,
						isAllowFreeUser: true,
						roles: [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
					},
					{
						title: ml('Nominations', currentUser, allMultiLingualData),
						link: '/nominations',
						hasAccess: true,
						isAllowFreeUser: true,
						roles: [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
					},
					{
						title: ml('Settings', currentUser, allMultiLingualData),
						link: '/recognition-settings',
						hasAccess: true,
						isAllowFreeUser: true,
						roles: [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
					},
				],
			},
			{
				title: ml('Recognitions', currentUser, allMultiLingualData),
				iconType: 'award',
				link: '/recognitions',
				hasAccess: true,
				isAllowFreeUser: true,
				roles:
					currentUser?.enableRecognition ||
					currentUser?.company?.enableRecognition ||
					enableByHost
						? [USER_ROLES.EMPLOYEE, USER_ROLES.MANAGER]
						: null,
				activeKey: 'recognition',
				panels: [
					platformConfig?.primaryPlatform === 'recognition'
						? {}
						: {
							title:
								currentUser?.company?.recognitionCustomTitle ||
								ml('RecHub', currentUser, allMultiLingualData),
							link: '/recognition-hub',
							hasAccess: true,
							isAllowFreeUser: true,
							roles: [USER_ROLES.EMPLOYEE, USER_ROLES.MANAGER],
						},
					{
						title: ml('My Recognitions', currentUser, allMultiLingualData),
						link: '/my-recognitions',
						hasAccess: true,
						isAllowFreeUser: true,
						roles: [USER_ROLES.EMPLOYEE, USER_ROLES.MANAGER],
					},
					checkFiltered('recognitionsCatalog')
						? {}
						: {
							title: ml(
								'Recognition Catalog',
								currentUser,
								allMultiLingualData
							),
							link: '/recognitions',
							hasAccess: true,
							isAllowFreeUser: true,
							roles: [USER_ROLES.EMPLOYEE, USER_ROLES.MANAGER],
						},
					{
						title: ml(
							'Recognition Approvals & Bonuses',
							currentUser,
							allMultiLingualData
						),
						link: '/approvals-bonus',
						hasAccess: true,
						isAllowFreeUser: true,
						roles: [USER_ROLES.MANAGER],
					},
				],
			},
			checkFiltered('referrals')
				? {}
				: {
					title:
						currentUser.displayAs === USER_ROLES.EMPLOYEE
							? ml('Referrals', currentUser, allMultiLingualData)
							: ml('Manage Referrals', currentUser, allMultiLingualData),
					iconType: 'group',
					link: '/referrals',
					hasAccess: true,
					roles: [
						USER_ROLES.EMPLOYEE,
						USER_ROLES.ADMIN,
						disableManagerPermissionsByType.managerReferrals === 'hidden'
							? ''
							: USER_ROLES.MANAGER,
						USER_ROLES.SUPER_ADMIN,
						USER_ROLES.SUPPORT_ADMIN,
					],
					panels: [
						{
							title: ml('Referrals', currentUser, allMultiLingualData),
							link: '/referrals',
							hasAccess: true,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.MANAGER,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
						{
							title: ml('Referral Leads', currentUser, allMultiLingualData),
							link: '/deck',
							hasAccess: company.enableLead,
							roles: company.enableLead
								? [
									USER_ROLES.ADMIN,
									USER_ROLES.MANAGER,
									USER_ROLES.SUPER_ADMIN,
									USER_ROLES.SUPPORT_ADMIN,
								]
								: [],
						},
						{
							title: ml('My Referrals', currentUser, allMultiLingualData),
							link: '/myreferrals',
							hasAccess: true,
							roles: [USER_ROLES.EMPLOYEE],
						},
						{
							title: ml('Referral Network', currentUser, allMultiLingualData),
							link: '/mycontacts',
							hasAccess: company?.enableExtendedNetwork,
							roles: [USER_ROLES.EMPLOYEE],
						},
					],
				},
			checkFiltered('internal')
				? {}
				: {
					title: currentUser.company.internalMobilityCustomTitle
						? currentUser.company.internalMobilityCustomTitle
						: currentUser.role === USER_ROLES.EXTENDED_USER
							? ml('Grow Your Career', currentUser, allMultiLingualData)
							: ml('Internal Mobility', currentUser, allMultiLingualData),
					iconType: 'internal',
					link: '/internal',
					hasAccess: true,
					roles: IMRoles,
					panels: [
						{
							title: ml('Grow Your Career', currentUser, allMultiLingualData),
							link: '/internal',
							hasAccess: currentUser.company.allowInternalMobility,
							specialCase: true,
							roles: [
								USER_ROLES.EMPLOYEE,
								USER_ROLES.ADMIN,
								USER_ROLES.MANAGER,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
						{
							title: ml(
								'Internal Applicants',
								currentUser,
								allMultiLingualData
							),
							link: '/internal-applicants',
							hasAccess: currentUser.company.allowSelfReferrals,
							specialCase: true,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.MANAGER,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
						{
							title: ml('My Applications', currentUser, allMultiLingualData),
							link: '/myapplications',
							hasAccess:
								currentUser.company.allowSelfReferrals ||
								currentUser.role === USER_ROLES.EXTENDED_USER,
							roles: [USER_ROLES.EMPLOYEE, USER_ROLES.EXTENDED_USER],
						},
						{
							title: ml('Career Profile', currentUser, allMultiLingualData),
							link: '/career',
							hasAccess:
								currentUser.company.enableCareerProfile ||
								currentUser.role === USER_ROLES.EXTENDED_USER,
							roles: [USER_ROLES.EMPLOYEE, USER_ROLES.EXTENDED_USER],
						},
						{
							title: ml(
								'Open To New Roles',
								currentUser,
								allMultiLingualData
							),
							link: '/open',
							hasAccess: currentUser.company.allowInternalMobility,
							specialCase: true,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.MANAGER,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
					],
				},
			checkFiltered('bonuses')
				? {}
				: {
					title: ml('My Bonuses', currentUser, allMultiLingualData),
					iconType: 'bonus',
					link: '/mybonuses',
					hasAccess: true,
					roles: [hideBonus ? '' : USER_ROLES.EMPLOYEE],
				},
			checkFiltered('bonuses')
				? {}
				: {
					title: ml('Bonuses', currentUser, allMultiLingualData),
					iconType: 'bonus',
					link: '/builder',
					hasAccess: true,
					roles: [
						disableManagerPermissionsByType.managerBonuses === 'hidden'
							? ''
							: USER_ROLES.MANAGER,
						USER_ROLES.ADMIN,
						USER_ROLES.SUPER_ADMIN,
						USER_ROLES.SUPPORT_ADMIN,
					],
					panels: [
						{
							title: ml('All Bonuses', currentUser, allMultiLingualData),
							link: '/bonuses',
							hasAccess: true,
							roles: [
								disableManagerPermissionsByType.managerBonuses === 'hidden'
									? ''
									: USER_ROLES.MANAGER,
								USER_ROLES.ADMIN,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
						{
							title: ml('Bonus Campaigns', currentUser, allMultiLingualData),
							link: '/bonus-campaigns',
							hasAccess: true,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
						{
							title: ml('Bonus Settings', currentUser, allMultiLingualData),
							link: '/builder',
							hasAccess: true,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
					],
				},
			checkFiltered('jobs')
				? {}
				: {
					title: ml('Manage Jobs', currentUser, allMultiLingualData),
					iconType: 'id',
					link: '/jobs',
					hasAccess: true,
					roles: [
						disableManagerPermissionsByType.managerJobs === 'hidden'
							? ''
							: USER_ROLES.MANAGER,
						USER_ROLES.ADMIN,
						USER_ROLES.SUPER_ADMIN,
						USER_ROLES.SUPPORT_ADMIN,
					],
				},
			checkFiltered('messages')
				? {}
				: {
					title: ml('Message Center', currentUser, allMultiLingualData),
					iconType: 'messages',
					link: '/messagecenter',
					hasAccess: true,
					roles: [
						USER_ROLES.ADMIN,
						USER_ROLES.SUPER_ADMIN,
						USER_ROLES.SUPPORT_ADMIN,
					],
					panels: [
						{
							title: ml('Send A Message', currentUser, allMultiLingualData),
							link: '/messagecenter',
							hasAccess: true,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
					],
				},
			checkFiltered('reports')
				? {}
				: {
					title: ml('Reports', currentUser, allMultiLingualData),
					iconType: 'reports',
					hasAccess: true,
					roles: [
						USER_ROLES.ADMIN,
						USER_ROLES.SUPER_ADMIN,
						USER_ROLES.SUPPORT_ADMIN,
					],
					panels: [
						{
							title: ml('Report Builder', currentUser, allMultiLingualData),
							link: '/report-builder',
							hasAccess: true,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
						{
							title: ml('Report History', currentUser, allMultiLingualData),
							link: '/report-history',
							hasAccess: true,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
						...metabaseReports.map((report) => ({
							title: report.label,
							link: `/custom-report/${report.label.toLowerCase()?.replaceAll(' ', '-')}`,
							hasAccess: true,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						})),
					],
				},
			checkFiltered('points')
				? {}
				: {
					title: ml('Gamification', currentUser, allMultiLingualData),
					iconType: 'gamification',
					link: '/gamification',
					hasAccess: true,
					roles: [
						USER_ROLES.ADMIN,
						USER_ROLES.SUPER_ADMIN,
						USER_ROLES.SUPPORT_ADMIN,
					],
					panels: [
						{
							title: ml('Points', currentUser, allMultiLingualData),
							link: '/points',
							hasAccess: true,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
						{
							title: ml('Gift Card Store', currentUser, allMultiLingualData),
							link: '/giftcardstore',
							hasAccess: parse(currentUser.company.pointsSettings)?.isStoreEnabled,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
					],
				},
			checkFiltered('employees')
				? {}
				: {
					title: ml('Employees', currentUser, allMultiLingualData),
					iconType: 'users',
					link: '/employees',
					hasAccess: true,
					roles: [
						disableManagerPermissionsByType.managerEmployees === 'hidden'
							? ''
							: USER_ROLES.MANAGER,
						USER_ROLES.ADMIN,
						USER_ROLES.SUPER_ADMIN,
						USER_ROLES.SUPPORT_ADMIN,
					],
				},
			checkFiltered('points')
				? {}
				: {
					title: ml('Gift Card Store', currentUser, allMultiLingualData),
					iconType: 'shoppingBag',
					link: '/giftcardstore',
					hasAccess: true,
					roles: [
						parse(currentUser.company.pointsSettings)?.isStoreEnabled
							? USER_ROLES.EMPLOYEE
							: null,
					],
				},
			checkFiltered('settings')
				? {}
				: {
					title: ml('Settings', currentUser, allMultiLingualData),
					iconType: 'settings',
					link: '/settings',
					hasAccess: true,
					roles: [
						USER_ROLES.ADMIN,
						USER_ROLES.SUPER_ADMIN,
						USER_ROLES.SUPPORT_ADMIN,
					],
					panels: [
						{
							title: ml('Settings', currentUser, allMultiLingualData),
							link: '/settings',
							hasAccess: true,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
						{
							title: ml('Form Builder', currentUser, allMultiLingualData),
							link: '/form-builder',
							hasAccess: true,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
						{
							title: ml('Notifications', currentUser, allMultiLingualData),
							link: '/job-notification-email',
							hasAccess: true,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
						{
							title: ml(
								'Companies & Brands',
								currentUser,
								allMultiLingualData
							),
							link: '/branding',
							hasAccess: true,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
						{
							title: ml(
								'Employee Dashboard',
								currentUser,
								allMultiLingualData
							),
							link: '/employee-dashboard-settings',
							hasAccess: true,
							roles: [
								USER_ROLES.ADMIN,
								USER_ROLES.SUPER_ADMIN,
								USER_ROLES.SUPPORT_ADMIN,
							],
						},
					],
				},
		];


		return (
			<aside
				className={`sidebar ${show ? 'sidebar-close' : ''}`}
				id="aside"
			>
				{logoUrl ? (
					<div
						className="sidebar-logo"
						onClick={() => this.logoClick()}
					>
						<img src={logoUrl} alt="logo" />
						{companyData?.showPoweredByErin && (
							<div className="powered-by-ERIN">
								<img alt="logo" style={{ width: 200 }} src={PoweredBy} />
							</div>
						)}
					</div>
				) : (
					<div className="sidebar-logo">
						<img src={Logo} alt="erin" />
					</div>
				)}

				{supportAdminPermissions && !supportMenuHidden && (
					<Select
						showSearch
						style={{
							width: '90%',
							marginLeft: '5%',
							marginTop: 10,
							marginBottom: 5,
						}}
						placeholder="Select Company"
						optionFilterProp="children"
						value={currentUser.company.id}
						suffixIcon={<DownOutlined />}
						getPopupContainer={() => document.querySelector('#aside')}
						onChange={this.onChangeCompany}
					>
						{options}
					</Select>
				)}

				{supportAdminPermissions && !supportMenuHidden && (
					<Select
						showSearch
						style={{
							width: '90%',
							marginLeft: '5%',
							marginTop: 5,
							marginBottom: 5,
						}}
						placeholder="Select Role"
						optionFilterProp="children"
						value={currentUser?.role}
						suffixIcon={<DownOutlined />}
						getPopupContainer={() => document.querySelector('#aside')}
						onChange={this.onChangeRole}
					>
						{roleOptions}
					</Select>
				)}
				<ul className="side-menu">
					{menuItems.map((item, i) => {
						if (
							item.hasOwnProperty('panels') &&
							item?.roles?.includes(currentUser.displayAs)
						) {
							return (
								<li key={i} className="menu-item">
									<Collapse
										expandIconPosition="end"
										bordered={false}
										defaultActiveKey={
											platformConfig?.primaryPlatform === 'recognition'
												? ['recognition']
												: []
										}
									>
										<Collapse.Panel
											key={item?.activeKey}
											header={
												<ConditionalWrapper
													condition={item}
													wrapper={(children) =>
														item?.link ? (
															<Link
																to={{ javascript: void 0 }}
																className={`menu-link ${
																	(item?.panels).find(
																		(item) =>
																			item.link == window.location.pathname
																	)
																		? 'active'
																		: 'inactive'
																}`}
															>
																{children}
															</Link>
														) : (
															<div
																className={`menu-link ${
																	(item?.panels).find(
																		(item) =>
																			item.link == window.location.pathname
																	)
																		? 'active'
																		: 'inactive'
																}`}
															>
																{children}
															</div>
														)
													}
												>
													<span className="menu-icon">
														<WebIcon
															name={item?.iconType}
															size={30}
															color={COLORS.white}
														/>
													</span>
													<span className="menu-title">{item?.title}</span>
												</ConditionalWrapper>
											}
										>
											<ul className="sub-menu">
												{item?.panels.map((item, j) => {
													if (item?.roles?.includes(currentUser.displayAs)) {
														// If Admin
														if (currentUser.displayAs !== USER_ROLES.EMPLOYEE) {
															return (
																<li
																	key={j + item?.link}
																	className={`sub-menu-item ${JSON.stringify(
																		item?.hasAccess
																	)}`}
																	onClick={() => {
																		if (
																			item?.hasOwnProperty('specialCase') &&
																			!item?.hasAccess
																		) {
																			return false;
																		}

																		this.handleLinkClick(item?.link);
																	}}
																>
																	<Link
																		className={`
                                            sub-menu-link
                                            ${
																			window.location.pathname ==
																			item?.link
																				? 'active'
																				: ''
																		}
                                            ${item?.hasAccess ? '' : 'lock'}
                                        `}
																		to={
																			item?.hasAccess
																				? {
																					pathname: item?.link,
																				}
																				: {
																					javascript: void 0,
																				}
																		}
																	>
																		<span>{item?.title}</span>
																	</Link>
																	{!item?.hasAccess &&
																		!item?.hasOwnProperty('specialCase') && (
																			<WebIcon
																				name="lock"
																				size={16}
																				color={COLORS.white}
																			/>
																		)}
																	{item?.hasOwnProperty('specialCase') &&
																		!item?.hasAccess && (
																			<div className="menu-tooltip">
																				<WebIcon
																					name="lock"
																					size={16}
																					color={COLORS.white}
																				/>
																				<span className="tooltiptext">
																					To enable this feature please contacts
																					our sales team{' '}
																					<a
																						href="https://erinapp.com/"
																						target="_blank"
																						rel="noreferrer"
																					>
																						here
																					</a>
																					.
																				</span>
																			</div>
																		)}
																</li>
															);
														}

														if (item?.hasAccess) {
															return (
																<li
																	key={j + item?.link}
																	className="sub-menu-item"
																	onClick={() =>
																		this.handleLinkClick(item?.link)
																	}
																>
																	<Link
																		className={`sub-menu-link ${
																			window.location.pathname == item?.link
																				? 'active'
																				: ''
																		}`}
																		to={{
																			pathname: item?.link,
																		}}
																	>
																		{item?.title}
																	</Link>
																</li>
															);
														}
													}
												})}
											</ul>
										</Collapse.Panel>
									</Collapse>
								</li>
							);
						}

						if (item?.roles?.includes(currentUser.displayAs)) {
							if (currentUser.displayAs !== USER_ROLES.employee) {
								return (
									<li
										key={i}
										className="menu-item"
										onClick={() => this.handleLinkClick(item?.link)}
									>
										<Link
											className={`
                          ${
												window.location.pathname.includes(item?.link)
													? 'active'
													: ''
											}
                          ${item?.hasAccess ? '' : 'lock'}
                        `}
											to={
												item?.hasAccess
													? { pathname: item?.link }
													: { javascript: void 0 }
											}
										>
											<span className="menu-icon">
												<WebIcon
													name={item?.iconType}
													size={30}
													color={COLORS.white}
												/>
											</span>
											<span className="menu-title">{item?.title}</span>
											{!item?.hasAccess && (
												<WebIcon name="lock" size={18} color={COLORS.white} />
											)}
										</Link>
									</li>
								);
							}

							if (item?.hasAccess) {
								return (
									<li
										key={i}
										className="menu-item"
										onClick={() => this.handleLinkClick(item?.link)}
									>
										<Link
											className={`
                            ${
												window.location.pathname.includes(item?.link)
													? 'active'
													: ''
											}
                          `}
											to={{ pathname: item?.link }}
										>
											<span className="menu-icon">
												<WebIcon
													name={item?.iconType}
													size={30}
													color={COLORS.white}
												/>
											</span>
											<span className="menu-title">{item?.title}</span>
										</Link>
									</li>
								);
							}
						}
					})}
				</ul>

				{currentUser?.role === 'admin' && (
					<div className="sidebar-switch-wrap">
						<div className="sidebar-switch-container">
							<span
								style={{ color: '#fff' }}
								className={`switch-label left ${
									currentUser.displayAs === USER_ROLES.ADMIN ? 'active' : ''
								}`}
							>
								Admin View
							</span>
							<label className="switch-wrap">
								<input
									type="checkbox"
									checked={currentUser.displayAs === USER_ROLES.EMPLOYEE}
									onChange={this.handleChangePermission}
								/>
								<span className="switch-slider" />
							</label>
							<span
								style={{ color: '#fff' }}
								className={`switch-label right ${
									currentUser.displayAs === USER_ROLES.EMPLOYEE ? 'active' : ''
								}`}
							>
								Employee View
							</span>
						</div>

						{isAuthorizedToSeeERINCompanyId && (
							<p
								style={{ cursor: 'copy' }}
								className="sidebar-info"
								onClick={this.handleCopyCompanyId}
							>
								ERIN Company ID: {company.id}
							</p>
						)}
					</div>
				)}
				{this.renderDownload(company)}
			</aside>
		);
	}
}

export const AsideComponent = withApollo(
	withRouter(withQueryParams(BaseAside))
);
