import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Collapse, Select } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import gql from 'graphql-tag';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { queryUserGroupsByCompanyIdIndex } from 'src/_shared/api/graphql/custom/userGroups/';
import fileIcon from '../../_shared/assets/erin_lightgray.png';
import { downloadFromS3Signed } from '../../_shared/services/utils.js';
import { currencies } from './commonCurrency.js';

export class GroupCurrencyComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			defaultActiveUserGroupKey: '',
			company: get(props, 'currentUser.company'),
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			userGroup: get(props, 'currentUser.userGroup'),
			userGroups: [],
		};
	}

	async componentDidMount() {
		this.getUserGroups();

		let errorImageSource;
		const errorImage = get(this.state.company, 'errorImage.key', false);
		if (errorImage) {
			errorImageSource = await downloadFromS3Signed(errorImage, 'erin-images');
		}

		this.setState({ errorImageSrc: errorImageSource });
	}

	async getUserGroups(
		policy = 'network-only',
		nextToken = null,
		allUserGroups = []
	) {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: gql(queryUserGroupsByCompanyIdIndex),
				variables: {
					companyId: get(currentUser, 'companyId'),
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const userGrps = [...data.queryUserGroupsByCompanyIdIndex.items];
			const token = get(
				data,
				'queryUserGroupsByCompanyIdIndex.nextToken',
				null
			);
			allUserGroups = [...allUserGroups, ...userGrps];
			this.setState({
				userGroups: allUserGroups,
			});
			if (token) {
				this.getUserGroups(policy, token, allUserGroups);
			}
		} catch (error) {
			console.log(error);
		}
	}

	handleSelectCurrency = (value, userGroupId) => {
		const { userGroup } = this.state;

		this.props.onUpdateUserGroup({
			input: {
				id: userGroupId,
				currency: value,
			},
		});
		const currentUserGroupId = get(this.props, 'currentUser.userGroupId');
		if (currentUserGroupId === userGroupId) {
			const updatedUserGroup = {
				...userGroup,
				id: userGroup.id,
				currency: value,
			};
			this.props.setCurrentUserGroup(updatedUserGroup);
		}
	};

	renderList = (userGroups) => {
		const { company, defaultActiveUserGroupKey } = this.state;
		const whiteLabel = get(company, 'whiteLabel');

		if (userGroups.length === 0) {
			return (
				<div className="no-content">
					{whiteLabel ? (
						<img
							className="no-content-icon"
							src={this.state.errorImageSrc}
							alt="error image"
						/>
					) : (
						<img alt="erin-logo" className="no-content-icon" src={fileIcon} />
					)}
					<p className="no-content-text">No User Group have been created. </p>
				</div>
			);
		}

		if (userGroups && userGroups.length > 0 && defaultActiveUserGroupKey) {
			return (
				<Collapse
					bordered={false}
					activeKey={defaultActiveUserGroupKey}
					className="bonus-collapse"
				>
					{this.renderUserGroups()}
				</Collapse>
			);
		}

		if (userGroups && userGroups.length > 0 && !defaultActiveUserGroupKey) {
			return (
				<Collapse bordered={false} className="bonus-collapse">
					{this.renderUserGroups()}
				</Collapse>
			);
		}
	};

	renderUserGroups = () => {
		const { theme, userGroups } = this.state;
		const { Option } = Select;
		const options = [];
		Object.keys(currencies).map((key) =>
			options.push(
				<Option key={key}>
					{getSymbolFromCurrency(key)} {key}
				</Option>
			)
		);

		// Sort userGroups by name
		const sortedGroups = orderBy(userGroups, ['name'], ['asc']);

		return (
			<Collapse
				className="bonus-collapse"
				bordered={false}
				expandIconPosition="end"
			>
				<Collapse.Panel
					key="currency"
					className="bonus-collapse-card"
					extra={
						<div className="bonus-collapse-icon-wrap">
							<i className="icon-arrow-down bonus-collapse-icon" />
						</div>
					}
					header={<h4>Currency</h4>}
					onClick={() =>
						this.setState({ defaultActiveUserGroupKey: 'currency' })
					}
				>
					{sortedGroups
						.filter((group) => group.active === true)
						.map((item) => (
							<div key={item.id} className="bs-form">
								<div className="bs-form-grp">
									<div className="custom-form-group">
										<label className="bs-label bs-label-width">
											{item.name}:
										</label>
										<Select
											style={{ width: 180 }}
											className="custom-input"
											defaultValue={item.currency}
											placeholder="Select"
											onSelect={(value) =>
												this.handleSelectCurrency(value, item.id)
											}
										>
											{options}
										</Select>
									</div>
								</div>
							</div>
						))}
				</Collapse.Panel>
			</Collapse>
		);
	};

	render() {
		const { userGroups } = this.state;
		return <Form>{this.renderList(userGroups)}</Form>;
	}
}

export const GroupCurrency = withApollo(Form.create()(GroupCurrencyComponent));
