import { Button } from 'antd';

export function ToggleOnOffDeck(props) {
	const {
		onClick,
		onDeckJobsKey,
		offDeckJobsKey,
		filterOnDeckStatus,
		activeText,
		inactivetext,
		onDeckLoading,
		offDeckLoading,
	} = props;

	return (
		<div className="custom-btn-group">
			<Button
				size="small"
				className={filterOnDeckStatus === onDeckJobsKey ? 'active' : null}
				loading={onDeckLoading}
				onClick={() => onClick(onDeckJobsKey)}
			>
				{activeText}
			</Button>
			<Button
				size="small"
				className={filterOnDeckStatus === offDeckJobsKey ? 'active' : null}
				loading={offDeckLoading}
				onClick={() => onClick(offDeckJobsKey)}
			>
				{inactivetext}
			</Button>
		</div>
	);
}
