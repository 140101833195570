import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import {
	getUserById,
	queryUsersByCompanyIdRoleIndex,
} from 'src/_shared/api/graphql/custom/users/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { logout, ml, parse } from 'src/_shared/services/utils.js';
import { USER_ROLES } from '../_shared/constants';
import SettingsFormAts from './SettingsFormAtsComponent.jsx';
import SettingsForm from './SettingsFormComponent.jsx';
import SettingsSFTPFolderForm from './SettingsSftpFolderFormComponent.jsx';
import SettingsSSOForm from './SettingsSSOFormComponent.jsx';
import { SubdomainCreation } from './subdomain-creation/index.js';

class SettingsComponent extends Component {
	constructor(props) {
		super(props);
		const supportAdmin = parse(get(props, 'currentUser.admin'));
		const supportAdminPermissions = get(supportAdmin, 'permissions') === '*';
		this.state = {
			company: props?.company,
			success: false,
			error: false,
			duplicateDeptError: false,
			departmentError: false,
			integrationError: false,
			integrationSuccess: false,
			users: [],
			currentJob: props?.currentJob,
			jobs: props?.jobs,
			syncGreenhouseJobs: false,
			importGreenhouseDepartments: false,
			syncClearCompanyJobs: false,
			integrationATSSettingError: false,
			syncLeverJobs: false,
			duplicateUserGroupError: false,
			userGroupError: false,
			successEmp: false,
			errorEmp: false,
			syncICIMSJobs: false,
			syncCrelateJobs: false,
			syncReferVetsJobs: false,
			syncSeaWorldJobs: false,
			syncSilkroadJobs: false,
			syncTalentReefJobs: false,
			syncUltiproJobs: false,
			supportAdminPermissions,
		};
	}

	async componentDidMount() {
		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		await this.getAllUsers();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.company !== this.props.company ||
			prevProps.jobs !== this.props.jobs
		) {
			if (this.props.onFetchMore) this.props.onFetchMore();
			this.setState({
				company: this.props.company,
				jobs: this.props.jobs,
			});
		}
	}

	async getAllUsers(policy = 'network-only', nextToken = null, allUsers = []) {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: gql(queryUsersByCompanyIdRoleIndex),
				variables: {
					companyId: get(currentUser, 'companyId'),
					role: USER_ROLES.ADMIN,
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const usrs = [...data.queryUsersByCompanyIdRoleIndex.items];
			const token = get(data, 'queryUsersByCompanyIdRoleIndex.nextToken', null);
			allUsers = [...allUsers, ...usrs];
			this.setState({
				users: allUsers,
			});
			if (token) {
				this.getAllUsers(policy, token, allUsers);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	handleDepartmentError = () => {
		this.setState({ departmentError: true });
	};

	handleDuplicateError = () => {
		this.setState({ duplicateDeptError: true });
	};

	handleDuplicateUserGroupError = () => {
		this.setState({ duplicateUserGroupError: true });
	};

	handleEmpSuccess = () => {
		this.setState({
			successEmp: true,
			errorEmp: false,
			duplicateUserGroupError: false,
		});
	};

	handleError = () => {
		this.setState({ error: true });
	};

	handleIntegrationError = () => {
		this.setState({ integrationError: true });
		setTimeout(() => {
			this.setState({ integrationError: false });
		}, 5000);
	};

	handleIntegrationSuccess = () => {
		this.setState({
			integrationSuccess: true,
		});
		setTimeout(() => {
			this.setState({
				integrationSuccess: false,
			});
		}, 4000);
	};

	handleSuccess = () => {
		this.setState({
			success: true,
			error: false,
			duplicateDeptError: false,
		});
		setTimeout(() => {
			this.setState({
				success: false,
			});
		}, 10_000);
	};

	handleUserGroupError = () => {
		this.setState({ userGroupError: true });
	};

	handleintegrationATSSettingError = () => {
		this.setState({ integrationATSSettingError: true });
		setTimeout(() => {
			this.setState({ integrationATSSettingError: false });
		}, 5000);
	};

	importGreenhouseDepartmentsSuccess = () => {
		this.setState({
			importGreenhouseDepartments: true,
		});
		setTimeout(() => {
			this.setState({
				importGreenhouseDepartments: false,
			});
		}, 300_000);
	};

	resetErrors = () => {
		this.setState({
			departmentError: false,
			error: false,
			duplicateError: false,
			integrationError: false,
			integrationATSSettingError: false,
			userGroupError: false,
		});
	};

	syncClearCompanyJobsSuccess = () => {
		this.setState({
			syncClearCompanyJobs: true,
		});
		setTimeout(() => {
			this.setState({
				syncClearCompanyJobs: false,
			});
		}, 1_800_000);
	};

	syncCrelateJobsSuccess = () => {
		this.setState({
			syncCrelateJobs: true,
		});
		setTimeout(() => {
			this.setState({
				syncCrelateJobs: false,
			});
		}, 1_800_000);
	};

	syncGreenhouseJobsSuccess = () => {
		this.setState({
			syncGreenhouseJobs: true,
		});
		setTimeout(() => {
			this.setState({
				syncGreenhouseJobs: false,
			});
		}, 1_800_000);
	};

	syncICIMSJobsSuccess = () => {
		this.setState({
			syncICIMSJobs: true,
		});
		setTimeout(() => {
			this.setState({
				syncICIMSJobs: false,
			});
		}, 1_800_000);
	};

	syncLeverJobsSuccess = () => {
		this.setState({
			syncLeverJobs: true,
		});
		setTimeout(() => {
			this.setState({
				syncLeverJobs: false,
			});
		}, 1_800_000);
	};

	syncReferVetsJobsSuccess = () => {
		this.setState({
			syncReferVetsJobs: true,
		});
		setTimeout(() => {
			this.setState({
				syncReferVetsJobs: false,
			});
		}, 1_800_000);
	};

	syncSeaWorldJobsSuccess = () => {
		this.setState({
			syncSeaWorldJobs: true,
		});
		setTimeout(() => {
			this.setState({
				syncSeaWorldJobs: false,
			});
		}, 1_800_000);
	};

	syncSilkroadJobsSuccess = () => {
		this.setState({
			syncSilkroadJobs: true,
		});
		setTimeout(() => {
			this.setState({
				syncSilkroadJobs: false,
			});
		}, 1_800_000);
	};

	syncTalentReefJobsSuccess = () => {
		this.setState({
			syncTalentReefJobs: true,
		});
		setTimeout(() => {
			this.setState({
				syncTalentReefJobs: false,
			});
		}, 1_800_000);
	};

	syncUltiproJobsSuccess = () => {
		this.setState({
			syncUltiproJobs: true,
		});
		setTimeout(() => {
			this.setState({
				syncUltiproJobs: false,
			});
		}, 1_800_000);
	};

	render() {
		const {
			error,
			success,
			duplicateDeptError,
			departmentError,
			integrationError,
			integrationSuccess,
			users,
			currentJob,
			jobs,
			syncGreenhouseJobs,
			importGreenhouseDepartments,
			syncClearCompanyJobs,
			integrationATSSettingError,
			syncLeverJobs,
			duplicateUserGroupError,
			userGroupError,
			successEmp,
			errorEmp,
			syncICIMSJobs,
			syncCrelateJobs,
			syncSilkroadJobs,
			syncReferVetsJobs,
			syncSeaWorldJobs,
			syncTalentReefJobs,
			syncUltiproJobs,
			supportAdminPermissions,
		} = this.state;
		const {
			onCreateDepartment,
			onUpdateDepartment,
			onUpdateCompany,
			onAddJob,
			onUpdateJob,
			onCreateUserGroup,
			onUpdateUserGroup,
			bonuses,
			company,
			currentUser,
			allMultiLingualData,
		} = this.props;
		if (!this.props.company) {
			return <Spinner company={company} />;
		}

		if (!get(this.props, 'currentUser.company')) return <Spinner />;

		const { languageCode } = this.props.currentUser;
		return (
			<main>
				<SettingsForm
					allMultiLingualData={allMultiLingualData}
					currentUser={currentUser}
					company={company}
					departments={this.props.departments}
					error={error}
					success={success}
					duplicateDeptError={duplicateDeptError}
					handleError={this.handleError}
					handleSuccess={this.handleSuccess}
					handleDuplicateError={this.handleDuplicateError}
					handleUrlChange={this.handleUrlChange}
					handleUrlError={this.handleUrlError}
					updateCurrentUserCompany={this.props.updateCurrentUserCompany}
					departmentError={departmentError}
					handleDepartmentError={this.handleDepartmentError}
					resetErrors={this.resetErrors}
					userGroups={this.props.userGroups}
					duplicateUserGroupError={duplicateUserGroupError}
					userGroupError={userGroupError}
					handleDuplicateUserGroupError={this.handleDuplicateUserGroupError}
					handleUserGroupError={this.handleUserGroupError}
					successEmp={successEmp}
					errorEmp={errorEmp}
					handleEmpSuccess={this.handleEmpSuccess}
					adminUsers={users}
					onCreateDepartment={onCreateDepartment}
					onUpdateDepartment={onUpdateDepartment}
					onUpdateCompany={onUpdateCompany}
					onCreateUserGroup={onCreateUserGroup}
					onUpdateUserGroup={onUpdateUserGroup}
				/>
				<div className="setting-card">
					<SettingsFormAts
						allMultiLingualData={allMultiLingualData}
						currentUser={currentUser}
						company={company}
						departments={this.props.departments}
						error={error}
						success={success}
						duplicateDeptError={duplicateDeptError}
						handleError={this.handleError}
						handleSuccess={this.handleSuccess}
						handleDuplicateError={this.handleDuplicateError}
						handleUrlChange={this.handleUrlChange}
						handleUrlError={this.handleUrlError}
						updateCurrentUserCompany={this.props.updateCurrentUserCompany}
						departmentError={departmentError}
						handleDepartmentError={this.handleDepartmentError}
						nextToken={this.props.nextToken}
						resetErrors={this.resetErrors}
						integrationError={integrationError}
						integrationSuccess={integrationSuccess}
						handleIntegrationSuccess={this.handleIntegrationSuccess}
						managers={users}
						currentJob={currentJob}
						jobs={jobs}
						syncGreenhouseJobs={syncGreenhouseJobs}
						syncGreenhouseJobsSuccess={this.syncGreenhouseJobsSuccess}
						importGreenhouseDepartments={importGreenhouseDepartments}
						importGreenhouseDepartmentsSuccess={
							this.importGreenhouseDepartmentsSuccess
						}
						handleIntegrationError={this.handleIntegrationError}
						syncClearCompanyJobs={syncClearCompanyJobs}
						syncClearCompanyJobsSuccess={this.syncClearCompanyJobsSuccess}
						integrationATSSettingError={integrationATSSettingError}
						handleintegrationATSSettingError={
							this.handleintegrationATSSettingError
						}
						syncLeverJobs={syncLeverJobs}
						syncLeverJobsSuccess={this.syncLeverJobsSuccess}
						syncICIMSJobs={syncICIMSJobs}
						syncICIMSJobsSuccess={this.syncICIMSJobsSuccess}
						tieredBonuses={bonuses}
						languageCode={languageCode}
						syncUltiproJobs={syncUltiproJobs}
						syncUltiproJobsSuccess={this.syncUltiproJobsSuccess}
						syncCrelateJobs={syncCrelateJobs}
						syncCrelateJobsSuccess={this.syncCrelateJobsSuccess}
						syncReferVetsJobs={syncReferVetsJobs}
						syncReferVetsJobsSuccess={this.syncReferVetsJobsSuccess}
						syncSeaWorldJobs={syncSeaWorldJobs}
						syncSeaWorldJobsSuccess={this.syncSeaWorldJobsSuccess}
						syncSilkroadJobs={syncSilkroadJobs}
						syncSilkroadJobsSuccess={this.syncSilkroadJobsSuccess}
						syncTalentReefJobs={syncTalentReefJobs}
						syncTalentReefJobsSuccess={this.syncTalentReefJobsSuccess}
						onCreateDepartment={onCreateDepartment}
						onUpdateDepartment={onUpdateDepartment}
						onUpdateCompany={onUpdateCompany}
						onAddJob={onAddJob}
						onUpdateJob={onUpdateJob}
					/>
				</div>
				{supportAdminPermissions && (
					<h2 className="setting-heading">
						{ml('SFTP', currentUser, allMultiLingualData)}
					</h2>
				)}
				{supportAdminPermissions && (
					<div className="setting-card">
						<SettingsSFTPFolderForm
							currentUser={currentUser}
							company={this.state.company}
							languageCode={languageCode}
							onUpdateCompany={onUpdateCompany}
						/>
					</div>
				)}
				{supportAdminPermissions && (
					<h2 className="setting-heading">Host / SSO Settings</h2>
				)}
				{supportAdminPermissions && (
					<div className="setting-card">
						<SettingsSSOForm
							currentUser={currentUser}
							company={this.state.company}
							languageCode={languageCode}
							onUpdateCompany={onUpdateCompany}
						/>
					</div>
				)}
				{currentUser?.displayAs === USER_ROLES.SUPER_ADMIN && (
					<SubdomainCreation
						currentUser={currentUser}
						allMultiLingualData={allMultiLingualData}
					/>
				)}
			</main>
		);
	}
}

export default withApollo(SettingsComponent);
