import gql from 'graphql-tag';

export const GetReferralMyContacts = gql`
	query GetReferral($id: ID!) {
        getReferral(id: $id) {
            id
            bonuses {
                id
                amountDue
                bonusStatus
                companyId
                contactId
                earnedDate
                hireDate
                jobId
                payment
                recipientType
                referralId
                startDate
                userId
                notes
            }
            companyId
            company {
                id
                contactIncentiveBonus
                referralBonusWaitingPeriod
            }
            contact {
                id
                firstName
                lastName
                emailAddress
                socialMediaAccounts
                phoneNumber
                contactResume {
                    bucket
                    region
                    key
                }
            }
            contactId
            userId
            user {
                id
                firstName
                lastName
                incentiveEligible
                role
                userGroupId
                emailAddress
                title
                department {
                    id
                    name
                }
                avatar {
                    key
                    region
                    bucket
                }
            }
            jobId
            job {
                id
                departmentId
                department {
                    id
                    name
                }
                campaignId
                campaign {
                    id
                    name
                    startDate
                    endDate
                    archived
                    tieredBonusId
                }
                displayReqId
                externalJobId
                externalSource
                isGeneralReferral
                isHotJob
                jobType
                location
                referralBonus
                title
                tieredBonus {
                    id
                    name
                    tiers
                }
            }
            status
            bonusStatus
            referralDate
            referralType
            hireDate
            ownershipEndDate
            message
            customStatus
            contactResume {
                bucket
                region
                key
            }
        }
    }
`;
