import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { ArrowRightOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Input, Row, Select } from 'antd';
import { Auth } from 'aws-amplify';
import Cookies from 'js-cookie';
import _ from 'lodash';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { geolocated } from 'react-geolocated';
import PlacesAutocomplete from 'src/_shared/components/location/PlacesAutocomplete.jsx';
import { withRouter } from 'react-router-dom';
import { GetUserByCognitoId } from 'src/_shared/api/graphql/custom/users/';
import { Spinner, WebIcon } from 'src/_shared/index.js';
import {
	findItemByNameAndKeywords,
	getLocation,
	ml,
	parse,
} from 'src/_shared/services/utils.js';
import { GetUserByEmailAddress } from 'src/graphql/custom/users/';
import uuid from 'uuid/v4';
import validator from 'validator';
import { COLORS } from '../../_shared/styles/colors';
import { requiredFields } from '../errorHandlers';
import { PageItem } from './AddLocationPageItemComponent.jsx';
import { USStates } from './copy.js';
import {
	AddLocationBtn as AddLocationButton,
	AlertError,
	CheckIcon,
	FormStyles,
	InputStyles2,
	LabelStyles,
	MiscText,
	SubTitleStyles,
	SubmitBtn as SubmitButton,
	SubmitBtnContainer as SubmitButtonContainer,
	TitleStyles,
	btnText as buttonText,
	errorStyles,
	typeaheadRow,
} from './newUserFormStyles.js';
import { SelectStyles, SortDown } from './selectStyles.js';

class NewCompanyUserForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			address: '',
			serverError: false,
			lengthError: false,
			department: '',
			emailError: false,
			geolocationAllowed: false,
			requiredErrors: {},
			tokenData: this.props.tokenData,
			redirectURL: this.props.redirectURL,
			submitting: false,
			currencyAbbrev: 'USD',
			multiLingualData: '',
			isSubCompanyIdOnToken: false,
		};
	}

	async componentDidMount() {
		const permissions = await navigator.permissions.query({
			name: 'geolocation',
		});
		const geolocationAllowed = permissions?.state === 'granted';
		this.setState({ geolocationAllowed });
		if (geolocationAllowed) this.getCurrentLocation();
		// Double check to make sure cognito id does not already exist for this user
		const cognitoId = get(this.state, 'tokenData.cognito:username');
		const subCompanyName = get(this.props, 'tokenData.custom:subCompany');
		const subCompanies = get(this.props, 'company.subCompanies', []);
		const subCompany = findItemByNameAndKeywords(subCompanyName, subCompanies);
		const subCompanyId = subCompany ? subCompany.id : null;
		if (subCompanyId) this.setState({ isSubCompanyIdOnToken: true });
		const { data } = await this.props.client.query({
			query: GetUserByCognitoId,
			variables: {
				cognitoId,
			},
		});
		const currentUser = {
			...data.getUserByCognitoId,
		};

		if (currentUser && currentUser.cognitoId) {
			// Found the user - do not let them login
			const error =
				'The provided user already exists. Please try logging in again';
			this.props.history.push({
				pathname: '/login',
				state: {
					err: error,
				},
			});
		}
	}

	onAuthentication = (authToken, currentUser) => {
		try {
			Cookies.set('jwt', authToken);
			window.location.href = '/dashboard';
		} catch (error) {
			console.log(error);
		}
	};

	onChange = (event_) => {
		const { value } = event_.target;
		this.setState({ currencyAbbrev: value });
	};

	getCurrentLocation = () => {
		if (navigator.geolocation) {
			const options = { timeout: 60_000 };
			navigator.geolocation.getCurrentPosition(
				this.showLocation,
				this.errorHandler,
				options
			);
		} else {
			console.log('Sorry, your browser does not support geolocation!');
		}
	};

	getUserGroupId = (userGroups, name) => {
		const userGroup = userGroups.find((group) => group.name === name);
		return get(userGroup, 'id');
	};

	addLocation = async () => {
		if (this.props.isGeolocationAvailable) {
			const lat = this.props?.coords?.latitude;
			const lon = this.props?.coords?.longitude;
			const location = await getLocation({ lat, lon }, 'apiKey');
			this.setState({ address: location?.address, location });
		} else {
			console.log('Location is not available');
		}
	};

	errorHandler = (error) => {
		if (error.code == 1) {
			console.log('Error: Access is denied!');
		} else if (error.code == 2) {
			console.log('Error: Position is unavailable!');
		}
	};

	handleSelectDepartment = (department) => {
		this.setState({ department });
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		const { onCreateUser, company, setCurrentUser } = this.props;
		this.setState({
			lengthError: false,
			serverError: false,
			pageIsValid: true,
		});
		this.props.form.validateFields(async (error, values) => {
			if (error) {
				this.setState({ serverError: true });
				this.setState({ loading: false });
			}

			if (this.pageIsValid(values)) {
				this.setState({ loading: true });
				if (
					values.password &&
					!validator.isStrongPassword(values.password, {
						minLength: 10,
						minLowercase: 1,
						minUppercase: 1,
						minNumbers: 1,
						minSymbols: 1,
					})
				) {
					this.setState({ lengthError: true });
					this.setState({ loading: false });
					return;
				}
			}

			const userEmail = get(values, 'emailAddress', '')
				.split(' ')
				.join('')
				.toLowerCase();
			const emailExists = await this.props.client
				.query({
					query: GetUserByEmailAddress,
					variables: {
						emailAddress: userEmail,
					},
					fetchPolicy: 'network-only',
				})
				.then((result) => {
					this.setState({ emailError: true });
					this.setState({ loading: false });
				})
				.catch(async (error_) => {
					Auth.signUp({
						username: uuid(),
						password: values.password,
						attributes: {
							email: userEmail,
						},
					})
						.then(() => {
							Auth.signIn(
								userEmail.toLowerCase().trim(),
								values.password.trim()
							).then(async (data) => {
								const token = get(
									data,
									'signInUserSession.accessToken.jwtToken'
								);
								const userGroupId = this.getUserGroupId(
									get(this.props, 'company.userGroups', []),
									'Default'
								);
								const input = {
									cognitoId: get(data, 'username'),
									companyId: get(company, 'id'),
									emailAddress: userEmail,
									role: 'employee',
									firstName: get(values, 'firstName'),
									lastName: get(values, 'lastName'),
									title: get(values, 'title'),
									departmentId: get(values, 'department.key'),
									avatar: null,
									lastLogin: null,
									active: true,
									createdById: get(company, 'id'),
									userGroupId,
									currency: get(this.state, 'currencyAbbrev', 'USD'),
									languageCode: 'US',
								};
								if (this.state?.location)
									input.location = JSON.stringify(this.state.location);
								if (get(values, 'subCompany.key')) {
									input.subCompanyId = get(values, 'subCompany.key');
								}

								onCreateUser({
									input,
								}).then(async (d) => {
									const res = await this.props.client.query({
										query: GetUserByCognitoId,
										variables: {
											cognitoId: get(d, 'cognitoId'),
										},
									});
									const currentUser = {
										...res.data.getUserByCognitoId,
									};
									if (currentUser) setCurrentUser(currentUser);
									this.onAuthentication(token, currentUser);
								});
							});
						})
						.catch((error_) => {
							this.setState({ serverError: true });
							this.setState({ loading: false });
							console.error(error_);
						});
				});
		});
	};

	isGoogle = () => {
		try {
			if (window.google) return true;
		} catch (error) {
			console.log(error);
			return false;
		}
	};

	pageIsValid = (values) => {
		this.setState({ emailError: false, passwordError: false });
		let loginFormPickerSettings = parse(
			get(this.props, 'company.loginFormPickerSettings', [])
		);
		loginFormPickerSettings = loginFormPickerSettings
			? loginFormPickerSettings
			: [];
		loginFormPickerSettings = loginFormPickerSettings.map((picker, index) => {
			return { ...picker, index };
		});
		const filteredPickers = loginFormPickerSettings.filter(
			(picker) => get(picker, 'required') === true
		);
		const requiredPickers = filteredPickers.map((picker) => {
			return {
				key: `picker${picker.index}`,
				message: get(picker, 'error', 'Please select an item'),
			};
		});
		let isValid = true;
		const requiredErrors = {};
		let subCompanies = get(this.props, 'company.subCompanies');
		subCompanies = subCompanies ? subCompanies : [];
		const requestFields = [...requiredPickers, ...requiredFields];
		for (const { key, message } of requestFields) {
			if (key === 'department' && (!values[key] || values[key].length === 0)) {
				requiredErrors[key] = message;
				isValid = false;
			}

			if (!values[key]) {
				if (
					key === 'subCompany' &&
					subCompanies.length > 0 &&
					!this.state.isSubCompanyIdOnToken
				) {
					requiredErrors[key] = message;
					isValid = false;
				} else if (key !== 'subCompany') {
					requiredErrors[key] = message;
					isValid = false;
				}
			}
		}

		this.setState({
			requiredErrors,
		});
		return isValid;
	};

	showLocation = async (position) => {
		const lat = position?.coords?.latitude;
		const lon = position?.coords?.longitude;
		const location = await getLocation({ lat, lon }, 'apiKey');
		this.setState({ address: location?.address, location });
	};

	renderCustomPickers() {
		const FormItem = Form.Item;
		const { Option } = Select;
		const { getFieldDecorator } = this.props.form;
		const { requiredErrors } = this.state;
		let loginFormPickerSettings = parse(
			get(this.props, 'company.loginFormPickerSettings')
		);
		loginFormPickerSettings = loginFormPickerSettings
			? loginFormPickerSettings
			: [];
		const pickers = loginFormPickerSettings.map((picker, index) => {
			let options = [];
			options = picker.values.map((value) => {
				return (
					<Option key={value} value={value}>
						{value}
					</Option>
				);
			});
			const newPicker = (
				<FormItem className={InputStyles2}>
					<label className={LabelStyles}>{get(picker, 'name')}</label>
					<div className={SortDown}>
						{getFieldDecorator(
							`picker${index}`,
							{}
						)(
							<Select
								labelInValue
								placeholder={get(picker, 'placeholder')}
								showArrow={false}
								className={SelectStyles}
							>
								{options}
							</Select>
						)}
						<div>
							<WebIcon color={COLORS.darkGray} size={14} name="sort-down" />
						</div>
					</div>
					{requiredErrors && requiredErrors[`picker${index}`] ? (
						<div>
							<p className={errorStyles}>
								{' '}
								{requiredErrors[`picker${index}`]}{' '}
							</p>
						</div>
					) : null}
				</FormItem>
			);
			return newPicker;
		});

		const result = [];
		const groups = _.chunk(pickers, 2);
		for (const group of groups) {
			if (group.length === 1) result.push(group[0]);
			if (group.length === 2) {
				const double = (
					<div>
						<Col md={11} xs={24}>
							{group[0]}
						</Col>
						<Col md={{ span: 12, offset: 1 }} xs={24}>
							{group[1]}
						</Col>
					</div>
				);
				result.push(double);
			}
		}

		return result;
	}

	handleSelectLocation(location) {
		this.setState(location);
	}

	handleChangeLocation(address) {
		this.setState({ address });
	}

	renderLocation = () => {
		const { allMultiLingualData } = this.props;
		const languageCode = 'US';
		const { Option } = Select;
		const options = [];
		Object.keys(USStates).map((key) =>
			options.push(<Option key={key}>{USStates[key]}</Option>)
		);
		return (
			<div>
				<PageItem>
					<Col md={24} xs={48}>
						<label className={LabelStyles}>
							{ml('Location', { languageCode }, allMultiLingualData)}
						</label>
						{this.state?.geolocationAllowed && (
							<Button
								className={AddLocationButton}
								style={{ marginLeft: 5 }}
								onClick={this.addLocation}
							>
								<EnvironmentOutlined />
								<span className={buttonText}>
									{ml(
										'Autofill Location',
										{ languageCode },
										allMultiLingualData
									)}
								</span>
							</Button>
						)}
					</Col>
					<Col md={24} xs={16}>
						<div>
							<PlacesAutocomplete
								address={this.state.address}
								auth="apiKey"
								setAddress={this.handleChangeLocation.bind(this)}
								onSelect={this.handleSelectLocation.bind(this)}
							/>
						</div>
					</Col>
				</PageItem>
			</div>
		);
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { emailError, lengthError, serverError, requiredErrors, submitting } =
			this.state;
		const { allMultiLingualData } = this.props;
		const languageCode = 'US';
		const FormItem = Form.Item;
		const { Option } = Select;

		const theme = get(this.props, 'company.theme');
		const whiteLabel = get(this.props, 'company.whiteLabel');
		const sortedDepartments = _.sortBy(this.props.departments, ['name']);
		let options = [];
		options = sortedDepartments
			.filter((department) => department.active === true)
			.map((department) => {
				return (
					<Option key={department.id} value={department.id}>
						{department.name}
					</Option>
				);
			});

		let optionsSubCompanies = [];
		const sortedSubCompanies = _.sortBy(
			get(this.props, 'company.subCompanies', []),
			['name']
		);
		if (sortedSubCompanies !== null) {
			optionsSubCompanies = sortedSubCompanies.map((subCompany) => {
				return (
					<Option key={subCompany.id} value={subCompany.id}>
						{subCompany.name}
					</Option>
				);
			});
		}

		if (submitting) {
			return (
				<div style={{ width: '100%' }}>
					<Spinner />
				</div>
			);
		}

		const departmentHeader = ml(
			'Department',
			{ languageCode },
			allMultiLingualData
		);
		const initialDepartment = get(this.props, 'defaultDepartment.id') ? (
			<Option
				key={get(this.props, 'defaultDepartment.id', null)}
				value={get(this.props, 'defaultDepartment.id', null)}
			>
				{get(this.props, 'defaultDepartment.name', null)}
			</Option>
		) : (
			[]
		);
		return (
			<div>
				<Row>
					<h1 className={TitleStyles}>
						{ml(`Let's Get Started`, { languageCode }, allMultiLingualData)}
					</h1>
				</Row>
				<Row>
					<h2 className={SubTitleStyles}>
						{ml(
							'First complete your profile',
							{ languageCode },
							allMultiLingualData
						)}
					</h2>
				</Row>
				<Form className={FormStyles}>
					<Row>
						<div>
							<Col md={11} xs={24}>
								<FormItem className={InputStyles2}>
									<label className={LabelStyles}>
										{ml('First Name', { languageCode }, allMultiLingualData)}
									</label>
									{getFieldDecorator('firstName', {})(<Input />)}
									{requiredErrors && requiredErrors.firstName ? (
										<div>
											<p className={errorStyles}>
												{' '}
												{requiredErrors.firstName}{' '}
											</p>
										</div>
									) : null}
								</FormItem>
							</Col>
							<Col md={{ span: 12, offset: 1 }} xs={24}>
								<FormItem className={InputStyles2}>
									<label className={LabelStyles}>
										{ml('Last Name', { languageCode }, allMultiLingualData)}
									</label>
									{getFieldDecorator('lastName', {})(<Input />)}
									{requiredErrors && requiredErrors.lastName ? (
										<div>
											<p className={errorStyles}> {requiredErrors.lastName} </p>
										</div>
									) : null}
								</FormItem>
							</Col>
						</div>
					</Row>
					<Row>
						<div>
							<Col md={11} xs={24}>
								<FormItem className={InputStyles2}>
									<label className={LabelStyles}>{departmentHeader}</label>
									<div className={SortDown}>
										{getFieldDecorator(
											'department',
											{}
										)(
											<Select
												labelInValue
												placeholder={`Select a ${departmentHeader}`}
												showArrow={false}
												className={SelectStyles}
											>
												{options}
											</Select>
										)}
										<div>
											<WebIcon
												color={COLORS.darkGray}
												size={14}
												name="sort-down"
											/>
										</div>
									</div>

									{requiredErrors && requiredErrors.department ? (
										<div>
											<p className={errorStyles}>
												{' '}
												{requiredErrors.department}{' '}
											</p>
										</div>
									) : null}
								</FormItem>
							</Col>
							<Col md={{ span: 12, offset: 1 }} xs={24}>
								<FormItem className={InputStyles2}>
									<label className={LabelStyles}>
										{ml('Job Title', { languageCode }, allMultiLingualData)}
									</label>
									{getFieldDecorator('title', {})(<Input />)}
									{requiredErrors && requiredErrors.title ? (
										<div>
											<p className={errorStyles}> {requiredErrors.title} </p>
										</div>
									) : null}
								</FormItem>
							</Col>
						</div>
					</Row>
					{optionsSubCompanies.length > 0 &&
						!this.state.isSubCompanyIdOnToken && (
							<Row>
								<div>
									<Col md={24} xs={24}>
										<FormItem className={InputStyles2}>
											<label className={LabelStyles}>
												{ml('Company', { languageCode }, allMultiLingualData)}
											</label>
											<div className={SortDown}>
												{getFieldDecorator(
													'subCompany',
													{}
												)(
													<Select
														labelInValue
														placeholder="Select a company"
														showArrow={false}
														className={SelectStyles}
													>
														{optionsSubCompanies}
													</Select>
												)}
												<div>
													<WebIcon
														color={COLORS.darkGray}
														size={14}
														name="sort-down"
													/>
												</div>
											</div>

											{requiredErrors && requiredErrors.subCompany ? (
												<div>
													<p className={errorStyles}>
														{' '}
														{requiredErrors.subCompany}{' '}
													</p>
												</div>
											) : null}
										</FormItem>
									</Col>
								</div>
							</Row>
						)}
					<Row>{this.renderCustomPickers()}</Row>
					<Row>{this.renderLocation()}</Row>
					<FormItem className={InputStyles2}>
						<label className={LabelStyles}>
							{ml('Email Address', { languageCode }, allMultiLingualData)}
						</label>
						{getFieldDecorator('emailAddress', {
							rules: [
								{
									type: 'email',
									message: ml(
										'Not a valid email address.',
										{ languageCode },
										allMultiLingualData
									),
								},
							],
						})(<Input />)}
						{emailError ? (
							<Alert
								className={AlertError}
								type="error"
								message="A user with this email address already exists."
							/>
						) : null}
					</FormItem>
					<FormItem className={InputStyles2}>
						<label className={LabelStyles}>Create Password</label>
						{getFieldDecorator('password', {
							rules: [
								{
									required: true,
									message: 'Password Required',
								},
							],
						})(<Input.Password className={InputStyles2} autoComplete="on" />)}
						{lengthError ? (
							<Alert
								className={AlertError}
								type="error"
								message="Your password must have minimum 10 characters, at least one upper case, one lower case letter, a number and a special character."
							/>
						) : null}
						{requiredErrors && requiredErrors.password ? (
							<div>
								<p className={errorStyles}> {requiredErrors.password} </p>
							</div>
						) : null}
					</FormItem>
					{!whiteLabel && (
						<p className={MiscText}>
							{ml(
								'By creating a profile you agree to the ERIN',
								{ languageCode },
								allMultiLingualData
							)}{' '}
							<a
								href="http://erinapp.com/terms-of-use"
								rel="noopener noreferrer"
								target="_blank"
							>
								{ml('Terms of Use', { languageCode }, allMultiLingualData)}
							</a>{' '}
							{ml('and', { languageCode }, allMultiLingualData)}{' '}
							<a
								href="https://erinapp.com/privacy-policy"
								rel="noopener noreferrer"
								target="_blank"
							>
								{ml('Privacy Policy', { languageCode }, allMultiLingualData)}
							</a>
							{', '}
							{ml(
								'and agree that we can send you information about jobs and referrals at your company. You can opt out at any time.',
								{ languageCode },
								allMultiLingualData
							)}
						</p>
					)}
					{serverError ? (
						<Alert
							type="error"
							message={ml(
								'There was a problem creating your account. Please try again.',
								{ languageCode },
								allMultiLingualData
							)}
						/>
					) : null}

					<FormItem className={SubmitButtonContainer(theme)}>
						<Button
							className={SubmitButton(theme)}
							onClick={_.debounce(this.handleSubmit, 300, {
								leading: true,
								trailing: false,
							})}
						>
							{ml('Get Started', { languageCode }, allMultiLingualData)}
							<ArrowRightOutlined className={CheckIcon} />
						</Button>
					</FormItem>
				</Form>
			</div>
		);
	}
}

export default withApollo(
	withRouter(
		Form.create()(
			geolocated({
				positionOptions: {
					enableHighAccuracy: true,
				},
				userDecisionTimeout: 60_000,
			})(NewCompanyUserForm)
		)
	)
);
