import { useState, useEffect } from 'react';
import { Card, List, Spin, Button } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import ViewRewardModal from '../../ViewRewardModal/ViewRewardModal.jsx';
import CoreBadge from 'src/RnRCommonComponent/CoreBadge/CoreBadge';
import { showBadgeNumber } from './helper.js';
import { addCommas } from '../../../rewardsAndRecognitionHelper/utils.js';

function useWindowSize() {
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return width;
}

const RewardList = (props) => {
	const {
		handleIconClickEvent,
		title,
		badgeList,
		badgeType,
		hasMore,
		isLoading,
		fetchMoreData,
		isDetailsShown,
		handleOpenViewReward,
		handleCloseViewReward,
		isViewRewardModalVisible,
		rewardDetails,
		isModalLoaderVisible,
		getMyBadge,
		isPointsShown,
		isIssuedVisible,
	} = props;
	const width = useWindowSize();

	const [badgeExpanded, setBadgeExpanded] = useState(false);

	const visibleBadges = badgeExpanded
		? badgeList
		: badgeList?.slice(0, showBadgeNumber(width, badgeType));

	const handleOnClick = (badge) => {
		if (handleIconClickEvent) {
			handleIconClickEvent(badge);
		}
	};
	const badgesData = (
		visibleBadges?.map((badge, index) => {
			return (
				<>
				<div
					key={index}
					className="badges-container"
					disabled={badge.visibility === false}
					style={{
						opacity:
							badge.visibility || getMyBadge
								? 1
								: 0.3,
					}}
				>
					<div
						className="badges-container-edit-target"
						style={{ cursor: 'pointer' }}
					>
						<div
							className="badges-img"
							onClick={() => {
								handleOnClick(badge);
								!handleIconClickEvent &&
								!isDetailsShown &&
								handleOpenViewReward(
									badge.id
								);
							}}
						>
							{
								<div className="badges-svg">
									<CoreBadge
										badgeType={
											badge?.badgeTypeCode
										}
										badgeIcon={
											badge?.iconCode
										}
										label={badge?.label}
										color={badge?.color}
										gradientColor={badge?.gradientColor || badge?.gradientcolor}
									/>
								</div>
							}
							<div className="text-center img-label">
								{isPointsShown ? (
									addCommas(
										badge.points
									) + ' Points'
								) : isIssuedVisible ? (
									<div
										style={{
											display: 'flex',
											flexDirection:
												'column',
											alignItems:
												'center',
										}}
									>
										<div
											style={{
												marginBottom:
													'-0.5rem',
											}}
										>
											{addCommas(
												badge.issued
											)}
										</div>
										<div>Issued</div>
									</div>
								) : (
									badge.name
								)}
							</div>
						</div>
					</div>
			{isDetailsShown ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '2px',
					}}
				>
					<Button
						type="link"
						onClick={() => {
							handleOpenViewReward(
								badge.id
							);
						}}
						className="text-center img-label"
					>
						Details
					</Button>
				</div>
			) : null}
				</div>
				</>
			)
		})
		);

	return (
		<div className="d-card ">
			<div className="d-card-head">
				<h4 className="d-title-no-case">{title}</h4>
			</div>
			<div
				id={badgeType}
				style={{
					maxHeight: badgeExpanded ? '500px' : isDetailsShown ? '190px' : '170px',
					height: '100%',
					overflowY: 'scroll',
					WebkitOverflowScrolling: 'touch'
				}}
			>
			<List
				grid={{
					gutter: 8,
					xs: 1,
					sm: 2,
					md: 3,
					lg: 3,
					xl: 4,
					xxl: 6,
				}}
				dataSource={badgesData}
				renderItem={(item) => (
					<List.Item>
						<Card className="badge-card" style={{textAlign: 'center', padding: 0, border: 'none'}}>{item}</Card>
					</List.Item>
				)}
			/>
			</div>
			{
				<div className="d-flex justify-content-center align-items-center  m-2">
					{visibleBadges?.length && isLoading === true ? (
						<Spin className="spinner" />
					) : (
						<div
							// disabled
							onClick={() => {
								setBadgeExpanded(!badgeExpanded);
								if (!badgeExpanded && hasMore) {
									fetchMoreData();
								}
							}}
							className="d-flex justify-content-center align-items-center collpse-btn"
						>
							{badgeExpanded ? (
								<>
									Collapse <UpOutlined className="ml-1" />
								</>
							) : visibleBadges?.length >= badgeList?.length ? (
								<></>
							) : (
								<>
									Expand <DownOutlined className="ml-1" />
								</>
							)}
						</div>
					)}
				</div>
			}
			<ViewRewardModal
				isViewRewardModalVisible={isViewRewardModalVisible}
				handleCloseViewReward={handleCloseViewReward}
				rewardDetails={rewardDetails}
				isModalLoaderVisible={isModalLoaderVisible}
				isSharingVisible={false}
			/>
		</div>
	);
};

export default RewardList;
