import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Select, message } from 'antd';
import dayjs from 'dayjs';
import _, { upperFirst } from 'lodash';
import get from 'lodash/get';
import { Component } from 'react';
import PlacesAutocomplete from 'src/_shared/components/location/PlacesAutocomplete.jsx';
import { Link } from 'react-router-dom';
import { GetUserByCognitoId } from 'src/_shared/api/graphql/custom/users/';
import ResetPasswordModal from '../../_shared/components/reset-password-modal/ResetPasswordModalComponent.jsx';
import SupportResetPasswordModal from '../../_shared/components/reset-password-modal/SupportResetPasswordModalComponent.jsx';
import { USER_ROLES } from '../../_shared/constants';
import {
	downloadFromS3Signed,
	getLocation,
	ml,
	parse,
	pascalCase,
} from '../../_shared/services/utils.js';
import DepartmentItem from './DepartmentItemComponent.jsx';
import DisableUserButton from './DisableUserBtnComponent.jsx';
import SelectDepartments from './SelectDepartmentsComponent.jsx';
import SelectRole from './SelectRoleComponent.jsx';
import { USStates } from './copy.js';

class EmployeeInfoCard extends Component {
	constructor(props) {
		super(props);
		const location = parse(props?.employee?.location);
		const address = [location?.city, location?.state, location?.country]
			.filter(Boolean)
			.join(', ');
		this.state = {
			address,
			currentCity: '',
			currentCountry: '',
			currentState: '',
			isEdit: false,
			editedAvatar: '',
			editedCity: '',
			editedCountry: '',
			editedDepartment: {},
			editedEmployeeType: {},
			editedEmailAddress: '',
			editedEmployeeId: '',
			editedFirstName: '',
			editedLastName: '',
			editedPosition: '',
			editedState: '',
			editedSubCompany: {
				key: props.employee.subCompanyId,
				label: props.employee.subCompany
					? props.employee.subCompany.name
					: 'Main Company (Default)',
			},
			employee: {},
			geolocationAllowed: false,
			hasSelectedDepartment: false,
			hasSelectedSubCompany: false,
			hasSelectedEmployeeGroup: false,
			managerEmployees: this.props.currentUser.company
				?.disableManagerPermissionsByType
				? JSON.parse(
						this.props.currentUser.company.disableManagerPermissionsByType
					).managerEmployees
				: 'hidden',
			avatarImageURL: '',
		};
	}

	async componentDidMount() {
		const { employee } = this.props;
		const { location, currency } = employee;
		const permissions = await navigator.permissions.query({
			name: 'geolocation',
		});
		const geolocationAllowed = permissions?.state === 'granted';
		const loc =
			location !== null && location !== undefined ? parse(location) : '';
		const city = loc?.city || '';
		const state = loc?.state || '';
		const country = loc?.country || '';
		const lat = loc?.lat || '';
		const lon = loc?.long || '';
		const address = [city, state, country].filter(Boolean).join(', ');
		const avatarImage = get(this.props.employee, 'avatar', false);
		let presignedUrl = '';
		if (avatarImage) {
			presignedUrl = await downloadFromS3Signed(avatarImage.key);
		}

		this.setState({
			address,
			employee,
			editedEmailAddress: employee ? employee.emailAddress : '',
			editedFirstName: employee.firstName,
			editedLastName: employee.lastName,
			editedPosition: employee.title,
			editedAvatar: employee.avatar,
			editedEmployeeId: employee.employeeId,
			editedCity: city,
			editedState: state,
			editedCountry: country,
			editedLat: lat,
			editedLon: lon,
			geolocationAllowed,
			currentCity:
				location !== null && JSON.parse(location).city !== null
					? JSON.parse(location).city
					: '',
			currentState:
				location !== null && JSON.parse(location).state !== null
					? JSON.parse(location).state
					: '',
			currentCountry:
				location !== null && JSON.parse(location).country !== null
					? JSON.parse(location).country
					: '',
			currentLat:
				location !== null && JSON.parse(location).lat !== null
					? JSON.parse(location).lat
					: '',
			currentLon:
				location !== null && JSON.parse(location).lon !== null
					? JSON.parse(location).lon
					: '',
			avatarImageURL: presignedUrl,
			// -- DROPDOWN SELECTORS
			editedDepartment: this.state.hasSelectedDepartment
				? this.state.editedDepartment
				: {
						key: employee.departmentId,
						label: employee.department.name,
					},
			editedEmployeeType: this.state.hasSelectedEmployeeGroup
				? this.state.editedEmployeeType
				: {
						key: employee.userGroupId,
						label: employee.userGroup?.name,
					},
		});
	}

	componentDidUpdate(prevProps) {
		const { employee } = this.props;

		if (prevProps.employee !== employee) {
			const { location, currency } = employee;
			const loc =
				location !== null && location !== undefined ? parse(location) : '';
			const city = loc?.city || '';
			const state = loc?.state || '';
			const country = loc?.country || '';
			const address = [city, state, country].filter(Boolean).join(', ');
			this.setState({
				address,
				employee,
				editedFirstName: employee.firstName,
				editedLastName: employee.lastName,
				editedPosition: employee.title,
				editedAvatar: employee.avatar,
				editedEmployeeId: employee.employeeId,
				editedCity: city,
				editedState: state,
				editedCountry: country,
				currentCity:
					location !== null && JSON.parse(location).city !== null
						? JSON.parse(location).city
						: '',
				currentState:
					location !== null && JSON.parse(location).state !== null
						? JSON.parse(location).state
						: '',
				currentCountry:
					location !== null && JSON.parse(location).country !== null
						? JSON.parse(location).country
						: '',
				// -- DROPDOWN SELECTORS
				editedDepartment: this.state.hasSelectedDepartment
					? this.state.editedDepartment
					: {
							key: employee.departmentId,
							label: employee.department.name,
						},
				editedEmployeeType: this.state.hasSelectedEmployeeGroup
					? this.state.editedEmployeeType
					: {
							key: employee.userGroupId,
							label: employee.userGroup?.name,
						},
			});
		}
	}

	autofillLocation = () => {
		try {
			return navigator.geolocation.getCurrentPosition(
				async (position) => {
					const input = {};
					const location = await getLocation({
						lat: position?.coords?.latitude,
						lon: position?.coords?.longitude,
					});
					console.log(location);
					for (const [key, value] of Object.entries(location)) {
						if (key === 'address') {
							input[key] = value;
						} else {
							input[`current${pascalCase(key)}`] = value;
							input[`edited${pascalCase(key)}`] = value;
						}
					}

					this.setState(input);
				},
				(error) => console.log(error)
			);
		} catch {}
	};

	cancelEdit = () => {
		const { employee } = this.state;
		message.error('Edit Cancelled');
		this.setState({
			isEdit: false,
			editedFirstName: employee.firstName,
			editedLastName: employee.lastName,
			editedPosition: employee.title,
			editedAvatar: employee.avatar,
			// -- DROPDOWN SELECTORS
			editedDepartment: {
				key: employee.departmentId,
				label: employee.department.name,
			},
			editedSubCompany: {
				key: employee.subCompanyId,
				label: employee.subCompany?.name,
			},
			editedEmployeeType: {
				key: employee.userGroupId,
				label: employee.userGroup.name,
			},
			hasSelectedDepartment: false,
			hasSelectedSubCompany: false,
			hasSelectedEmployeeGroup: false,
		});
	};

	editInfo = () => {
		this.setState({
			isEdit: true,
		});
	};

	employeeRole = (role) => {
		switch (role) {
			case 'employee': {
				return 'Employee';
			}

			case 'admin': {
				return 'Administrator';
			}

			case 'manager': {
				return 'Manager';
			}

			case 'superAdmin': {
				return 'Super Adminstrator';
			}

			default: {
				return null;
			}
		}
	};

	handleSubmit = async () => {
		const {
			address = null,
			editedAvatar,
			editedFirstName,
			editedLastName,
			editedPosition,
			editedDepartment,
			editedSubCompany,
			editedEmployeeId,
			employee,
			editedEmployeeType,
			editedCity = null,
			editedCounty = null,
			editedCountry = null,
			editedIsRemote = false,
			editedState = null,
			editedStreet = null,
			currentLat = null,
			editedLat = null,
			currentLon = null,
			editedLon = null,
			editedZip = null,
		} = this.state;
		const lat = editedLat ? editedLat : currentLat;
		const lon = editedLon ? editedLon : currentLon;
		const city = editedCity;
		const state = editedState;
		const street = editedStreet;
		const country = editedCountry;
		const county = editedCounty;
		const isRemote = editedIsRemote;
		const zip = editedZip;
		let location = {
			address,
			city,
			country,
			county,
			lat,
			lng: lon,
			lon,
			state,
			street,
			isRemote,
			zip,
		};
		if (lat && lon) location.coordinates = { lat, lon };
		location = JSON.stringify(location);
		const errors = [];
		const emailPattern =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (this.state.editedEmailAddress === '') {
			const error = new Error('Please enter a valid email address.');
			errors.push(error);
		}

		if (
			!emailPattern.test(this.state.editedEmailAddress) &&
			this.state.editedEmailAddress !== ''
		) {
			const error = new Error('Please enter a valid email address.');
			errors.push(error);
		}

		const content = get(errors[0], 'message', null);
		if (errors.length > 0) {
			console.log(content);
		} else {
			console.log('Updating User...', location);
			this.props.onUpdate({
				input: {
					active: employee.active,
					avatar: editedAvatar,
					companyId: employee.companyId,
					departmentId: editedDepartment.key,
					employeeId: editedEmployeeId,
					firstName: editedFirstName,
					id: employee.id,
					lastName: editedLastName,
					location,
					title: editedPosition,
					subCompanyId:
						editedSubCompany.key && editedSubCompany.key != '1'
							? editedSubCompany.key
							: null,

					userGroupId: editedEmployeeType.key,
				},
			});
		}

		message.success('Profile Edited');
		this.setState({
			isEdit: false,
		});
	};

	supportUserAccountTakeover = async (employee) => {
		const { setCurrentUser } = this.props;
		const { data } = await this.props.client.query({
			query: GetUserByCognitoId,
			variables: { cognitoId: employee.cognitoId },
		});
		const currentUser = {
			...data.getUserByCognitoId,
		};
		setCurrentUser(currentUser);
	};

	handleSelectLocation = async (location) => {
		try {
			const input = {};
			for (const [key, value] of Object.entries(location)) {
				if (key === 'address') {
					input[key] = value;
				} else {
					input[`current${pascalCase(key)}`] = value;
					input[`edited${pascalCase(key)}`] = value;
				}
			}

			this.setState(input);
		} catch {}
	};

	handleChangeLocation(address) {
		this.setState({ address });
	}

	renderLocation = () => {
		const { Option } = Select;
		const options = [];
		Object.keys(USStates).map((key) =>
			options.push(<Option key={key}>{USStates[key]}</Option>)
		);
		return (
			<>
				<PlacesAutocomplete
					address={this.state.address}
					setAddress={this.handleChangeLocation.bind(this)}
					onSelect={this.handleSelectLocation.bind(this)}
				/>
				{this.state?.geolocationAllowed && (
					<Button
						type="primary"
						size="large"
						style={{ marginLeft: 5 }}
						onClick={this.autofillLocation}
					>
						<i className="icon-plus" />
						<span>Autofill Location</span>
					</Button>
				)}
			</>
		);
	};

	render() {
		const {
			allDepartments,
			allSubCompanies,
			allUserGroups,
			employee,
			handleCancel,
			handleChangeRole,
			handleSupportCancel,
			onAddDepartment,
			onDeleteDepartment,
			onUpdate,
			visible,
			currentUser,
			allMultiLingualData,
		} = this.props;
		const {
			avatar,
			dateCreated,
			emailAddress,
			employeeId,
			firstName,
			id,
			lastLogin,
			lastName,
			location,
			managedDepartments,
			role,
			title,
		} = employee;
		const {
			editedSubCompany,
			editedDepartment,
			editedEmployeeType,
			isEdit,
			hasSelectedSubCompany,
			managerEmployees,
			avatarImageURL,
		} = this.state;
		const isManagerPermissionDisabled = Boolean(
			managerEmployees !== 'edit' && currentUser?.role === USER_ROLES.MANAGER
		);
		const firstLogin = dateCreated;
		const loc = JSON.parse(location);
		const city = loc?.city || '';
		const state = loc?.state || '';
		const country = loc?.country || '';
		const address = [city, state, country].filter(Boolean).join(', ');

		const editIcon = (
			<div>
				<Button type="link" onClick={this.editInfo}>
					<i className="edit" />
					<span>Edit Profile</span>
				</Button>
			</div>
		);

		const okButton = (
			<>
				<Popconfirm
					title="Confirm Updates?"
					placement="left"
					okText="Yes"
					cancelText="No"
					onConfirm={() => this.handleSubmit()}
				>
					<Button icon={<CheckCircleOutlined />} type="primary">
						Save
					</Button>
				</Popconfirm>
				<Popconfirm
					title="Discard edits?"
					placement="left"
					okText="Yes"
					cancelText="No"
					onConfirm={this.cancelEdit}
				>
					<Button icon={<CloseCircleOutlined />}>Cancel</Button>
				</Popconfirm>
			</>
		);
		const editFirstName = (
			<Input
				key="editFirstName"
				type="text"
				id="firstname"
				name="editedFirstName"
				placeholder="First Name"
				defaultValue={firstName}
				className="custom-input"
				onChange={(e) =>
					this.setState({
						editedFirstName: e.target.value,
					})
				}
			/>
		);
		const editLastName = (
			<Input
				key="editLastName"
				type="text"
				id="lastname"
				name="editedLastName"
				placeholder="Last Name"
				defaultValue={lastName}
				className="custom-input"
				onChange={(e) =>
					this.setState({
						editedLastName: e.target.value,
					})
				}
			/>
		);
		const editEmployeeId = (
			<Input
				key="editEmployeeId"
				type="text"
				id="employeeid"
				name="editedEmployeeId"
				placeholder="Employee ID"
				defaultValue={employeeId}
				className="custom-input"
				onChange={(e) =>
					this.setState({
						editedEmployeeId: e.target.value,
					})
				}
			/>
		);
		const editPosition = (
			<Input
				key="editPosition"
				id="position"
				name="editedPosition"
				placeholder="Position"
				defaultValue={title}
				className="custom-input"
				onChange={(e) =>
					this.setState({
						editedPosition: e.target.value,
					})
				}
			/>
		);
		const editSubCompanySelect = (
			<Select
				labelInValue
				defaultValue={{
					key: editedSubCompany.key ? editedSubCompany.key : '',
					label: editedSubCompany.label ? editedSubCompany.label : '',
				}}
				value={{
					key: editedSubCompany.key ? editedSubCompany.key : '',
					label: editedSubCompany.label ? editedSubCompany.label : '',
				}}
				className="custom-input"
				placeholder={<p style={{ fontSize: 16, margin: 0 }}>Add a SubComany</p>}
				onSelect={(value) => {
					this.setState({
						editedSubCompany: value == '1' ? '' : value,
						hasSelectedSubCompany: true,
					});
				}}
			>
				{_.sortBy(allSubCompanies, [
					(subCompany) => {
						return subCompany.name.toLowerCase();
					},
				]).map((subCompany) => {
					return (
						<Select.Option key={subCompany.id}>{subCompany.name}</Select.Option>
					);
				})}
			</Select>
		);
		const editDepartmentSelect = (
			<Select
				labelInValue
				defaultValue={{
					key: editedDepartment.key,
					label: editedDepartment.label,
				}}
				value={{
					key: editedDepartment.key,
					label: editedDepartment.label,
				}}
				className="custom-input"
				placeholder={
					<p style={{ fontSize: 16, margin: 0 }}>Add a Department</p>
				}
				onSelect={(value) => {
					this.setState({
						editedDepartment: value,
						hasSelectedDepartment: true,
					});
				}}
			>
				{_.sortBy(allDepartments, [
					(department) => {
						return department.name.toLowerCase();
					},
				])
					.filter((department) => department.active)
					.map((department) => {
						return (
							<Select.Option key={department.id}>
								{department.name}
							</Select.Option>
						);
					})}
			</Select>
		);
		const editEmployeeTypeSelect = (
			<Select
				labelInValue
				defaultValue={{
					key: editedEmployeeType.key,
					label: editedEmployeeType.label,
				}}
				className="custom-input"
				value={{
					key: editedEmployeeType.key,
					label: editedEmployeeType.label,
				}}
				placeholder={
					<p style={{ fontSize: 16, margin: 0 }}>Add a Employee Group</p>
				}
				onSelect={(value) =>
					this.setState({
						editedEmployeeType: value,
						hasSelectedEmployeeGroup: true,
					})
				}
			>
				{_.sortBy(allUserGroups, [
					(group) => {
						return group.name.toLowerCase();
					},
				]).map((group) => {
					return <Select.Option key={group.id}>{group.name}</Select.Option>;
				})}
			</Select>
		);
		const editPermissions = (
			<>
				<p className="ed-subheading">
					Managers are able to manage jobs and referrals in their departments,
					Administrators can perform all functions.
				</p>
				<div className="custom-form-group">
					<label>Role:</label>
					<SelectRole role={role} handleChangeRole={handleChangeRole} />
				</div>
				{role === 'manager' ? (
					<>
						<div className="custom-form-group">
							<label>Manager Permissions:</label>
							<SelectDepartments
								departments={allDepartments}
								managedDepartments={managedDepartments}
								role={role}
								id={id}
								onAddDepartment={onAddDepartment}
							/>
						</div>
						<DepartmentItem
							managedDepartments={managedDepartments}
							onDeleteDepartment={onDeleteDepartment}
						/>
					</>
				) : null}
			</>
		);

		const supportAdmin = parse(get(this.props, 'currentUserAdmin'));
		const supportAdminPermissions = get(supportAdmin, 'permissions') === '*';
		const supportMenuHidden = get(supportAdmin, 'hidden', false);
		return (
			<>
				{!isManagerPermissionDisabled && (
					<div className="ed-actions">{isEdit ? okButton : editIcon}</div>
				)}

				<div className="ed-card">
					<div className="employee-image">
						{avatar === null || avatar === '' ? (
							<>
								{firstName[0]} {lastName[0]}
							</>
						) : (
							<img
								className="custom-img"
								src={avatarImageURL}
								alt={`${firstName} ${lastName}`}
							/>
						)}
					</div>
					<div className="employee-detail">
						{isEdit ? (
							<div className="ed-edit-name">
								<div className="custom-form-group block-details">
									<label>First Name</label>
									{editFirstName}
								</div>
								<div className="custom-form-group block-details">
									<label>Last Name</label>
									{editLastName}
								</div>
							</div>
						) : (
							<h4 className="ed-name">
								{firstName} {lastName}
							</h4>
						)}
						<div className="ed-email">
							<i className="icon-envelope-outline" style={{ fontSize: 18 }} />
							<Link to="#">{emailAddress}</Link>
						</div>
						{firstLogin && (
							<p className="ed-other-details">
								<span className="label">
									{ml('First Login', currentUser, allMultiLingualData)}:
								</span>
								{dayjs(firstLogin).format('M/D/YYYY')}
							</p>
						)}
						{lastLogin ? (
							<p className="ed-other-details">
								<span className="label">
									{ml('Last Web Login', currentUser, allMultiLingualData)}:
								</span>
								{dayjs(lastLogin).format('M/D/YYYY')}
							</p>
						) : (
							<p className="ed-other-details">
								<span className="label">Last Web Login:</span> Never
							</p>
						)}
						{employee.lastMobileLogin ? (
							<p className="ed-other-details">
								<span className="label">
									{ml('Last Mobile Login', currentUser, allMultiLingualData)}:
								</span>
								{dayjs(employee.lastMobileLogin).format('M/D/YYYY')}
							</p>
						) : (
							<p className="ed-other-details">
								<span className="label">Last Mobile Login:</span> Never
							</p>
						)}

						{isEdit ? (
							<div className="custom-form-group">
								<label>
									{ml('Department', currentUser, allMultiLingualData)}:
								</label>
								{editDepartmentSelect}
							</div>
						) : (
							<p className="ed-other-details">
								<span className="label">
									{ml('Department', currentUser, allMultiLingualData)}:
								</span>
								{editedDepartment.label}
							</p>
						)}
						{isEdit ? (
							<div className="custom-form-group">
								<label>
									{ml('Sub Company', currentUser, allMultiLingualData)}:
								</label>
								{editSubCompanySelect
									? editSubCompanySelect
									: hasSelectedSubCompany}
							</div>
						) : (
							<p className="ed-other-details">
								<span className="label">
									{ml('Sub Company', currentUser, allMultiLingualData)}:
								</span>
								{editedSubCompany.label
									? editedSubCompany.label
									: get(
											this.props,
											'currentUser.subCompany.name',
											'employee.subCompany.name',
											'Main Company (Default)'
										)}
							</p>
						)}

						{isEdit ? (
							<div className="custom-form-group">
								<label>
									{ml('Job Title', currentUser, allMultiLingualData)}:
								</label>
								{editPosition}
							</div>
						) : (
							<p className="ed-other-details">
								<span className="label">
									{ml('Job Title', currentUser, allMultiLingualData)}:
								</span>
								{title}
							</p>
						)}

						{isEdit === true ? null : (
							<p className="ed-other-details">
								<span className="label">
									{ml('Role', currentUser, allMultiLingualData)}:
								</span>
								{upperFirst(role)}
							</p>
						)}
						{isEdit ? (
							<div className="custom-form-group">
								<label>
									{ml('Employee Group', currentUser, allMultiLingualData)}:
								</label>
								{editEmployeeTypeSelect}
							</div>
						) : (
							<p className="ed-other-details">
								<span className="label">
									{ml('Employee Group', currentUser, allMultiLingualData)}:
								</span>
								{editedEmployeeType.label}
							</p>
						)}
						{isEdit ? (
							<div className="custom-form-group">
								<label>
									{ml('Employee ID', currentUser, allMultiLingualData)}:
								</label>
								{editEmployeeId}
							</div>
						) : (
							<p className="ed-other-details">
								<span className="label">
									{ml('Employee ID', currentUser, allMultiLingualData)}:
								</span>
								{employeeId ? employeeId : null}
							</p>
						)}

						{isEdit ? (
							<div className="custom-form-group">
								<label>
									{ml('Location', currentUser, allMultiLingualData)}:
								</label>
								{this.renderLocation()}
							</div>
						) : (
							<p className="ed-other-details">
								<span className="label">
									{ml('Location', currentUser, allMultiLingualData)}:
								</span>
								{address}
							</p>
						)}

						{isEdit ? editPermissions : null}

						{!isManagerPermissionDisabled && (
							<>
								<h5>{ml('Actions', currentUser, allMultiLingualData)}</h5>
								<DisableUserButton
									employee={employee}
									disableUser={ml(
										'Disable User',
										currentUser,
										allMultiLingualData
									)}
									enableUser={ml(
										'Enable User',
										currentUser,
										allMultiLingualData
									)}
									onUpdate={onUpdate}
								/>
							</>
						)}
						<br />
						{supportAdminPermissions && !supportMenuHidden && (
							<>
								<Button
									type="link"
									onClick={() => this.supportUserAccountTakeover(employee)}
								>
									Support Login as {get(employee, 'firstName')}{' '}
									{get(employee, 'lastName')}
								</Button>
								<br />
								<Button
									type="link"
									onClick={() => this.props.toggleResetPasswordModal(employee)}
								>{`Support Reset ${get(employee, 'firstName')} ${get(
									employee,
									'lastName'
								)}'s Email/Password`}</Button>
							</>
						)}
					</div>

					<ResetPasswordModal
						visible={visible}
						handleCancel={handleCancel}
						employee={employee}
					/>
					<SupportResetPasswordModal
						visible={this.props.showSupportPasswordResetModal}
						handleCancel={handleSupportCancel}
						employee={employee}
					/>
				</div>
			</>
		);
	}
}
export default EmployeeInfoCard;
