import { DownloadOutlined } from '@ant-design/icons';
import { Table } from 'antd';

export const ReportHistoryTable = ({ dataSource }) => {
	const sortByAlph = (a, b) => {
		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	};

	const columns = [
		{
			title: 'File Name',
			dataIndex: 'name',
			sorter: (a, b) => sortByAlph(a.name, b.name),
		},
		{
			title: 'Date Created',
			dataIndex: 'lastModified',
			render: (text) => new Date(text).toLocaleString(),
			sorter: (a, b) => sortByAlph(a.lastModified, b.lastModified),
		},
		{
			title: 'Download',
			dataIndex: 'presignedUrl',
			render: (text) => (
				<a href={text}>
					<DownloadOutlined />
				</a>
			),
			align: 'center',
		},
	];

	return (
		<div className="table-card">
			<Table
				pagination={{ pageSize: 25, showSizeChanger: false }}
				rowKey={(record) => record.id}
				dataSource={dataSource}
				columns={columns}
				scroll={{ x: 656 }}
			/>
		</div>
	);
};
