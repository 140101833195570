import { ArrowLeftOutlined, MinusOutlined } from '@ant-design/icons';
import { Avatar, Button, Table, message } from 'antd';
import dayjs from 'dayjs';
import get from 'lodash/get';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { USER_ROLES } from '../../constants/userRolesEnum.js';
import { formatDate, ml } from '../../services/utils';
import { SocialMedia } from '../social-media';
import { OnDeckJobModal } from './on-deck-job-modal/OnDeckJobModalContainer.jsx';

class OnDeckTable extends Component {
	confirmDelete = (contactId) => {
		try {
			const { onDeleteContact } = this.props;
			const input = {
				input: {
					id: contactId,
				},
			};
			onDeleteContact(input);
			message.success('Contact Deleted');
		} catch (error) {
			console.error(error);
		}
	};

	referralStatus = (fullContactStatus) => {
		try {
			switch (true) {
				case fullContactStatus === null: {
					return 'Pending';
				}

				case fullContactStatus.details !== undefined: {
					return 'Ready';
				}

				case fullContactStatus.message === 'Profile not found': {
					return 'Unavailable';
				}

				default: {
					return 'Unavailable';
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	renderReferredBy = (referredBy) => {
		if (this.props.displayAs === 'admin') {
			return (
				<Link className="table-link" to={`/employees/${referredBy?.id}`}>
					{referredBy?.firstName} {referredBy?.lastName}
				</Link>
			);
		}

		return (
			<span>
				{referredBy?.firstName} {referredBy?.lastName}
			</span>
		);
	};

	sortByAlph = (a, b) => {
		if (typeof a === 'string') {
			a = a.toLowerCase();
		}

		if (typeof b === 'string') {
			b = b.toLowerCase();
		}

		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	};

	render() {
		const {
			allMultiLingualData,
			currentUser,
			contacts,
			onDeckStatusTitle,
			languageCode,
		} = this.props;
		const totalCount = contacts?.length ?? 0;
		const managerReferrals = currentUser.company
			?.disableManagerPermissionsByType
			? JSON.parse(currentUser.company.disableManagerPermissionsByType)
					.managerReferrals
			: 'edit';
		const isManagerPermissionDisabled = managerReferrals !== 'edit';
		const columns = [
			{
				key: 'avatar',
				width: '6%',
				render: (record) =>
					record.avatar !== null && record.avatar !== undefined ? (
						<div className="custom-image-wrap">
							<Avatar
								style={{ borderRadius: 0 }}
								src={record.avatar}
								className="custom-img"
								shape="square"
							/>
						</div>
					) : (
						<div className="custom-image-wrap">
							{record.firstName === null ? '' : record.firstName[0]}
							{record.lastName === null ? '' : record.lastName[0]}
						</div>
					),
			},
			{
				title: ml('Candidate', currentUser, allMultiLingualData),
				key: 'name',
				width: '20%',
				render: (record) => (
					<Link className="table-link" to={`/mycontacts/${record.id}`}>
						{record.firstName} {record.lastName}
					</Link>
				),
				showSorterTooltip: false,
				sorter: (a, b) =>
					this.sortByAlph(
						(a.firstName ? a.firstName : 'zzz') +
							(a.lastName ? a.lastName : 'zzz'),
						(b.firstName ? b.firstName : 'zzz') +
							(b.lastName ? b.lastName : 'zzz')
					),
			},
			{
				title: ml('Info', currentUser, allMultiLingualData),
				width: '14%',
				render(record) {
					return (
						<div className="referral-lead-table-social">
							<SocialMedia
								email={record.emailAddress}
								socialMedia={record.socialMediaAccounts}
								contactResume={record.contactResume}
								message={record.onDeckNote}
								phone={record.phoneNumber}
							/>
						</div>
					);
				},
			},
			{
				title: ml('Email / Phone Number', currentUser, allMultiLingualData),
				width: '20%',
				render: (record) => (
					<>
						{record.emailAddress
							? record.emailAddress
							: record.phoneNumber
								? record.phoneNumber
								: ''}
					</>
				),
				key: 'email',
				showSorterTooltip: false,
				sorter: (a, b) => this.sortByAlph(a.emailAddress, b.emailAddress),
			},
			{
				title: ml('Referred By', currentUser, allMultiLingualData),
				dataIndex: 'user',
				width: '18%',
				key: 'fullContactData',
				showSorterTooltip: false,
				sorter: (a, b) =>
					this.sortByAlph(
						get(a, 'user.firstName', '') + get(a, 'user.lastName', ''),
						get(b, 'user.firstName', '') + get(b, 'user.lastName', '')
					),
				render: (referredBy) =>
					referredBy === null ? null : this.renderReferredBy(referredBy),
			},
			{
				title: ml('Date Referred', currentUser, allMultiLingualData),
				key: 'dateAdded',
				width: '18%',
				showSorterTooltip: false,
				sorter: (a, b) => dayjs(a.onDeckDate) - dayjs(b.onDeckDate),
				render: (record) =>
					formatDate(
						get(record, 'onDeckDate'),
						languageCode,
						this.props.currentUser.dateFormat
					),
				defaultSortOrder: 'descend',
			},
		];
		// Only push the columns with editable functionality if the manager permissions are "edit" or user is not manager
		if (
			!isManagerPermissionDisabled ||
			currentUser.role !== USER_ROLES.MANAGER
		) {
			columns.push(
				{
					title:
						onDeckStatusTitle === 'Off Deck '
							? ml('Remove', currentUser, allMultiLingualData)
							: ml('Add', currentUser, allMultiLingualData),
					key: 'move',
					width: 80,
					render: (record, data) => (
						<Button
							shape="circle"
							type="primary"
							style={{
								backgroundColor: 'var(--silver-chalice)',
								borderColor: 'var(--silver-chalice)',
							}}
							icon={
								onDeckStatusTitle === 'Off Deck ' ? (
									<MinusOutlined />
								) : (
									<ArrowLeftOutlined />
								)
							}
							size="large"
							onClick={() => this.props.changeDeck(data)}
						/>
					),
				},
				{
					title: ml('Submit to Job', currentUser, allMultiLingualData),
					key: 'action',
					width: 80,
					render: (record, data) => (
						<OnDeckJobModal
							jobs={this.props.jobs}
							jobData={this.props.job}
							data={data}
							{...this.props}
						/>
					),
				}
			);
		}

		return (
			<Table
				pagination={this.props.pagination}
				loading={this.props.loading}
				dataSource={contacts}
				columns={columns}
				rowKey={(record) => record.id}
				scroll={{ x: true }}
				onChange={this.props.onChange}
			/>
		);
	}
}

export default OnDeckTable;
