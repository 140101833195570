import get from 'lodash/get';
import dayjs from 'dayjs';
import { ml } from 'src/_shared/services/utils';

function OnDeckComments(props) {
	const { referrals, currentUser, allMultiLingualData } = props;
	const referral = referrals;

	return (
		<div>
			{referral.contact ? (
				<>
					<h4 className="content-right-heading">
						{ml('Referred By', currentUser, allMultiLingualData)}
					</h4>
					<p>
						<span
							style={{
								fontWeight: 700,
								fontSize: 16,
								lineHeight: '24px',
								color: 'var(--lochmara)',
							}}
						>
							{get(referral, 'contact.user.firstName')}{' '}
							{get(referral, 'contact.user.lastName')}
							{' - ' + get(referral, 'contact.user.title', '')}
						</span>
						{' ' + ml('on', currentUser, allMultiLingualData) + ' '}
						{dayjs(get(referral, 'onDeckDate')).format('M/D/YYYY')}
					</p>
				</>
			) : null}
			{referral.contact.onDeckNote ? (
				<div key={0}>
					<br />
					<span style={{ paddingLeft: '25px', fontStyle: 'italic' }}>
						" {get(referral, 'contact.onDeckNote')}"
					</span>
				</div>
			) : null}
		</div>
	);
}

export default OnDeckComments;
