import { Table, Typography } from "antd"
import formatDateInTable from "../../../rewardsAndRecognitionHelper/CommonDate.js";
import './style.scss'
import NoDataState from "../../../RnRCommonComponent/noDataState/NoDataState.jsx";
import { addCommas } from "../../../rewardsAndRecognitionHelper/utils.js";
import uuid from 'uuid/v4';

const { Text } = Typography;

const AllActivityPresentation = ({ allActivity }) => {

    const columns = [
        {
            title: 'RECIPIENT',
            dataIndex: 'recipient',
            key: 'recipient',
        },
        {
            title: 'BONUS',
            dataIndex: 'bonus',
            key: 'bonus',
            sorter: (a, b) => a.bonus - b.bonus,
            render: (bonus) => {
                const bonusText = Boolean(bonus) ? "$" + addCommas(bonus) : "None"
                return <Text type={bonusText === "None" ? "" : "success"}>{bonusText}</Text>
            },
        },
        {
            title: 'POINTS',
            dataIndex: 'points',
            key: 'points',
            sorter: (a, b) => a.points - b.points,
            render: (points) => {
                const pointsText = Boolean(points) ? addCommas(points) : "None"
                return <Text type={pointsText === "None" ? "" : "success"}>{pointsText}</Text>
            },
        },
        {
            title: 'PHYSICAL REWARDS',
            dataIndex: 'physicalRewards',
            key: 'physicalRewards',
            render: (text) => {
                return (
                    <Text type={text === "None" || !Boolean(text) ? "" : text === "Confirmed" ? "success" : text === "Pending" ? "warning" : "danger"} >
                        {Boolean(text) ? text : "None"}
                    </Text>
                )
            },
        },
        {
            title: 'SENDER',
            dataIndex: 'sender',
            key: 'sender',

        },
        {
            title: 'BADGE',
            dataIndex: 'reward',
            key: 'reward',
        },
        {
            title: 'CREATED',
            dataIndex: 'created',
            key: 'created',
            sorter: (a, b) => new Date(a.created) - new Date(b.created),
            render: (created) => {
                return <span>{formatDateInTable(created)}</span>
            }
        },
    ];

    return (
        <div className="nomination-rewards-table table-card">
            <Table
                rowKey={(record) => uuid() + record.id}
                dataSource={allActivity}
                columns={columns}
                rowHoverable={false}
                rowSelectedHoverBg={"white"}
                pagination={{ pageSize: 25, showSizeChanger: false }}
                locale={{ emptyText: <NoDataState title={"No activities found."} /> }}
            />
        </div>
    )
}

export default AllActivityPresentation;