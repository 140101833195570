import gql from 'graphql-tag';

export const GetJobByIdHiringManager = gql`
	query GetJob($id: ID!) {
		getJob(id: $id) {
			id
			hiringManagerId
			hiringManager {
				id
				emailAddress
				firstName
				lastName
			}
		}
	}
`;
