import gql from 'graphql-tag';

export const UpdateUserAside = gql`
	mutation UpdateUser($input: UpdateUserInput!) {
		updateUser(input: $input) {
			admin
			companyId
			company {
					id
					admins {
							id
							cognitoId
							companyId
							emailAddress
							firstName
							lastName
							title
							departmentId
							department {
									id
									name
							}
							lastLogin
							lastNotificationCheck
							incentiveEligible
							totalReferrals
							active
							role
							userGroupId
							dateCreated
					}
					managers {
							id
							cognitoId
							companyId
							emailAddress
							firstName
							lastName
							title
							departmentId
							department {
									id
									name
							}
							lastLogin
							lastNotificationCheck
							incentiveEligible
							totalReferrals
							active
							role
							userGroupId
							dateCreated
					}
					name
					defaultBonusAmount
					contactIncentiveBonus
					websiteUrl
					dashboardReferralPolicyText
					referralBonusWaitingPeriod
					referralLeadsAllowDuplicateContacts
					bonusEarnedNote
					allowSelfReferrals
					allowInternalMobility
					internalMobilityImage {
							bucket
							region
							key
					}
					dateCreated
					brandColor
					accountType
					logo {
							bucket
							region
							key
					}
					employeeDashboard
					employeeDashboardRows
					ignoreSubcompany
					enableGenericReferralEmail
					enableLead
					hideSubcompanyOnSignup
					hideSubcompanyOnJobs
					sendReferralColor
					applyInternallyColor
					generalReferralColor
					referralLeadColor
					disableSmartReferrals
					disableSAMLLogin
					disableClaimYourAccountLogin
					confirmCompliance
					platformConfig
					ssoGoogleDomain
					confirmContactEmails
					whiteLabel
					showPoweredByErin
					stages
					API {
							googleClientId
							microsoftClientId
					}
					theme
					symbol {
							bucket
							region
							key
					}
					background {
							bucket
							region
							key
					}
					errorImage {
							bucket
							region
							key
					}
					socialImage {
							bucket
							region
							key
					}
					subCompanies {
							companyId
							id
							name
							keywords
					}
					departments {
							active
							companyId
							id
							name
							keywords
					}
					userGroups {
							companyId
							id
							name
							keywords
					}
					enableGeneralReferrals
					referralStatus
					referralCustomStatuses
					requireBonusChangeReason
					disableManagerPermissions
					disableManagerPermissionsByType
					hideCopyReferral
					hideJobsPage
					disableShareLink
					disableNewReferralSMSNotification
					helpUrl
					privacyUrl
					hideRecognitionSocialShare
					hideRecognitionLeaderboard
					recognitionCustomTitle
					requireBadgeSelection
					termsUrl
					linkUrl
					linkName
					enableProspectCreation
					allowSelfReferralsInternalLink
					sendAdminNotificationsOnReferral
					sendAdminNotificationsOnInternalCandidatesFound
					enableAcceptedReferralReminder
					disableExtendedUserJobsView
					enableExtendedNetwork
					enableCareerProfile
					enableJobMatching
					enableJobNotifications
					enableRecognition
					gifIds
					externalUserSignUp
					hideLoginForm
					hideMessageRecruiter
					hidePaymentInfoJobCard
					disableReferrals
					hideBonus
					includeAdminInRanking
					disableSite
					hideInterestedForGroup
					senderEmailAddress
					whiteLabelServiceName
					host
					hideShareLinkForDepartment
					hideShareLinkNoPublicUrl
					hideDateCreatedJobDetails
					hideBonusFilterOnBrowseJobs
					jobFilterSettings
					jobNotificationSetting
					jobNotificationFrequency
					subCompanyLabel
					labelDepartment
					labelSocialShare
					sftpFolderName
					defaultLocation
					labelEmployeeID
					giftCardStoreAPIKeys {
							apiKey
							region
					}
					giftCardStoreBalance
					giftCardStorePendingBalance
					enableCustomPage
					enableCustomPage2
					enableCustomPage3
					userEditSettings
					userSignupSettings
					publicLeaderboard
					hideReferralsRanking
					customPageTitle
					customPageTitle2
					customPageTitle3
					pointsSettings
					popupTitle
					popupTitleContent
					partnerHostId
					hideWhatsApp
					hideRecruiterInfo
					hideRecruiterInfoJobDetails
					showRecruiterInfoJobCard
					resetPopup
					defaultToSubcompany
					internalMobilityCustomTitle
					appStoreUrls {
							ios
							android
					}
					bonusReportFrequency
					employeeReportFrequency
					referralsReportFrequency
					referralsReportDateRange
					countryCode
					metabaseReports {
							id
							label
							reportId
							reportType
					}
			}
			id
			role
		}
	}
`;
