import { Button, Card, Dropdown, Menu, Modal, Popconfirm, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { MyReferralsBonusDetailsModal } from '..';
import { USER_ROLES } from '../../constants';
import { ReferralHiredModal } from '../../referral-hired-modal';
import {
	calculateReferralBonus,
	formatDate,
	mapReferralStatus,
	conditionalStatusStyle,
	ml,
} from '../../services/utils';
import { ProgressIndicator } from '../referral-progress-indicator';
import { SocialMedia } from '../social-media';
import OwnershipPeriodTooltip from './OwnershipPeriodTooltip.jsx';

export class ReferralCardComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hiredModalVisible: false,
			dropdownVisibility: false,
			referral: get(props, 'referral'),
			bonusArray: get(props, 'referral.bonuses', ''),
			visible: false,
			translatedTitle: '',
		};
	}

	async componentDidMount() {
		this.setState({ referral: get(this.props, 'referral') });
		const { referral } = this.props;
		const jobTitle = get(referral, 'job.title');
		const newTitle = jobTitle;

		this.setState({
			translatedTitle: newTitle,
		});
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.currentUser.languageCode !== this.props.currentUser.languageCode
		) {
			const { referral } = this.props;
			const jobTitle = get(referral, 'job.title');
			const newTitle = jobTitle;
			this.setState({
				translatedTitle: newTitle,
			});
		}
	}

	handleCancel = () => {
		this.setState({
			visible: false,
		});
	};

	handleHired = () => {
		this.setState({ status: 'hired', bonusStatus: 'pending' });
	};

	handleVisibleChange = (flag) => {
		this.setState({ dropdownVisibility: flag });
	};

	handleUpdateBonusStatus = (status) => {
		const { referral, currentJob } = this.props;
		this.setState({ bonusStatus: status });
		this.props.onUpdateReferral({
			input: {
				id: referral.id,
				contactId: referral.contactId,
				userId: referral.userId,
				jobId: currentJob.id,
				bonusStatus: status,
			},
		});
	};

	handleUpdateStatus = (status) => {
		const { referral, onUpdateReferral } = this.props;

		if (status === 'hired') {
			this.setState({ hiredModalVisible: true });
		} else {
			this.setState({ bonusStatus: 'ineligible' });
			let input = {};
			const customStatuses = [
				'stage1',
				'stage2',
				'stage3',
				'stage4',
				'stage5',
				'stage6',
			];
			if (customStatuses.includes(status)) {
				const customStages = get(this.props.currentUser, 'company.stages');
				if (customStages) {
					const stages = JSON.parse(customStages);
					const stageObject = stages.find((value) => value[status]);
					const customStage = stageObject[status];
					input = {
						id: get(referral, 'id'),
						contactId: get(referral, 'contactId'),
						userId: get(referral, 'user.id'),
						jobId: get(referral, 'jobId'),
						status: 'interviewing',
						bonusStatus: 'ineligible',
						customStatus: customStage,
					};
				}
			} else {
				input = {
					id: get(referral, 'id'),
					contactId: get(referral, 'contactId'),
					userId: get(referral, 'user.id'),
					jobId: get(referral, 'jobId'),
					status,
					bonusStatus: 'ineligible',
				};
				if (status === 'interviewing') {
					input.customStatus = get(
						this.props.currentUser,
						'company.referralStatus',
						'Interviewing'
					);
				} else {
					// Explicitly set customStatus null or else the
					// referral retains the old customStatus
					input.customStatus = null;
				}
			}

			onUpdateReferral({ input });
		}
	};

	hideHiredModal = () => {
		this.setState({ hiredModalVisible: false });
	};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	render() {
		const {
			allMultiLingualData,
			currentUser,
			referral = {},
			allReferrals,
			currentJob,
			userRole,
			currentCurrencyRate,
			currentCurrencySymbol,
			ownershipSettings,
			cardStyle = {},
		} = this.props;
		const contactIncentiveBonus = get(
			this.props,
			'currentJob.company.contactIncentiveBonus'
		);
		const referralBonus = get(this.props, 'currentJob.referralBonus');
		const { translatedTitle } = this.state;
		const languageCode = get(this.props, 'currentUser.languageCode', 'US');
		const dateFormat = get(this.props, 'currentUser.dateFormat');
		const currentTieredBonus = get(this.props, 'currentJob.tieredBonus');
		const status = get(referral, 'status');
		const referralStatusText = get(referral, 'customStatus');
		const bonusStatus = get(referral, 'bonusStatus');
		const contact = get(referral, 'contact');
		const referralDate = formatDate(
			get(referral, 'referralDate'),
			languageCode,
			dateFormat
		);
		const user = get(referral, 'user');
		const hireDate = formatDate(get(referral, 'hireDate'), languageCode);
		referral.job = currentJob;
		let referralBonusDetails = referralBonus;
		if (typeof referralBonusDetails === 'string') {
			referralBonusDetails = JSON.parse(referralBonusDetails);
		}

		const amount = referralBonusDetails ? referralBonusDetails.amount : 0;
		let currencyCode = get(this.props, 'currentUser.userGroup.currency');
		if (currencyCode === null) currencyCode = 'USD';
		const symbol = get(this.props, 'currentCurrencySymbol', '$');
		const confirmMessage =
			status === 'hired'
				? 'Confirm Updates? Associated bonuses will be deleted.'
				: 'Confirm Updates?';
		let isManagerPermissionDisabled = get(
			this.props.currentUser,
			'company.disableManagerPermissions',
			false
		);
		// Preserves backwards compatibility and future compatibility with disableManagerPermissions
		const managerReferrals = this.props.currentUser.company
			?.disableManagerPermissionsByType
			? JSON.parse(
					this.props.currentUser.company.disableManagerPermissionsByType
				).managerReferrals
			: isManagerPermissionDisabled
				? 'readOnly'
				: 'edit';
		isManagerPermissionDisabled = managerReferrals !== 'edit';

		const capitalizeIt = (string = '') => {
			return string.charAt(0).toUpperCase() + string.slice(1);
		};

		const role = get(this.props.currentUser, 'role');
		const isStatusDropdownDisabled = Boolean(
			isManagerPermissionDisabled && role === USER_ROLES.MANAGER
		);

		let companyReferralStatus = get(
			this.props.currentUser,
			'company.referralStatus',
			'Interviewing'
		);
		if (companyReferralStatus === null || companyReferralStatus === '') {
			companyReferralStatus = 'Interviewing';
		}

		companyReferralStatus = capitalizeIt(companyReferralStatus);

		let REFERRAL_STATUS = {
			referred: 'Referred',
			accepted: 'Accepted',
			interviewing: 'Interviewing',
		};
		const customStages = get(this.props.currentUser, 'company.stages');
		if (customStages) {
			const stages = JSON.parse(customStages);
			if (stages.length > 0) {
				REFERRAL_STATUS = Object.assign(REFERRAL_STATUS, ...stages);
			}
		}

		const remainingStatuses = {
			hired: 'Hired',
			ineligible: 'Ineligible',
			notHired: 'Not Hired',
			declined: 'Declined',
			transferred: 'Transferred',
			inactive: 'Inactive',
			noresponse: 'No Response',
		};
		REFERRAL_STATUS = { ...REFERRAL_STATUS, ...remainingStatuses };

		if (ownershipSettings) {
			ownershipSettings.expiryDate = formatDate(
				get(referral, 'ownershipEndDate'),
				languageCode
			);
		}

		const disabled = Boolean(
			isStatusDropdownDisabled ||
				get(referral, 'type') === 'gdprReferralCreated'
		);
		return (
			<>
				<Card className="rd-card" style={cardStyle}>
					<div className="rd-card-top" style={{flex: '1', flexWrap: 'nowrap'}}>
						{get(this.props, 'referral.type') === 'gdprReferralCreated' ? (
							<div className="rdc-left">
								<h4 className="rdc-name rdc-acceptance">Pending Acceptance</h4>
							</div>
						) : contact ? (
							<div className="rdc-left">
								<h4 className="rdc-name">
									<Link
										to={{
											pathname: `/referrals/${referral.id}`,
											state: {
												filteredData: get(this.props, 'allReferrals'),
											},
										}}
									>
										{contact.firstName && contact.firstName}{' '}
										{contact.lastName && contact.lastName}
									</Link>
								</h4>

								<div className="rdc-social-icons">
									<SocialMedia
										email={get(contact, 'emailAddress', '')}
										socialMedia={get(contact, 'socialMediaAccounts')}
										contactResume={get(referral, 'contactResume')}
										message={get(referral, 'message')}
										phone={get(contact, 'phoneNumber')}
									/>
								</div>

								{get(referralBonus, 'amount') &&
								!currentTieredBonus &&
								get(referral, 'status') === 'hired' ? (
									<p className="rd-card-bonus">
										{get(
											referral,
											'referralData.referralType',
											get(referral, 'referralType', null)
										) === 'self' ? (
											<span className="text-orange" />
										) : (
											<>
												{`${symbol}${calculateReferralBonus(
													contactIncentiveBonus,
													amount,
													get(referral, 'user.incentiveEligible'),
													currentTieredBonus,
													'employee',
													get(
														referral,
														'referralData.user.userGroupId',
														get(referral, 'referralData.userGroupId', null)
													)
												)}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')}
											</>
										)}
									</p>
								) : null}
							</div>
						) : null}
						{get(this.props, 'referral.referralType') === 'self' ? (
							<div className="rdc-right" style={{textAlign: 'right'}}>
								<span className="rdc-referred">
									<span className="rdc-referred-self">Self Referred</span>
								</span>
								<span className="rdc-referred-date">{referralDate}</span>
							</div>
						) : (
							<div className="rdc-right">
								<span className="rdc-referred">
									{ml(
										`${mapReferralStatus('referred', currentUser.company)} By`,
										currentUser,
										allMultiLingualData
									)}
									<Link
										to={
											userRole === 'admin'
												? `/employees/${get(user, 'id', '')}`
												: '#'
										}
									>
										{get(user, 'firstName')} {get(user, 'lastName')}
									</Link>
								</span>
								<span className="rdc-referred-date">
									<OwnershipPeriodTooltip
										currentUser={currentUser}
										ownershipSettings={ownershipSettings}
									/>
									<Tooltip
										placement="top"
										title={`${ml(
											`${mapReferralStatus(
												'referred',
												currentUser.company
											)} on`,
											currentUser,
											allMultiLingualData
										)} ${referralDate} by ${get(referral, 'referralType')}`}
									>
										{get(referral, 'referralType') === 'email' && (
											<i
												className="icon-envelope-outline"
												style={{ fontSize: 16 }}
											/>
										)}
										{get(referral, 'referralType') === 'text' && (
											<i
												className="icon-message-outline"
												style={{ fontSize: 16 }}
											/>
										)}
										{get(referral, 'referralType') === '' ||
											(!get(referral, 'referralType') && (
												<i
													className="icon-telephone-outline"
													style={{ fontSize: 16 }}
												/>
											))}
										{get(referral, 'referralType') &&
											get(referral, 'referralType').includes('share') && (
												<i className="icon-share" />
											)}

										<span>{referralDate}</span>
									</Tooltip>
								</span>

								{hireDate !== null && status === 'hired' ? (
									<span className="rdc-referred">
										{ml(
											`${mapReferralStatus('hired', currentUser.company)} on`,
											currentUser,
											allMultiLingualData
										)}
										<span className="text-gray">{hireDate}</span>
									</span>
								) : null}
								{referral?.adminNote ? (
									<div className="rdc-action-btn">
										<span>
											<Tooltip
												placement="top"
												title={adminNote}
												style={{ fontWeight: 1000 }}
											>
												<img alt="erin-note" src={note} style={{ width: 20 }} />
											</Tooltip>
										</span>
									</div>
								) : null}
							</div>
						)}
					</div>
					<div className="rdc-status-dropdown" style={{flex: '1'}}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<span className="status-dropdown-label">Status:</span>
							<Dropdown
								key={referral.id}
								trigger={['click']}
								disabled={disabled}
								open={this.state.dropdownVisibility}
								onOpenChange={(flag) => this.handleVisibleChange(flag)}
								className="status-dropdown-btn"
								menu={{
									items: Object.keys(REFERRAL_STATUS).map((key) => ({
										key,
										label: (
											key === 'hired' ? (
												<span onClick={() => this.handleUpdateStatus(key)}>
													{mapReferralStatus(key, currentUser.company)}
												</span>
											) : (
												<Popconfirm
													title={confirmMessage}
													placement="top"
													okText={ml('Yes', currentUser, allMultiLingualData)}
													cancelText={ml('No', currentUser, allMultiLingualData)}
													onConfirm={() => {
														this.handleVisibleChange(false);
														this.handleUpdateStatus(key);
													}}
												>
													{mapReferralStatus(key, currentUser.company)}
												</Popconfirm>
											)
										),
										style: conditionalStatusStyle(key),
									}))
								}}
							>
								<Button onClick={e => e.stopPropagation()}>
									<span style={conditionalStatusStyle(referral.status)}>
										{mapReferralStatus(referral, currentUser.company)}
									</span>
									<DownOutlined />
								</Button>
							</Dropdown>
						</div>
						{status === 'hired' &&
						bonusStatus &&
						this.state.bonusArray.length > 0 ? (
							<Button type="primary" onClick={this.showModal}>
								{ml('View Bonus Details', currentUser, allMultiLingualData)}
							</Button>
						) : (
							<></>
						)}
					</div>
					<div className="my-progressbar-wrap" style={{flex: 1}}>
						<ProgressIndicator
							type="referral"
							points={5}
							status={status}
							currentUser={get(this.props, 'currentUser')}
							referral={referral}
							onClick={this.handleUpdateStatus}
						/>
					</div>
					<ReferralHiredModal
						referral={referral}
						job={currentJob}
						visible={this.state.hiredModalVisible}
						handleCancel={this.hideHiredModal}
						handleHired={this.handleHired}
						allReferrals={allReferrals}
						onUpdateJob={this.props.onUpdateJob}
						onUpdateReferral={this.props.onUpdateReferral}
					/>
				</Card>
				<Modal
					title="Bonus Details"
					open={this.state.visible}
					footer={null}
					onCancel={this.handleCancel}
				>
					<MyReferralsBonusDetailsModal
						allMultiLingualData={allMultiLingualData}
						currentUser={currentUser}
						currentTieredBonus={currentTieredBonus}
						status={status}
						userGroup={get(referral, 'user.userGroupId')}
						currentCurrencyRate={currentCurrencyRate}
						currentCurrencySymbol={currentCurrencySymbol}
						data={referral}
						handleCancel={this.handleCancel}
						translatedTitle={translatedTitle}
					/>
				</Modal>
			</>
		);
	}
}
