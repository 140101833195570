export const queryContactsByCompanyIdOnDeckStatusIndex = `query queryContactsByCompanyIdOnDeckStatusIndex(
	$companyId: ID!
	$onDeckStatus: String!
	$first: Int
	$after: String
  ) {
	queryContactsByCompanyIdOnDeckStatusIndex(
	  companyId: $companyId
	  onDeckStatus: $onDeckStatus
	  first: $first
	  after: $after
	) {
	  items {
		id
		companyId
		firstName
		lastName
		emailAddress
		phoneNumber
		userId 
		user {
		  id
		  firstName
		  lastName
		  title
		  userGroupId
		  userGroup {
			id
			measurement
			name
			currency
		  }
		  currency
		  languageCode
		}
		referrals {
		  id
		  contactId
		}
		importMethod
		fullContactData
		dateCreated
		onDeckDate
		onDeckStatus
		onDeckNote
		contactResume {
		  bucket
		  region
		  key
		}
		questionsData
	  }
	  nextToken
	}
  }
  `;
