import { Button } from 'antd';
import get from 'lodash/get';
import queryString from 'query-string';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import {
	downloadFromS3Signed,
	getEnvironment,
	graphql,
} from 'src/_shared/services/utils.js';
import LoginBackground from '../_shared/assets/auth-bg-full.jpg';
import ErinWhiteLogo from '../_shared/assets/erinwhite.png';
import PoweredBy from '../_shared/assets/poweredbyERIN.png';
import rightArrow from '../_shared/assets/right-arrow.svg';
import { RouterContext } from '../_shared/contexts';
import ForgottenPasswordModal from './fogotten-password-modal/ForgottenPasswordModalComponent.jsx';
import GetOrganizationModal from './get-organization-modal/GetOrganizationModalComponent.jsx';
import LoginCard from './login-items/LoginCardComponent.jsx';
import dompurify from 'dompurify';

class LoginComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			forgotPasswordVisible: false,
			orgModalVisible: false,
			visible: false,
			userEmail: null,
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			company: get(props, 'currentUser.company'),
			whiteLabel: get(props, 'whiteLabel'),
			backgroundImage: null,
			token: queryString.parse(this.props.location.hash),
			code: queryString.parse(this.props.location.search),
			fetchedCompany: false,
			hideLoginScreenShading: false,
			backgroundImageURL: '',
			sanitizedHtmlContent: '',
			enableLandingPageCustomizations: false,
			loginEmailLabel: null
		};
	}

	async componentDidMount() {
		let { host } = window.location;
		host = host.replace('www.', '');
		let company = null;
		let hideLoginScreenShading = false;

		try {
			// START Getting company data by host using lambda
			let fetchedCompany = false;
			try {
				company = await graphql({ input: { host }, query: 'getCompanyByHost' });
				company ||= [];
				if (company && company.whiteLabelSettings) {
					const whiteLabelSettings = JSON.parse(company.whiteLabelSettings);
					this.setState({
						enableLandingPageCustomizations:
							whiteLabelSettings.enableLandingPageCustomizations,
						loginEmailLabel: whiteLabelSettings.loginEmailLabel
					});
					const sanitizedHtmlContent = dompurify.sanitize(
						whiteLabelSettings.landingPageHtmlContent,
						{
							ALLOWED_TAGS: [
								'iframe',
								'p',
								'h1',
								'h2',
								'h3',
								'h4',
								'a',
								'span',
								'div',
								'br',
								'strong',
								'em',
								'img',
							],
							ALLOWED_ATTR: [
								'src',
								'width',
								'height',
								'frameborder',
								'allow',
								'allowfullscreen',
								'title',
								'style',
								'target',
								'href',
								'class',
							],
						}
					);
					hideLoginScreenShading =
						whiteLabelSettings &&
						whiteLabelSettings.hideLoginScreenShading !== null
							? whiteLabelSettings.hideLoginScreenShading
							: false;

					this.setState({
						sanitizedHtmlContent,
					});
				}

				fetchedCompany = true;
			} catch (error) {
				console.log('ERROR =', error);
			}

			// END Getting company dta by host using lambda

			const saml = await graphql({
				input: { companyId: company?.id },
				query: 'getSAMLAuthByCompanyId',
			});
			const theme = JSON.parse(get(company, 'theme', '{}'));
			const labelEmployeeID = get(company, 'labelEmployeeID', 'Employee ID');
			const lblDepartment = get(company, 'labelDepartment', 'Department');
			const whiteLabel = get(company, 'whiteLabel', false);
			const userSignupSettings = get(company, 'userSignupSettings');
			this.setState({
				company,
				hideLoginScreenShading,
				whiteLabel,
				fetchedCompany,
				theme,
				host,
				saml,
				labelEmployeeID,
				lblDepartment,
				userSignupSettings,
			});

			// Set background image for whitelabel comapnies
			const key = get(company, 'background.key', false);
			if (key) {
				const url = await downloadFromS3Signed(
					key,
					company?.background?.bucket
				);
				this.setState({ backgroundImageURL: url }, () =>
					this.setBackgroundImage(company)
				);
			} else {
				this.setBackgroundImage(company);
			}
		} catch (error) {
			console.log(error);
		}

		if (get(this.props, 'location.state.currentUser')) {
			this.props.setCurrentUser(get(this.props, 'location.state.currentUser'));
		}

		if (this.props.location?.state?.redirectURL) {
			localStorage.setItem(
				'redirectURL',
				this.props.location.state.redirectURL
			);
		}

		const accountClaimError = get(
			this.props,
			'location.state.accountClaimError'
		);
		const accountClaimSuccess = get(
			this.props,
			'location.state.accountClaimSuccess'
		);
		const externalUserSuccess = get(
			this.props,
			'location.state.externalUserSuccess'
		);
		const warningMessage = get(this.props, 'location.state.warningMessage');
		const successMessage = get(this.props, 'location.state.successMessage');
		if (accountClaimError) this.setState({ accountClaimError });
		if (accountClaimSuccess) this.setState({ accountClaimSuccess });
		if (externalUserSuccess) this.setState({ externalUserSuccess });
		if (warningMessage) this.setState({ warningMessage });
		if (successMessage) this.setState({ successMessage });

		const companyAdminSuccess = get(
			this.props,
			'location.state.companyAdminSuccess'
		);
		if (companyAdminSuccess) this.setState({ companyAdminSuccess });
		if (get(this.props, 'routeData.openClaimYourAccount')) this.openModal();
		const logo = get(company, 'logo.key', false);
		const logoSource = await downloadFromS3Signed(logo, 'erin-images');
		this.setState({ logoSrc: logoSource });
	}

	getMobileOperatingSystem = () => {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;
		if (/android/i.test(userAgent) && !window.MSStream) {
			const width = window.innerWidth || document.body.clientWidth;
			return width > 1000 ? 'Tablet' : 'Android';
		}

		// IOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			const width = window.innerWidth || document.body.clientWidth;
			return width > 1000 ? 'Tablet' : 'iOS';
		}
	};

	setBackgroundImage = (company) => {
		const { backgroundImageURL } = this.state;
		const whiteLabel = get(company, 'whiteLabel');
		const { host } = window.location;
		let backgroundImage = null;
		if (host !== 'app.erinapp.com' && whiteLabel && backgroundImageURL) {
			const key = get(company, 'background.key', false);
			backgroundImage = `url(${backgroundImageURL})`;
			this.setState({ backgroundImage });
		} else if (company) {
			backgroundImage = `url(${LoginBackground})`;
			this.setState({ backgroundImage });
		}
	};

	setPageOne = () => this.setState({ page: 1 });

	setPageTwo = () => this.setState({ page: 2 });
	setUserEmail = (value) => this.setState({ userEmail: value });
	closeModal = () =>
		this.setState({
			visible: false,
			orgModalVisible: false,
			forgotPasswordVisible: false,
		});

	openCompanyAccountModal = () => this.setState({ orgModalVisible: true });
	openForgotPasswordModal = () =>
		this.setState({ forgotPasswordVisible: true });

	openModal = () => this.setState({ visible: true });

	parseJwt = () => {
		const token = this.state.token.id_token;
		if (!token) {
			return;
		}

		const base64Url = token.split('.')[1];
		const base64 = base64Url.replaceAll('-', '+').replaceAll('_', '/');
		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);

		return JSON.parse(jsonPayload);
	};

	render() {
		const {
			accountClaimError,
			accountClaimSuccess,
			backgroundImage,
			company,
			error,
			theme,
			page,
			saml,
			visible,
			userEmail,
			forgotPasswordVisible,
			companyAdminSuccess,
			warningMessage,
			successMessage,
			externalUserSuccess,
			orgModalVisible,
			labelEmployeeID,
			loginEmailLabel,
			lblDepartment,
			userSignupSettings,
		} = this.state;
		const {
			setCurrentUser,
			client,
			setFailedLoginAttempts,
			failedLoginAttempts,
		} = this.props;

		const whiteLabel = get(this.state, 'company.whiteLabel');
		const loginButtonText = get(saml, 'loginButtonText')
			? get(saml, 'loginButtonText')
			: 'Click Here To Login';
		const hideLoginForm = get(company, 'hideLoginForm');
		const disableSAMLLogin = get(company, 'disableSAMLLogin');
		if (!company || !this.state.backgroundImage)
			return <Spinner forceDefault />;
		return (
			<div data-testid="login">
				<div className="auth" style={backgroundImage && { backgroundImage }}>
					<div className="auth-left">
						<div className="auth-logo-wrap">
							{company && whiteLabel ? (
								<div>
									<div
										className="auth-logo whitelabel-logo"
										style={{ marginLeft: 'auto', marginRight: 'auto' }}
									>
										<img src={this.state.logoSrc} alt="logo" />
									</div>
									{this.state.enableLandingPageCustomizations && (
										<div
											dangerouslySetInnerHTML={{
												__html: this.state.sanitizedHtmlContent,
											}}
											style={{
												width: '500px',
												height: '400px',
												overflow: 'auto',
											}}
										/>
									)}
								</div>
							) : (
								company &&
								getEnvironment() !== 'other' && (
									<div className="auth-logo">
										<img src={ErinWhiteLogo} alt="Erin Logo" />
									</div>
								)
							)}
						</div>
						<div className="auth-bottom-links">
							{get(company, 'whiteLabel') && (
								<ul className="auth-links-info">
									{/* If whiteLabel company set ios link in settings, show it here */}
									{get(company, 'appStoreUrls.ios') === 'comingSoon' ? (
										<li>IOS Coming Soon</li>
									) : (
										get(company, 'appStoreUrls.ios') && (
											<li>
												<a
													href={get(company, 'appStoreUrls.ios')}
													target="_blank"
													rel="noopener noreferrer"
												>
													IOS
												</a>
											</li>
										)
									)}
									{/* If whiteLabel company set android link in settings, show it here */}
									{get(company, 'appStoreUrls.android') === 'comingSoon' ? (
										<li>Android Coming Soon</li>
									) : (
										get(company, 'appStoreUrls.android') && (
											<li>
												<a
													href={get(company, 'appStoreUrls.android')}
													target="_blank"
													rel="noopener noreferrer"
												>
													Android
												</a>
											</li>
										)
									)}
									{/* if company has set Terms of Use URL in settings, display it's link at Login Screen */}
									{get(company, 'termsUrl') ? (
										<li>
											<a
												href={get(company, 'termsUrl')}
												target="_blank"
												rel="noopener noreferrer"
											>
												{get(company, 'name')} Terms of Use
											</a>
										</li>
									) : (
										<li>
											<a
												href="https://erinapp.com/terms-of-use/"
												target="_blank"
												rel="noopener noreferrer"
											>
												Terms of Use
											</a>
										</li>
									)}
									{/* if company has set Privacy Policy URL in settings, display it's link at Login Screen */}
									{get(company, 'privacyUrl') ? (
										<li>
											<a
												href={get(company, 'privacyUrl')}
												target="_blank"
												rel="noopener noreferrer"
											>
												{get(company, 'name')} Privacy Policy
											</a>
										</li>
									) : (
										<li>
											<a
												href="https://erinapp.com/privacy-policy/"
												target="_blank"
												rel="noopener noreferrer"
											>
												Privacy Policy
											</a>
										</li>
									)}
									{/* if company has set  URL in settings, display it's link at Login Screen */}
									{get(company, 'linkUrl') && (
										<li>
											<a
												href={get(company, 'linkUrl')}
												target="_blank"
												rel="noopener noreferrer"
											>
												{get(company, 'linkName') ?? get(company, 'name')}
											</a>
										</li>
									)}
								</ul>
							)}
							{/* If company is not whitelabel, show erin download links and About Erin */}
							{!get(company, 'whiteLabel') && this.state.fetchedCompany && (
								<ul className="auth-links-info">
									<li>
										<span>Downloads:</span>
										<a
											href="https://apps.apple.com/us/app/erin-employee-referrals/id1451343403"
											target="_blank"
											rel="noopener noreferrer"
										>
											IOS
										</a>
										<a
											href="https://play.google.com/store/apps/details?id=com.erinmobile&hl=en_US&gl=US"
											target="_blank"
											rel="noopener noreferrer"
										>
											Android
										</a>
									</li>
									<li>
										<a
											href="https://erinapp.com/terms-of-use/"
											target="_blank"
											rel="noopener noreferrer"
										>
											Terms of Use
										</a>
									</li>
									<li>
										<a
											href="https://erinapp.com/privacy-policy/"
											target="_blank"
											rel="noopener noreferrer"
										>
											Privacy Policy
										</a>
									</li>
									<li>
										<a
											href="https://erinapp.com/"
											target="_blank"
											rel="noopener noreferrer"
										>
											ERIN
										</a>
									</li>
								</ul>
							)}
							<span>
								{get(this.state, 'company.showPoweredByErin', false) &&
									get(this.state, 'company.host', '') && (
										<div className="mt-2" style={{ padding: '2px' }}>
											<img alt="logo" style={{ width: 200 }} src={PoweredBy} />
										</div>
									)}
							</span>
						</div>
					</div>
					{saml && hideLoginForm && !get(company, 'externalUserSignUp') ? (
						<div
							style={
								this.state.hideLoginScreenShading
									? { backgroundColor: 'transparent' }
									: {}
							}
							className="auth-right"
						>
							<div className="auth-card">
								{disableSAMLLogin ? (
									<h2 className="auth-title text-center" style={{ margin: 0 }}>
										No Login Options Available
									</h2>
								) : (
									<>
										<h2 className="auth-title text-center">
											Log in to {company && whiteLabel ? company?.name : 'ERIN'}
										</h2>
										<div className="text-center">
											<Button
												loading={this.state.loading}
												htmlType="submit"
												type="primary"
												size="large"
												onClick={() =>
													this.props.history.push({
														pathname: get(saml, 'path', ''),
													})
												}
											>
												{loginButtonText}
												<img
													style={{ marginLeft: '6px' }}
													src={rightArrow}
													height={26}
													width={26}
													alt="right arrow"
												/>
											</Button>
										</div>
									</>
								)}
							</div>
						</div>
					) : (
						<div
							style={
								this.state.hideLoginScreenShading
									? { backgroundColor: 'transparent' }
									: {}
							}
							className="auth-right"
						>
							<div className="auth-card">
								{this.state.fetchedCompany && (
									<RouterContext.Consumer>
										{({ onAuthentication }) => (
											<LoginCard
												error={error}
												accountClaimError={accountClaimError}
												accountClaimSuccess={accountClaimSuccess}
												closeModal={this.closeModal}
												warningMessage={warningMessage}
												successMessage={successMessage}
												theme={theme}
												company={company}
												whiteLabel={whiteLabel}
												visible={visible}
												saml={this.state.saml}
												setCurrentUser={setCurrentUser}
												handleClaimAccount={this.openModal}
												locationState={
													this.props.location.state
														? this.props.location.state
														: null
												}
												setUserEmail={this.setUserEmail}
												openModal={this.openModal}
												openForgotPasswordModal={this.openForgotPasswordModal}
												openCompanyAccountModal={this.openCompanyAccountModal}
												companyAdminSuccess={companyAdminSuccess}
												externalUserSuccess={externalUserSuccess}
												setFailedLoginAttempts={setFailedLoginAttempts}
												failedLoginAttempts={failedLoginAttempts}
												labelEmployeeID={labelEmployeeID}
												loginEmailLabel={loginEmailLabel}
												userSignupSettings={userSignupSettings}
												lblDepartment={lblDepartment}
												onAuthentication={onAuthentication}
											/>
										)}
									</RouterContext.Consumer>
								)}
							</div>
						</div>
					)}
				</div>

				<GetOrganizationModal
					saml={get(this.state, 'saml')}
					error={get(this.props, 'location.state.err')}
					theme={theme}
					visible={orgModalVisible}
					page={page}
					setPageOne={this.setPageOne}
					closeModal={this.closeModal}
				/>
				<ForgottenPasswordModal
					visible={forgotPasswordVisible}
					page={page}
					userEmail={userEmail}
					setPageOne={this.setPageOne}
					setPageTwo={this.setPageTwo}
					setUserEmail={this.setUserEmail}
					closeModal={this.closeModal}
					openForgotPasswordModal={this.openForgotPasswordModal}
				/>
			</div>
		);
	}
}

export default withApollo(LoginComponent);
