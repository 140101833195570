import getSymbolFromCurrency from 'currency-symbol-map';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { lambda, ml } from 'src/_shared/services/utils.js';

const BaseReferenceCard = ({
	currentUser,
	title,
	allMultiLingualData,
	height,
}) => {
	const [referralCount, setReferralCount] = useState(0);
	const [totalAmountDue, setTotalAmountDue] = useState(0);

	const fetchReferralCounts = async (userId) => {
		if (!userId) return;

		try {
			const referralCounts = await lambda({
				endpoint: 'getReferralCountByUser',
				variables: {
					userId,
				},
			});

			setReferralCount(referralCounts?.message?.referralCount ?? 0);
			setTotalAmountDue(referralCounts?.message?.totalAmountDue ?? 0);
		} catch (error) {
			console.error('Error fetching referral counts:', error);
		}
	};

	useEffect(() => {
		if (currentUser?.id) {
			fetchReferralCounts(currentUser.id);
		}
	}, [currentUser?.id]);

	if (!currentUser) {
		return null;
	}

	const currencyCode = currentUser?.userGroup?.currency ?? 'USD';
	const hideBonus = currentUser?.company?.hideBonus ?? false;

	return (
		<div className={`d-card ${height === 'height-equal' && 'height-equal'}`}>
			<div className="d-card-head">
				<h4 className="d-title">{title}</h4>
			</div>
			<div className="d-card-body vh-center aaa">
				<div className="justify-data-box">
					{title === ml('Your Referrals', currentUser, allMultiLingualData) && (
						<div
							className="data-card dark-text bg-ffffff"
							style={
								hideBonus &&
								title === ml('Your Referrals', currentUser, allMultiLingualData)
									? { maxWidth: 'none' }
									: {}
							}
						>
							{referralCount >= 1 ? (
								<>
									<h5>
										<Link to="/myreferrals" className="font-weight-bold">
											{referralCount}
										</Link>
									</h5>
									<p>{ml('Referrals', currentUser, allMultiLingualData)}</p>
								</>
							) : (
								<>
									<h5>0</h5>
									<p>
										<Link to="/browsejobs">
											{ml('Click Here', currentUser, allMultiLingualData)}
										</Link>{' '}
										{ml(
											'to make your first referral',
											currentUser,
											allMultiLingualData
										)}
									</p>
								</>
							)}
						</div>
					)}
					{!hideBonus &&
					title === ml('Your Referrals', currentUser, allMultiLingualData) ? (
						<div className="data-card light-text bg-6CB761">
							<h5>
								{getSymbolFromCurrency(currencyCode)}
								{totalAmountDue}
							</h5>
							<p>{ml('Total Bonuses', currentUser, allMultiLingualData)}</p>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export const ReferralCard = BaseReferenceCard;
