import { connect } from 'react-redux';
import { withGetCompany } from 'src/_shared/api/components/settings/';
import { dashboardActions } from 'src/actions.js';
import { compose } from '../../_shared/services/utils.js';
import { ReportHistoryComponent } from './ReportHistoryComponent.jsx';

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		companyId: state.user.currentUser.companyId,
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

const ReportHistoryWithAPI = compose(withGetCompany)(ReportHistoryComponent);

export const ReportHistory = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(ReportHistoryWithAPI);
