/** Packages */
import { Button, message } from 'antd';

/** Components & Styles */
import { configMode } from 'src/_shared/api/';

/** Shared Utils */
import { lambda, ml } from 'src/_shared/services/utils.js';

export function GiftCardLog({ allMultiLingualData, currentUser }) {
	const getGiftReport = () => {
		let endpoint = '';
		endpoint =
			configMode === 'DEV'
				? 'export-individual-gifts-log-prod-sendEmail'
				: 'export-individual-gifts-log-prod-sendEmail';
		message.success(
			ml(
				`Export processing. You will receive an email when ready`,
				currentUser,
				allMultiLingualData
			)
		);
		lambda({
			endpoint,
			variables: {
				companyId: currentUser.companyId,
				cognitoId: currentUser.cognitoId,
			},
		});
	};

	return (
		<div className="link-wrap">
			<h4 className="profile-heading">
				{ml('Gift Cards Log', currentUser, allMultiLingualData)}
			</h4>
			<Button type="link" onClick={getGiftReport}>
				{ml('Download', currentUser, allMultiLingualData)}
			</Button>
		</div>
	);
}
