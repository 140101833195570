import PropTypes from 'prop-types';
import { ml } from 'src/_shared/services/utils.js';

export const JobLocation = ({
	allMultiLingualData,
	currentUser,
	location,
	locations = [],
}) => {
	if (!location) return null;
	const {
		city,
		state,
		country,
		isRemote,
		originalLocation,
		prefersOriginalLocation,
	} = location;

	const isVisible = [
		city,
		state,
		country,
		isRemote,
		originalLocation,
		prefersOriginalLocation,
	].some(Boolean);

	const locationText =
		locations.length > 1
			? ml('Multiple Locations', currentUser, allMultiLingualData)
			: prefersOriginalLocation
				? originalLocation
				: isRemote
					? ml('Remote', currentUser, allMultiLingualData)
					: ([city, state].filter(Boolean).join(', ') ?? country ?? '');

	return (
		<li>
			{isVisible && <i className="icon-location" />}
			<span>{locationText}</span>
		</li>
	);
};

JobLocation.propTypes = {
	allMultiLingualData: PropTypes.arrayOf(PropTypes.object).isRequired,
	currentUser: PropTypes.object.isRequired,
	location: PropTypes.shape({
		city: PropTypes.string,
		state: PropTypes.string,
		country: PropTypes.string,
		isRemote: PropTypes.bool,
		originalLocation: PropTypes.string,
		prefersOriginalLocation: PropTypes.bool,
	}).isRequired,
	locations: PropTypes.arrayOf(PropTypes.object),
};
