import { Row, Tooltip } from 'antd';
import dompurify from 'dompurify';
import _, { get } from 'lodash';
import { Component } from 'react';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import { JobLocation } from 'src/_shared/index.js';
import { StringParser } from 'src/_shared/services/';
import {
	campaignIsActive,
	formatDate,
	getBonusAmount,
	ml,
	normalizeDollar,
	parse,
} from 'src/_shared/services/utils.js';
import { JobTypes } from '../../addJobModal/pages/copy';
import { jobLocation, summaryValue } from './jobDescriptionCardStyles.js';
import dayjs from 'dayjs';

const sanitizer = dompurify.sanitize;

export class JobDescriptionCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currencyRate: 0,
			currencySymbol: '',
		};
	}

	safeSalary = (min, max) => {
		// TODO: Handle this case better
		if (!min && !max) return '';
		if (!min || min === max) return `$${normalizeDollar(max)}`;
		if (!max) return `$${normalizeDollar(min)}`;
		return `$${normalizeDollar(min)} - $${normalizeDollar(max)}`;
	};

	renderLocations = () => {
		const locations = parse(get(this.props, 'job.locations'));
		if (!locations) return null;
		const renderLocs = locations.map((location) => {
			const icn =
				location && (location.city || location.isRemote === true) ? (
					<WebIcon name="placeholder" size={20} />
				) : null;
			const lbl = (
				<span className={jobLocation}>
					{location && location.city && location.state
						? `${location.city}, ${location.state}`
						: location && location.isRemote
							? 'Remote'
							: null}
				</span>
			);
			return (
				<span>
					{icn}
					{lbl}
				</span>
			);
		});

		const result =
			locations && locations.length > 1 ? (
				<Row>
					<span className={summaryValue}> {renderLocs} </span>
				</Row>
			) : null;
		return result;
	};

	render() {
		const {
			allMultiLingualData,
			job,
			job: {
				department,
				hiringManager,
				location,
				jobType,
				salary,
				externalJobId,
			} = {},
			onClick,
			currentUser,
		} = this.props;
		const _salary = this.safeSalary(salary.from, salary.to);

		let isHotJob = get(job, 'isHotJob', false);
		const campaignId = get(job, 'campaignId');
		const campaign = get(job, 'campaign');

		let isActive = false;
		let archived;
		if (campaignId) {
			const today = dayjs();
			const startDate = get(campaign, 'startDate');
			const endDate = get(campaign, 'endDate');
			archived = get(campaign, 'archived', false);
			if (today.isBetween(startDate, endDate, 'day', '[]')) {
				isActive = true;
				isHotJob = true;
			} else {
				isActive = false;
				isHotJob = false;
			}
		}

		if (archived) {
			isActive = false;
			isHotJob = false;
		}

		const locations = parse(get(this.props, 'job.locations'));
		const jobLevels = _.sortBy(get(job, 'jobLevels', []), ['name']);
		const showJobLevels = () => {
			let result = '';
			for (const [i, jobLevel] of jobLevels.entries()) {
				result += get(jobLevel, 'name');
				if (i !== jobLevels.length - 1) result += ', ';
			}

			return result;
		};

		const jobLevelTitle =
			jobLevels && jobLevels.length > 1 ? `Job Levels: ` : `Job Level: `;
		const isGeneralReferral = get(job, 'isGeneralReferral', false);
		const hideRecruiterInfoJobDetails = get(
			currentUser,
			'company.hideRecruiterInfoJobDetails',
			false
		);
		const generalReferralJob = () => {
			return (
				<Tooltip
					arrowPointAtCenter
					placement="topRight"
					title={ml(
						'General Jobs represent multiple openings. A new referral and bonus (if applicable) will be created when the candidate is moved to a different job.',
						currentUser,
						allMultiLingualData
					)}
					style={{ fontWeight: 1000, fontStyle: 'italic' }}
				>
					<p className="mjd-job-data">
						{ml('General Job', currentUser, allMultiLingualData)}
					</p>
				</Tooltip>
			);
		};

		return (
			<>
				<h2 className="mjd-heading" onClick={onClick}>
					{get(this.props, 'job.title')}{' '}
					{isHotJob == true ? <i className="icon-fire icon-orange" /> : null}
				</h2>
				{this.renderLocations()}
				<ul className="mjd-location">
					<JobLocation
						allMultiLingualData={allMultiLingualData}
						currentUser={currentUser}
						location={location}
						locations={locations}
					/>
					<li>
						<i className="icon-folder" />
						<span>{department ? department.name : ''}</span>
					</li>
				</ul>
				{isGeneralReferral ? (
					<>{generalReferralJob()}</>
				) : (
					<>
						{this.props.job.tieredBonus?.name && (
							<p className="mjd-job-data">
								{ml('Bonus', currentUser, allMultiLingualData)}:
								<span className="mjd-bonus">
									{this.props.job.tieredBonus?.name}
								</span>
							</p>
						)}
						{this.props.job.campaign?.tieredBonus?.name && (
							<p className="mjd-job-data">
								{ml('Campaign Bonus', currentUser, allMultiLingualData)}:
								<span className="mjd-bonus">
									{this.props.job.campaign.tieredBonus.name}
								</span>
							</p>
						)}
					</>
				)}
				{externalJobId &&
					externalJobId !== true &&
					externalJobId !== null &&
					externalJobId !== '' && (
						<p className="mjd-job-data">
							ID#:
							<span>{externalJobId}</span>
						</p>
					)}
				{job && job.dateCreated ? (
					<p className="mjd-job-data">
						{ml('Created:', currentUser, allMultiLingualData)}
						<span>
							{formatDate(
								job.dateCreated,
								currentUser.languageCode,
								currentUser.dateFormat || 'US'
							)}
						</span>
					</p>
				) : null}
				<>
					{jobLevels && jobLevels.length > 0 && jobLevels[0] !== null && (
						<p className="mjd-job-data">
							{jobLevelTitle}
							<span> {showJobLevels()} </span>
						</p>
					)}
					<p className="mjd-job-data">
						{ml('Job Type', currentUser, allMultiLingualData)}:
						<span> {JobTypes[jobType]} </span>
					</p>
					{_salary && (
						<p className="mjd-job-data">
							{ml('Salary', currentUser, allMultiLingualData)}:
							<span>
								{_salary} {salary && salary.interval ? salary.interval : null}
							</span>
						</p>
					)}
				</>
				<div className="mjd-job-description">
					<h4 className="mjd-subheading">
						{ml('Job Description', currentUser, allMultiLingualData)}:
					</h4>
					<div className="mjd-content">
						<StringParser
							text={
								this.props.job.description
									? sanitizer(this.props.job.description)
									: ''
							}
							breakOn="5"
							showMoreText={ml('View More', currentUser, allMultiLingualData)}
							showLessText={ml('View Less', currentUser, allMultiLingualData)}
						/>
					</div>
				</div>
				{hiringManager && !hideRecruiterInfoJobDetails && (
					<span className="mjd-recruiter">
						{ml('Recruiter', currentUser, allMultiLingualData)}:
						<a
							href={`mailto:${hiringManager.emailAddress}`}
							className="mjd-recruiter-name"
						>
							{' '}
							{`${hiringManager.firstName} ${hiringManager.lastName}`}
						</a>
					</span>
				)}
			</>
		);
	}
}
