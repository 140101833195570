import { SettingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Select, Tooltip, message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import {
	FREQUENCY_MAP,
	FREQUENCY_TYPE,
	FREQUENCY_VALUE,
	REPORT_DATE_RANGE,
	REPORT_TYPES,
} from 'src/_shared/constants/';
import {
	initialCaps,
	lambda,
	ml,
	validEmail,
} from 'src/_shared/services/utils.js';
import { ReportBuilderContext } from './drag-and-drop-interface/index.js';

export const ReportBuilderForm = ({
	allMultiLingualData,
	config,
	currentUser,
	disableBackup,
	deleteConfig,
	exportConfig,
	handleNotificationFrequencyChange,
	handleNotificationTypeChange,
	handleReportDateRangeChange,
	notificationFrequency,
	notificationType,
	reportDateRange,
	type,
	updateRemoteConfig,
	isFlipped,
	setIsFlipped,
	...props
}) => {
	const [emailAddresses, setEmailAddresses] = useState(
		config?.recipients ?? ''
	);
	const [error, setError] = useState('');
	const [fileName, setFileName] = useState(
		config.fileName ??
			`${currentUser.company.name.split(' ').join('-').toLowerCase()}-${type
				.split(' ')
				.join('-')
				.toLowerCase()}`
	);
	const [isAwaitingResult, setIsAwaitingResult] = useState(false);
	const [deliverBlankReports, setDeliverBlankReports] = useState(
		config.deliverBlankReports ?? false
	);
	const [displayReportDateRange, setDisplayReportDateRange] = useState(false);
	const [flipTooltipVisible, setFlipTooltipVisible] = useState(false);

	// Set ref to get header values from ReportBuilderContext
	const headersReference = useRef(null);

	useEffect(() => {
		headersReference.current.setHeaders(config.data);
		if (type === REPORT_TYPES.REFERRALS_REPORT) {
			setDisplayReportDateRange(true);
		}
	}, [config]);

	const handleFlip = () => setIsFlipped(!isFlipped);

	const handleExport = async () => {
		try {
			setIsAwaitingResult(true);
			await exportConfig();
		} catch (error_) {
			console.error(error_);
		} finally {
			setIsAwaitingResult(false);
		}
	};

	const handleDelete = async () => {
		try {
			setIsAwaitingResult(true);
			await deleteConfig();
			message.success('Remote config file deleted.');
		} catch (error_) {
			console.error(error_);
			message.error('Unable to delete remote config file.');
		} finally {
			setIsAwaitingResult(false);
		}
	};

	const handleSubmit = async () => {
		try {
			if (!emailAddresses) {
				setError('Missing email address');
				throw new Error('Missing email address');
			}

			const allEmailsValid = emailAddresses
				.trim()
				.split(/[\s,;]+/)
				.some((emailAddress) => !validEmail(emailAddress));

			if (allEmailsValid) {
				setError('Invalid email found');
				throw new Error('Invalid email found');
			}

			setError('');
			const headers = headersReference.current.getHeaders();

			// Determine upload settings
			const s3FileName = `${currentUser.company.id}.json`;
			const bucket = 'report-builder-config-erin';
			let key;
			let frequencyDBAttribute;

			switch (type) {
				case REPORT_TYPES.REFERRALS_REPORT: {
					key = `referralsReport/configuredHeaders/${s3FileName}`;
					frequencyDBAttribute = 'referralsReportFrequency';
					break;
				}

				case REPORT_TYPES.EMPLOYEE_REPORT: {
					key = `employeeReport/configuredHeaders/${s3FileName}`;
					frequencyDBAttribute = 'employeeReportFrequency';
					break;
				}

				case REPORT_TYPES.BONUS_REPORT: {
					key = `bonusReport/configuredHeaders/${s3FileName}`;
					frequencyDBAttribute = 'bonusReportFrequency';
					break;
				}

				default: {
					throw new Error('Incompatible report type.');
				}
			}

			const {
				companyId,
				company: { name: companyName },
				onUpdateCompany,
			} = props;

			const body = {
				companyName,
				data: headers,
				recipients: emailAddresses,
				fileName,
				deliverBlankReports,
			};

			if (type === REPORT_TYPES.REFERRALS_REPORT) {
				body.referralsReportDateRange = reportDateRange;
			}

			// Update S3 config file
			await lambda({
				endpoint: 'putS3Objects',
				variables: {
					userId: currentUser.id,
					files: [
						{
							bucket,
							key,
							body,
						},
					],
				},
			});

			// Update DB with frequency values
			const input = {
				id: companyId,
				[frequencyDBAttribute]: notificationFrequency,
			};
			if (type === REPORT_TYPES.REFERRALS_REPORT) {
				input.referralsReportDateRange = reportDateRange;
			}

			await onUpdateCompany({
				input,
			});

			// Flag local backup for Super Admin export
			await updateRemoteConfig(type, body);

			message.success('Successfully submitted');
		} catch (error) {
			console.error(error);
			message.error('There was a problem submitting your changes');
		}
	};

	return (
		<>
			{config.data && (
				<div className="report-builder">
					<div className={`report-builder-main ${isFlipped ? 'flipped' : ''}`}>
						<div className="report-builder-inner">
							<div className="report-builder-front">
								<div className="report-builder-setting-card">
									{/* Report Headers Drag & Drop Interface */}
									<ReportBuilderContext
										ref={headersReference}
										configData={config.data}
									/>
									<Tooltip
										autoDestroy
										placement="left"
										title={ml(
											'Export Settings',
											currentUser,
											allMultiLingualData
										)}
										open={flipTooltipVisible}
									>
										<Button
											className={`report-builder-flip-action ${error && !isFlipped ? 'error' : ''}`}
											icon={<SettingOutlined />}
											size="large"
											shape="circle"
											onClick={handleFlip}
											onMouseDown={() => setFlipTooltipVisible(false)}
											onMouseOver={() => setFlipTooltipVisible(true)}
											onMouseOut={() => setFlipTooltipVisible(false)}
										/>
									</Tooltip>
								</div>
							</div>

							<div className="report-builder-back">
								<div className="setting-card">
									<div className="report-builder-export-settings-container">
										<h2 className="setting-heading">
											{ml('Export Settings', currentUser, allMultiLingualData)}
										</h2>

										<div className="report-builder-export-settings">
											{/* Custom File Name */}
											<div className="row">
												<div className="col-md-6">
													<div>
														<h4 className="setting-card-title">
															{ml(
																'Custom File Name (.csv)',
																currentUser,
																allMultiLingualData
															)}
														</h4>
														<div className="custom-form-group">
															<Input
																type="text"
																value={fileName}
																className="report-builder-custom-input"
																onChange={(e) => setFileName(e.target.value)}
															/>
														</div>
													</div>
												</div>
											</div>

											{/* Recipient Email Addresses */}
											<div className="row">
												<div className="col-md-6">
													<div>
														<div className="report-builder-title-with-error">
															<h4 className="setting-card-title">
																{ml(
																	'Recipient Email Addresses',
																	currentUser,
																	allMultiLingualData
																)}
															</h4>
															{error && (
																<div style={{ color: 'red' }}>{error}</div>
															)}
														</div>
														<div className="custom-form-group">
															<Input.TextArea
																placeholder="Enter email addresses separated by a space, comma, or semicolon"
																className="report-builder-custom-input"
																defaultValue={emailAddresses}
																value={emailAddresses}
																onChange={(e) => {
																	setEmailAddresses(e.target.value);
																	setError('');
																}}
															/>
														</div>
													</div>
												</div>
											</div>

											{/* Report Data Date Range */}
											{displayReportDateRange && (
												<div className="row">
													<div className="col-md-6">
														<h4 className="setting-card-title">
															{ml(
																'Included Records',
																currentUser,
																allMultiLingualData
															)}
														</h4>
														<div className="custom-form-group">
															<label
																className="custom-label"
																htmlFor="reportDateRangeSelect"
															>
																{ml(
																	'Include records created within the last',
																	currentUser,
																	allMultiLingualData
																)}
															</label>
															<Select
																name="reportDateRangeSelect"
																className="custom-input"
																defaultValue={reportDateRange}
																placeholder={ml(
																	'Select Date Range ',
																	currentUser,
																	allMultiLingualData
																)}
																value={reportDateRange}
																onSelect={(selection) =>
																	handleReportDateRangeChange(selection)
																}
															>
																{Object.values(REPORT_DATE_RANGE).map(
																	(frequency) => (
																		<Select.Option
																			key={frequency}
																			value={frequency}
																		>
																			{ml(
																				initialCaps(
																					frequency.replace('_', ' ')
																				),
																				currentUser,
																				allMultiLingualData
																			)}
																		</Select.Option>
																	)
																)}
															</Select>
														</div>
													</div>
												</div>
											)}

											{/* Deliver Blank Reports */}
											<div className="row">
												<div className="col-md-6">
													<h4 className="setting-card-title">
														{ml(
															'Deliver Blank Reports',
															currentUser,
															allMultiLingualData
														)}
													</h4>
													<div className="custom-form-group">
														<Checkbox
															checked={deliverBlankReports}
															onChange={() =>
																setDeliverBlankReports(
																	(deliverBlankReports) => !deliverBlankReports
																)
															}
														>
															{ml(
																'If no report would be delivered, send a blank one instead.',
																currentUser,
																allMultiLingualData
															)}
														</Checkbox>
													</div>
												</div>
											</div>

											{/* Delivery Frequency */}
											<div className="row">
												<div className="col-md-3">
													<div>
														<h4 className="setting-card-title">
															{ml(
																'Delivery Frequency',
																currentUser,
																allMultiLingualData
															)}
														</h4>
														<div className="custom-form-group">
															<Select
																className="custom-input"
																defaultValue={notificationType}
																placeholder={ml(
																	'Select Frequency ',
																	currentUser,
																	allMultiLingualData
																)}
																value={notificationType}
																onSelect={(selection) =>
																	handleNotificationTypeChange(selection)
																}
															>
																{Object.values(FREQUENCY_TYPE).map(
																	(frequency) => (
																		<Select.Option
																			key={frequency}
																			value={frequency}
																		>
																			{ml(
																				initialCaps(frequency),
																				currentUser,
																				allMultiLingualData
																			)}
																		</Select.Option>
																	)
																)}
															</Select>
														</div>
													</div>
												</div>

												{/* Delivery Date */}
												{notificationType !== FREQUENCY_VALUE.DAILY && (
													<div className="col-md-3">
														<div>
															<h4 className="setting-card-title">
																{ml(
																	'Delivery Date',
																	currentUser,
																	allMultiLingualData
																)}
															</h4>
															<div className="custom-form-group">
																<Select
																	className="custom-input"
																	defaultValue={notificationFrequency}
																	placeholder={ml(
																		'Select Date ',
																		currentUser,
																		allMultiLingualData
																	)}
																	value={notificationFrequency}
																	onSelect={(value) =>
																		handleNotificationFrequencyChange(value)
																	}
																>
																	{Object.values(FREQUENCY_MAP)
																		.filter(
																			(frequency) =>
																				frequency.type === notificationType
																		)
																		.map((frequency) => (
																			<Select.Option
																				key={frequency.key}
																				title={frequency.key}
																				value={frequency.value}
																			>
																				{ml(
																					frequency.key,
																					currentUser,
																					allMultiLingualData
																				)}
																			</Select.Option>
																		))}
																</Select>
															</div>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
									<Button
										className={`report-builder-flip-action ${error && !isFlipped ? 'error' : ''}`}
										size="small"
										onClick={handleFlip}
									>
										{ml('Report Headers', currentUser, allMultiLingualData)}
									</Button>
								</div>
							</div>
						</div>
					</div>
					<Button
						className="report-builder-update-action"
						type="primary"
						size="large"
						onClick={handleSubmit}
					>
						{ml('Update', currentUser, allMultiLingualData)}
					</Button>
				</div>
			)}
		</>
	);
};
