import dayjs from 'dayjs';
import get from 'lodash/get';
import { JobTitleComponent } from '..';
import { ml } from 'src/_shared/services/utils';

function ReferralComments(props) {
	const { allMultiLingualData, referrals, currentUser } = props;
	const htmlDecode = (input) => {
		let returnValue = input.replaceAll('&gt;', '>');
		returnValue = returnValue.replaceAll('&lt;', '<');
		returnValue = returnValue.replaceAll('&quot;', '"');
		returnValue = returnValue.replaceAll('&apos;', "'");
		returnValue = returnValue.replaceAll('&amp;', '&');
		return returnValue;
	};

	return (
		<>
			{referrals.find(
				(referral) =>
					referral.message !== null && referral.message !== undefined
			) ? (
				<h4 className="content-right-heading">{ml('Referral Comments', currentUser, allMultiLingualData)}</h4>
			) : null}
			{referrals.map((referral, index) => {
				const message = referral.message ?? '';
				return referral.message ? (
					<div key={index}>
						<p className="content-right-comment">
							<i className="icon-message-fill" />
							<span>
								<JobTitleComponent
									jobTitle={get(referral, 'job.title', '')}
									currentUser={currentUser}
								/>
							</span>
							&nbsp;by&nbsp;
							<span>
								{get(referral, 'user.firstName')}{' '}
								{get(referral, 'user.lastName')}{' '}
							</span>
							&nbsp;on&nbsp;
							{dayjs(get(referral, 'referralDate')).format('M/D/YYYY')}
						</p>
						<p className="content-right-comment text-italic">
							"{htmlDecode(message)}"
						</p>
					</div>
				) : null;
			})}
		</>
	);
}

export default ReferralComments;
