import get from 'lodash/get';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import fileIcon from 'src/_shared/assets/erin_lightgray.png';
import { LanguageSelect } from 'src/_shared/components/language/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import {
	downloadFromS3Signed,
	getSetErrorImageURL,
	translation,
	ml,
} from 'src/_shared/services/utils.js';
import { JobDescriptionCard } from './job-description';

class JobDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			errorImageURL: '',
			page: 0,
			reload: false,
			currentUserCompany:
				props.companyData === undefined
					? props.currentUser.company
					: props.companyData,
			translatedTitle: get(props, 'currentJob.title'),
			translatedDescription: get(props, 'currentJob.description'),
		};
	}

	async componentDidMount() {
		const { currentUser } = this.props;
		const errorImageURL = await getSetErrorImageURL(
			currentUser?.company?.errorImage?.key
		);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}

		const errorImage = get(
			this.props.currentUser,
			'company.errorImage.key',
			false
		);
		if (errorImage) {
			const url = await downloadFromS3Signed(errorImage, 'erin-images');
			this.setState({ errorImageURL: url });
		}
	}

	componentDidUpdate(prevProps) {
		const { currentJob } = this.props;
		if (prevProps.currentJob !== currentJob) {
			this.setState({
				translatedDescription: get(currentJob, 'description'),
				translatedTitle: get(currentJob, 'title'),
			});
		}
	}

	handleBack = () => {
		this.setState({ page: this.state.page > 0 ? this.state.page - 1 : 0 });
	};

	async selectLanguage(languageCode) {
		let translatedTitle = get(this.props, 'currentJob.title');
		let desc = get(this.props, 'currentJob.description').toString('html');
		let translatedDescription = desc;
		if (languageCode !== 'US') {
			desc = `<h1>${translatedTitle}</h1>${desc}`;
			desc = desc.replaceAll('&nbsp;', '');
			desc = desc.replaceAll('&amp;', 'and');
			translatedDescription = await translation(desc, { languageCode });
			translatedTitle = translatedDescription.substring(
				translatedDescription.indexOf('<h1>') + 4,
				translatedDescription.lastIndexOf('</h1>')
			);
			translatedDescription = translatedDescription.slice(
				Math.max(0, translatedTitle.length + 9)
			);
		}

		this.setState({
			translatedTitle,
			translatedDescription,
		});
	}

	render() {
		const {
			allMultiLingualData,
			currentJob,
			onCreateReferral,
			onCreateContact,
			currentUser,
			onUpdateJob,
			contacts,
			currentCurrencyRate,
			currentCurrencySymbol,
			onCreateUserJobShare,
			userJobShares,
			onUpdateUserJobShare,
		} = this.props;
		const contactIncentiveBonus = get(
			this.props,
			'currentUser.company.contactIncentiveBonus'
		);
		const {
			company,
			currentUserCompany,
			errorImageURL,
			translatedDescription,
			translatedTitle,
		} = this.state;
		const whiteLabel = get(company, 'whiteLabel');
		const allowSelfReferrals = get(
			this.props,
			'currentUser.company.allowSelfReferrals'
		);
		const allowSelfReferralsInternalLink = get(
			this.props,
			'currentUser.company.allowSelfReferralsInternalLink'
		);
		const disableShareLink = get(
			this.props,
			'currentUser.company.disableShareLink'
		);
		const departmentId = get(currentJob, 'departmentId');
		const hideShareLinkForDepartment = get(
			currentUser,
			'company.hideShareLinkForDepartment'
		);
		let hideDepartmentShareLink = false;
		if (hideShareLinkForDepartment) {
			const jsonData = JSON.parse(hideShareLinkForDepartment);
			hideDepartmentShareLink = jsonData.some(function (value) {
				return value.id === departmentId;
			});
		}

		const hideShareLinkNoPublicUrl = get(
			currentUserCompany,
			'hideShareLinkNoPublicUrl'
		);
		if (
			!get(currentUser, 'company') ||
			currentJob?.id !== this.props?.match?.params?.id
		) {
			return <Spinner />;
		}

		return (
			<main>
				<div className="page-title">
					<Link className="ant-btn ant-btn-link" to="/browsejobs">
						<i className="icon-arrow-left" />
						<span>{ml('View All Jobs',currentUser, allMultiLingualData)}</span>
					</Link>
					<ul className="info-action">
						<li>
							<LanguageSelect onSelect={this.selectLanguage.bind(this)} />
						</li>
					</ul>
				</div>
				<div className="browse-job-card">
					{currentJob && (
						<JobDescriptionCard
							allMultiLingualData={allMultiLingualData}
							currentUser={currentUser}
							job={currentJob}
							translatedDescription={translatedDescription}
							translatedTitle={translatedTitle}
							contactIncentiveBonus={contactIncentiveBonus}
							contacts={contacts}
							allowSelfReferrals={allowSelfReferrals}
							currentCurrencyRate={currentCurrencyRate}
							currentCurrencySymbol={currentCurrencySymbol}
							currentUserCompany={currentUserCompany}
							allowSelfReferralsInternalLink={allowSelfReferralsInternalLink}
							disableShareLink={disableShareLink}
							userJobShares={userJobShares}
							hideDepartmentShareLink={hideDepartmentShareLink}
							hideShareLinkNoPublicUrl={hideShareLinkNoPublicUrl}
							onUpdateJob={onUpdateJob}
							onCreateReferral={onCreateReferral}
							onCreateContact={onCreateContact}
							onCreateUserJobShare={onCreateUserJobShare}
							onUpdateUserJobShare={onUpdateUserJobShare}
						/>
					)}
					{currentJob && currentJob.status === 'closed' ? (
						<div className="no-content">
							{errorImageURL && whiteLabel ? (
								<img
									src={errorImageURL}
									alt="error image"
									className="no-content-icon"
								/>
							) : (
								<img
									alt="erin-logo"
									className="no-content-icon"
									src={fileIcon}
								/>
							)}
							<p className="no-content-text">
								{ml('This Job is closed. Referrals are no longer accepted.', currentUser, allMultiLingualData)}
							</p>
						</div>
					) : null}
				</div>
			</main>
		);
	}
}

export default JobDetails;
