import { Button } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import ReferralModal from 'src/_shared/referral-modal/ReferralModalComponent.jsx';
import { ml } from 'src/_shared/services/utils.js';
import {
	ReferralBtn as ReferralButton,
	ReferralBtnMyReferrals as ReferralButtonMyReferrals,
	ReferralRequestBtn as ReferralRequestButton,
} from './referralModalWrapperStyles.js';

class ReferralModalWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			job: get(props, 'jobData', get(props, 'job')),
			contacts: props.contacts,
			contact: props.contact,
			visible: false,
			referrals: props.referrals,
			companyData: props.companyData,
			theme: JSON.parse(
				get(
					props,
					'currentUser.company.theme',
					get(props, 'companyData.theme', '{}')
				)
			),
		};
	}

	handleCancel = () => {
		this.setState({ visible: false });
	};

	showModal = () => {
		this.setState({ visible: true });
	};

	render() {
		const { job, visible, contact, referrals, theme } = this.state;
		const {
			allMultiLingualData,
			buttonType,
			isBtnBlock,
			contacts,
			contactsNextToken,
			currentCurrencyRate,
			currentCurrencySymbol,
			currentUser,
			companyData,
			contactIncentiveBonus,
			campaignId,
			notification,
			setCurrentUser,
			setUpdatedContacts,
			onUpdateJob,
			onCreateUserJobShare,
			onUpdateUserJobShare,
		} = this.props;
		const referralButtonStyle =
			buttonType === 'small'
				? ReferralRequestButton(theme)
				: contact
					? ReferralButtonMyReferrals
					: ReferralButton(theme);
		const disabled = get(this.props, 'disabled', false);
		return (
			<>
				<Button
					disabled={disabled}
					type="primary"
					block={Boolean(isBtnBlock)}
					size={buttonType ? buttonType : 'default'}
					onClick={this.showModal}
				>
					{disabled
						? ml('Referral Not Available', currentUser, allMultiLingualData)
						: get(this.props, 'buttonType') === 'small'
							? ml('Refer', currentUser, allMultiLingualData)
							: this.props.matchFound
								? ml('Refer Contact', currentUser, allMultiLingualData)
								: this.props.contact
									? ml('Make Referral', currentUser, allMultiLingualData)
									: ml('Refer Someone', currentUser, allMultiLingualData)}
				</Button>
				{visible && (
					<ReferralModal
						setUpdatedContacts={setUpdatedContacts}
						allMultiLingualData={this.props.allMultiLingualData}
						currentUser={this.props.currentUser}
						newContact={this.props.newContact}
						currentTieredBonus={this.props.currentTieredBonus}
						job={job}
						contact={contact}
						contacts={contacts}
						contactsNextToken={contactsNextToken}
						visible={visible}
						handleCancel={this.handleCancel}
						matchFound={this.props.matchFound}
						referrals={referrals}
						handleNotificationCompleted={this.props.handleNotificationCompleted}
						currentCurrencyRate={currentCurrencyRate}
						currentCurrencySymbol={currentCurrencySymbol}
						companyData={companyData}
						contactIncentiveBonus={contactIncentiveBonus}
						campaignId={campaignId}
						notification={notification}
						setCurrentUser={setCurrentUser}
						onCreateContact={this.props.ImportedCreateContact}
						onCreateReferral={this.props.onCreateReferral}
						onCreateWebNotification={this.props.onCreateWebNotification}
						onUpdateContact={this.props.onUpdateContact}
						onUpdateJob={onUpdateJob}
						onCreateUserJobShare={onCreateUserJobShare}
						onUpdateUserJobShare={onUpdateUserJobShare}
					/>
				)}
			</>
		);
	}
}

export default ReferralModalWrapper;
