import get from 'lodash/get';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { COLORS } from 'src/_shared/styles/colors';
import { USER_ROLES } from '../../constants/userRolesEnum.js';
import { ml, parse } from '../../services/utils';
import { HeaderMenuItem } from './header-menu-item';
import { UserProfile } from './user-profile';

class BaseHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			headerMultiData: '',
			currentLocation: this.props?.location?.pathname || '/',
		};
	}

	async componentDidMount() {
		const pathname =
			this.props.location.pathname === '/'
				? '/dashboard'
				: this.props.location.pathname;
		this.props.updatePathname(pathname);
	}

	async componentDidUpdate(prevProps) {
		if (prevProps.currentLocation !== this.props.currentLocation) {
			this.setState({ currentLocation: this.props.currentLocation });
		}
	}

	handleLinkClick = (location) => {
		this.props.updatePathname(location);
	};

	renderMenuItems = () => {
		const allMultiLingualData = get(this.props, 'allMultiLingualData');
		const currentUser = get(this.props, 'currentUser');
		const platformConfig = currentUser?.company?.platformConfig
			? JSON.parse(currentUser.company.platformConfig)
			: { primaryPlatform: 'referrals', filterMode: 'exclusionary', filterList: []};
		const checkFiltered = (filteredPage) => {
			if (currentUser.role === USER_ROLES.SUPER_ADMIN) return false;
			return platformConfig?.filterList?.some((value) => value === filteredPage);
		};
		const enableCustomPage = get(
			this.props,
			'currentUser.company.enableCustomPage'
		);
		const enableCustomPage2 = get(
			this.props,
			'currentUser.company.enableCustomPage2'
		);
		const enableCustomPage3 = get(
			this.props,
			'currentUser.company.enableCustomPage3'
		);
		const customPageTitle = get(
			this.props,
			'currentUser.company.customPageTitle'
		);
		const customPageTitle2 = get(
			this.props,
			'currentUser.company.customPageTitle2'
		);
		const customPageTitle3 = get(
			this.props,
			'currentUser.company.customPageTitle3'
		);

		const hideJobsPage = get(
			this.props,
			'currentUser.company.hideJobsPage',
			false
		);

		const disableExtendedUserJobsView = get(
			this.props,
			'currentUser.company.disableExtendedUserJobsView',
			false
		);

		const HeaderItemMeta = {
			BROWSE: {
				title: ml('Jobs', currentUser, allMultiLingualData),
				link: '/browsejobs',
			},
			ANNOUNCEMENTS: {
				title: ml('Announcements', currentUser, allMultiLingualData),
				link: '/announcements',
			},
			CUSTOMPAGE: {
				title: customPageTitle ? customPageTitle : 'Custom Page',
				link: '/custompage/1',
			},
			CUSTOMPAGETWO: {
				title: customPageTitle2 ? customPageTitle2 : 'Custom Page 2',
				link: '/custompage/2',
			},
			CUSTOMPAGETHREE: {
				title: customPageTitle3 ? customPageTitle3 : 'Custom Page 3',
				link: '/custompage/3',
			},
		};

		if (checkFiltered('announcements') || get(this.props, 'currentUser.role') === 'extendedUser') {
			delete HeaderItemMeta.ANNOUNCEMENTS;
		}

		if (checkFiltered('customPages') || !enableCustomPage || currentUser.role === USER_ROLES.EXTENDED_USER) {
			delete HeaderItemMeta.CUSTOMPAGE;
		}

		if (checkFiltered('customPages') || !enableCustomPage2 || currentUser.role === USER_ROLES.EXTENDED_USER) {
			delete HeaderItemMeta.CUSTOMPAGETWO;
		}

		if (checkFiltered('customPages') || !enableCustomPage3 || currentUser.role === USER_ROLES.EXTENDED_USER) {
			delete HeaderItemMeta.CUSTOMPAGETHREE;
		}

		if (
			checkFiltered('jobs') ||
			hideJobsPage ||
			(disableExtendedUserJobsView &&
				currentUser.role === USER_ROLES.EXTENDED_USER)
		) {
			delete HeaderItemMeta.BROWSE;
		}

		const currentLocation = get(this.state, 'currentLocation', '');
		const items = [];
		const theme = parse(get(currentUser, 'company.theme'));
		for (const key of Object.keys(HeaderItemMeta)) {
			const { title, link } = HeaderItemMeta[key];
			const isCurrentLocation = currentLocation.startsWith(link);
			const item = (
				<HeaderMenuItem
					key={key}
					title={title}
					link={link}
					active={isCurrentLocation}
					theme={theme}
					onMenuItemClick={this.handleLinkClick}
					{...this.props}
				/>
			);
			items.push(item);
		}

		const menu = <ul className="top-menu">{items}</ul>;
		return menu;
	};

	render() {
		const {
			allMultiLingualData,
			createSetLanguage,
			onUpdate,
			setMultiLingualData,
			updateCurrentUserLanguage,
			currentUser,
		} = this.props;
		return get(currentUser, 'company') ? (
			<nav className="nav-menu">
				<div className="hamburger" onClick={this.props.hamburgerClickHandler}>
					<div className="bar" />
					<div className="bar" />
					<div className="bar" />
				</div>
				{this.renderMenuItems()}
				<UserProfile
					currentUser={currentUser}
					history={this.props.history}
					updateCurrentUserLanguage={updateCurrentUserLanguage}
					createSetLanguage={createSetLanguage}
					allMultiLingualData={allMultiLingualData}
					setMultiLingualData={setMultiLingualData}
					onUpdate={onUpdate}
				/>
			</nav>
		) : (
			<div style={{ height: 71, backgroundColor: COLORS.lightGray2 }} />
		);
	}
}

export const HeaderComponent =
	withRouter((props) => {
		return <BaseHeader {...props} />;
	}
);
