// ReferralJob.jsx

import React, { useState } from 'react';
import { Col, Typography } from 'antd';
import { Link } from 'react-router-dom';
import BonusInfo from './BonusInfo';
import { ml } from 'src/_shared/services/utils';

const { Paragraph } = Typography;

const ReferralJob = ({
	allMultiLingualData,
	referral,
	myReferralBonus,
	totalBonus,
	currentTieredBonus,
	currentUser,
	showModal,
}) => {
	const isHotJob = referral.isHotJob;
	const jobTitle = referral.job?.title;

	return (
		<Col className="referral-job">
			<div className="referral-job-title">
				<Paragraph className="referral-job-paragraph">
					<Link to={`/browsejobs/${referral?.job?.id}`}>
						<div>
							{isHotJob && <i className="icon-fire text-orange" />}
							<strong>{jobTitle || null}</strong>
						</div>
					</Link>
				</Paragraph>
			</div>

			<BonusInfo
				referral={referral}
				myReferralBonus={myReferralBonus}
				totalBonus={totalBonus}
				currentTieredBonus={currentTieredBonus}
				currentUser={currentUser}
				allMultiLingualData={allMultiLingualData}
			/>
			{referral.status === 'hired' && totalBonus > 0 && (
				<a onClick={showModal} className="view-bonus-details">
					<strong>{ml('View Bonus Details', currentUser, allMultiLingualData)}</strong>
				</a>
			)}
		</Col>
	);
};

export default ReferralJob;
