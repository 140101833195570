import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Input, List, Modal, Popconfirm, Switch, message } from 'antd';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { slackNotificationSovren } from 'src/_shared/services/';
import { lambda, ml } from 'src/_shared/services/utils.js';
import AddJobForm from './AddJobFormComponent.jsx';
import EditJobForm from './EditJobFormComponent.jsx';
import Base64 from './base64.js';

function ResumeInfo(props) {
	const [careerProfile, setCareerProfile] = useState(props.careerProfile);
	const [switchChecked, setSwitchChecked] = useState(props.openToNewRole);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setCareerProfile(props.careerProfile);
	}, [props.careerProfile, props.openToNewRole]);

	const currentUser = get(props, 'currentUser');
	const allMultiLingualData = get(props, 'allMultiLingualData');
	const {
		handleChange,
		addPosition,
		editedResumePosition,
		editedOrg,
		editedStartDate,
		editedEndDate,
		editedDescription,
		toggleNewPosition,
		handleCancel,
		submitNewPosition,
		cancelModal,
		submitJobEdit,
		handleJobEdit,
		deleteUserJob,
	} = props;
	const { confirm } = Modal;

	const currentOrEndDate = (job) => {
		if (job.end !== undefined && job.end !== null) {
			if (job.end.current) {
				return 'Current';
			}

			if (
				isNaN(job.end.month ? job.end.month : Number.NaN) ||
				isNaN(job.end.year ? job.end.year : Number.NaN)
			) {
				return 'Presently Working';
			}

			return `${job.end.monthName} ${job.end.year}`;
		}
	};

	const onChange = (file) => {
		const cp = careerProfile ? careerProfile : {};
		if (get(cp, 'employment') && cp.employment.length > 0) {
			confirm({
				title: 'Importing your resume will update your work history.',
				icon: <InfoCircleOutlined />,
				content:
					'Your current work history may be overwritten or duplicated if you continue.',
				onOk() {
					sovrenParseResumeAndCreateMatches(file);
				},
				onCancel() {
					document.querySelector('#fileInput').value = null;
				},
			});
		} else {
			sovrenParseResumeAndCreateMatches(file);
		}
	};

	const checkModalIndex = (index) => {
		if (index === props.visibleModalIndex) {
			return true;
		}

		return false;
	};

	const sovrenParseResumeAndCreateMatches = async (file) => {
		if (!file) return;

		setIsLoading(true);

		const revisionDate = new Date(file.lastModified ?? Date.now())
			.toISOString()
			.slice(0, 10);
		const reader = new FileReader();
		reader.addEventListener('load', async (event) => {
			try {
				const encodedFile = Base64.encodeArray(event.target.result);
				const resumeData = {
					DocumentAsBase64String: encodedFile,
					RevisionDate: revisionDate,
				};
				const data = await lambda({
					endpoint: 'sovrendatacalls',
					variables: resumeData,
				});
				await slackNotificationSovren({
					username: 'sovren',
					title: `${get(props, 'currentUser.firstName')} ${get(
						props,
						'currentUser.lastName'
					)} has uploaded a resume! Credits Remaining: ${
						data.creditsRemaining ?? 'unknown'
					}`,
					name: `Resume Uploaded`,
				});
				props.handleUpdateSovrenResume(file, data.parsedDocument);
				const resume = data.parsedDocument;
				await autofillResume(resume);
				setIsLoading(false);
			} catch (error) {
				console.error(error);
				message.error(
					'There was a problem uploading your resume. Please try again in a few minutes.'
				);
				setIsLoading(false);
			}
		});

		reader.readAsArrayBuffer(file);
	};

	const autofillResume = async (resume = {}) => {
		const parsedDocument = JSON.parse(resume);
		const structuredDocument = parsedDocument.Resume.StructuredXMLResume;
		const skillsArray =
			parsedDocument.Resume.UserArea['sov:ResumeUserArea'][
				'sov:ExperienceSummary'
			]['sov:SkillsTaxonomyOutput']['sov:TaxonomyRoot'][0]['sov:Taxonomy'];
		await props.autofillResumeInfo(structuredDocument, skillsArray);
	};

	const resumeFileInput = (
		<input
			type="file"
			id="fileInput"
			accept=".pdf,.txt,.rtf,.doc,.docm,.docx,.dot,.dotm,.dotx,.mht,.mhtml,.odt,.ott,.odm,.htm,.html.,.htmlc,.wps,.xml,.xps,.eml,.emlx,.msg,.oft,.olm,.zip,.gzip,.7z,.xml"
			style={{ display: 'none' }}
			onChange={(event) => onChange(event.target.files[0] || null)}
			onClick={(event) => (event.target.value = '')}
		/>
	);
	return (
		<>
			<div className="page-title">
				<div>
					<Popconfirm
						title="Are you sure?"
						placement="bottom"
						okText="Yes"
						cancelText="No"
						onConfirm={() => {
							props.handleNewRole(switchChecked);
						}}
						onCancel={() => {
							setSwitchChecked((switchChecked) => !switchChecked);
						}}
					>
						<Switch
							checked={switchChecked}
							onChange={(checked) => {
								setSwitchChecked(checked);
							}}
						/>
					</Popconfirm>
					<span style={{ marginLeft: 8, fontWeight: '600', lineHeight: '22px' }}>
						{currentUser.openToNewRole
							? ml('Open to New Roles', currentUser, allMultiLingualData)
							: ml(
								`Looking for a new role at ${get(
									props,
									'currentUser.company.name'
								)}? Let us know!`,
								currentUser,
								allMultiLingualData
						)}
					</span>
				</div>
				<ul className="info-action">
					<li>
						<Button
							type="link"
							className="add-btn btn-lg"
							disabled={isLoading}
							onClick={() => document.querySelector('#fileInput').click()}
						>
							<span className="icon-circle">
								<i className={isLoading ? 'icon-spinner2' : 'icon-plus'} />
							</span>
							{ml(
								isLoading ? 'Loading' : 'Import Resume',
								currentUser,
								allMultiLingualData
							)}
							{resumeFileInput}
						</Button>
					</li>
				</ul>
			</div>
			<div className="row">
				<div className="col-md-6 col-xs-12">
					<div className="career-card">
						<div className="career-card-head">
							<h2 className="career-card-heading">Skills</h2>
							{get(careerProfile, 'skills', []).length > 0 && (
								<Button
									type="link"
									className="add-btn btn-sm"
									disabled={isLoading}
									onClick={() => props.flipSkillModal(true)}
								>
									<span className="icon-circle">
										<i className={isLoading ? 'icon-spinner2' : 'icon-plus'} />
									</span>
									{ml('Add Skill', currentUser, allMultiLingualData)}
								</Button>
							)}
						</div>
						<div className="career-card-body">
							{get(careerProfile, 'skills', []).length === 0 && (
								<Button
									type="link"
									className="add-btn btn-lg"
									disabled={isLoading}
									onClick={() => props.flipSkillModal(true)}
								>
									<span className="icon-circle">
										<i className={isLoading ? 'icon-spinner2' : 'icon-plus'} />
									</span>
									{ml('Add Skill', currentUser, allMultiLingualData)}
								</Button>
							)}
						</div>
						<Modal
							centered
							open={props.skillModal}
							footer={null}
							title="Type your new skill and press enter"
							onCancel={() => props.flipSkillModal(false)}
						>
							<div className="custom-form-group">
								<label className="custom-label">Skills</label>
								<Input
									className="custom-input"
									placeholder="Enter a new skill"
									value={props.inputSkill}
									onPressEnter={async (e) => {
										if (e.target.value.length > 0) {
											props.handleSkillChange('');
											await props.addUserSkill(e.target.value);
										}
									}}
									onChange={(e) => props.handleSkillChange(e.target.value)}
								/>
							</div>
						</Modal>
						<ul className="tag-wrap">
							{get(careerProfile, 'skills', []).length > 0
								? get(careerProfile, 'skills', []).map((s, index) => {
										const skill = s
											? s
													.split(' ')
													.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
													.join(' ')
											: '';

										return (
											<li
												key={index}
												className="tag green"
												onClick={(e) => e.stopPropagation()}
											>
												<span className="tag-name"> {skill} </span>
												<Popconfirm
													placement="left"
													title="Are you sure？"
													okText="Delete"
													cancelText="Cancel"
													disabled={isLoading}
													onConfirm={async () =>
														await props.deleteUserSkill(index)
													}
												>
													<i className="tag-close icon-cross" />
												</Popconfirm>
											</li>
										);
									})
								: null}
						</ul>
						{get(careerProfile, 'skills', []).length > 0 && (
							<div className="career-card-footer">
								<Popconfirm
									placement="left"
									title="Are you sure you want to remove all Skills？"
									okText="Remove All"
									cancelText="Cancel"
									onConfirm={async () => await props.deleteAllUserSkills()}
								>
									<Button
										type="link"
										className="remove-all-button"
										disabled={isLoading}
									>
										{`${ml('Remove All', currentUser, allMultiLingualData)}`}
									</Button>
								</Popconfirm>
							</div>
						)}
					</div>
				</div>
				<div className="col-md-6 col-xs-12">
					<div className="career-card">
						<div className="career-card-head">
							<h2 className="career-card-heading">
								{ml('Work History', currentUser, allMultiLingualData)}
							</h2>
							{get(careerProfile, 'employment', []).length > 0 && (
								<Button
									type="link"
									className="add-btn btn-sm"
									disabled={isLoading}
									onClick={props.toggleNewPosition}
								>
									<span className="icon-circle">
										<i className={isLoading ? 'icon-spinner2' : 'icon-plus'} />
									</span>
									{ml('Add Work History', currentUser, allMultiLingualData)}
								</Button>
							)}
						</div>
						<div className="career-card-body">
							{get(careerProfile, 'employment', []).length === 0 && (
								<Button
									type="link"
									className="add-btn btn-lg"
									disabled={isLoading}
									onClick={props.toggleNewPosition}
								>
									<span className="icon-circle">
										<i className={isLoading ? 'icon-spinner2' : 'icon-plus'} />
									</span>
									{ml('Add Work History', currentUser, allMultiLingualData)}
								</Button>
							)}
						</div>

						<AddJobForm
							{...{
								currentUser,
								allMultiLingualData,
								handleChange,
								addPosition,
								editedResumePosition,
								editedOrg,
								editedStartDate,
								editedEndDate,
								editedDescription,
								toggleNewPosition,
								handleCancel,
								submitNewPosition,
							}}
						/>

						{get(careerProfile, 'employment', []).length > 0 && (
							<List
								itemLayout="horizontal"
								dataSource={careerProfile.employment}
								renderItem={(job, index) => (
									<List.Item key={'job' + index}>
										<div className="work-history-details">
											<div className="work-history-left">
												<div className="job-details">
													<p>
														<strong>{job.title}</strong>
													</p>
													<p>{get(job, 'name')}</p>

													<p>
														{job.start !== undefined && job.start !== null
															? `${job.start.monthName} ${job.start.year}`
															: null}{' '}
														{' - '}
														{currentOrEndDate(job)}
													</p>
												</div>
												<div className="job-description">
													<p>
														<strong>
															{ml(
																'Description',
																currentUser,
																allMultiLingualData
															)}
														</strong>
													</p>
													<p>{job.description}</p>
												</div>
											</div>
											<div className="work-history-right">
												<Button
													type="link"
													className="btn-link"
													disabled={isLoading}
													onClick={() => props.setModalIndex(index)}
												>
													<i className="icon-edit" />
													Edit
												</Button>
											</div>
										</div>

										<EditJobForm
											visible={checkModalIndex(index)}
											onOk={async () => await submitJobEdit()}
											onCancel={cancelModal}
											{...{
												currentUser,
												allMultiLingualData,
												job,
												index,
												handleJobEdit,
												deleteUserJob,
											}}
											key={'editJobForm' + index}
										/>
									</List.Item>
								)}
							/>
						)}
						{get(careerProfile, 'employment', []).length > 0 && (
							<div className="career-card-footer">
								<Popconfirm
									placement="left"
									title="Are you sure you want to remove all Work History？"
									okText="Remove All"
									cancelText="Cancel"
									onConfirm={async () => await props.deleteAllUserJobs()}
								>
									<Button
										type="link"
										className="remove-all-button"
										disabled={isLoading}
									>
										{`${ml('Remove All', currentUser, allMultiLingualData)}`}
									</Button>
								</Popconfirm>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default ResumeInfo;
