import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import App from './app.component';
import { WithApiProvider } from 'src/_shared/api/index.js';
import ErrorHandler from './error-handler.jsx';

Amplify.configure({
	Auth: {
		region: 'us-east-2',
		userPoolId: 'us-east-2_G43SkZOZF',
		userPoolWebClientId: '43lo6tposab52aj6ft6u4c33cb',
	},
});
const rootElement = document.querySelector('#root');
const root = createRoot(rootElement);
const consoleWarning = console.warn;

console.warn = (...args) => {
	// Check if it's an Apollo "Missing field ..." message
	if (typeof args[0] === 'string' && args[0].includes('Missing field')) {
		return; // Ignore this specific warning
	}

	consoleWarning(...args);
};

root.render(
	<ErrorHandler>
		<WithApiProvider Component={App} />
	</ErrorHandler>
);
