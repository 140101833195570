import { useEffect, useState } from 'react';
import { Spinner, lambda } from 'src/_shared/index.js';
import { ReportHistoryTable } from './ReportHistoryTable.jsx';

export const ReportHistoryComponent = (props) => {
	const [isLoading, setIsLoading] = useState(true);
	const [reports, setReports] = useState([]);
	useEffect(() => {
		const getReports = async () => {
			try {
				const response = await lambda({
					endpoint: 'metabaseReportHistory',
					variables: {
						companyId: props.companyId,
					},
				});
				setReports(response.files);
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};

		getReports();
	}, [props.companyId]);
	return (
		<main>
			<div className="page-title">
				<h2 className="page-heading">Report History</h2>
			</div>
			{isLoading ? (
				<Spinner />
			) : reports.length > 0 ? (
				<ReportHistoryTable dataSource={reports} />
			) : (
				<>Looks like you haven't generated any reports yet</>
			)}
		</main>
	);
};
