import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { COLORS } from '../../styles/colors';
import { useCallback, useState } from 'react';
import { translation } from 'src/_shared';

function OwnershipPeriodTooltip({ ownershipSettings, currentUser }) {
	const [translatedTooltip, setTranslatedTooltip] = useState(null);
	const languageCode = currentUser?.languageCode;
	let tooltipTitle = '';

	if (ownershipSettings?.days && ownershipSettings?.expiryDate) {
		tooltipTitle = `Your company's policy assigns credit to the first referring employee for ${ownershipSettings.days} day${
			ownershipSettings.days > 1 ? 's' : ''
		}. This candidate will be eligible to be referred by a different employee on ${
			ownershipSettings.expiryDate
		}`;
	}

	const handleTooltipVisibleChange = useCallback(
		async (visible) => {
			if (!ownershipSettings?.enabled || !ownershipSettings?.expiryDate) {
				return;
			}

			if (visible) {
				if (tooltipTitle && languageCode && languageCode !== 'EN' && languageCode !== 'US') {
					try {
						const tooltipTitleTranslated = await translation(tooltipTitle, {
							languageCode,
						});
						setTranslatedTooltip(tooltipTitleTranslated);
					} catch (error) {
						console.error(error);
						setTranslatedTooltip(null);
					}
				} else {
					setTranslatedTooltip(null);
				}
			}
		},
		[tooltipTitle, languageCode, ownershipSettings]
	);

	if (!ownershipSettings?.enabled || !ownershipSettings?.expiryDate) {
		return null;
	}

	return (
		<Tooltip
			placement="top"
			title={translatedTooltip || tooltipTitle}
			onOpenChange={handleTooltipVisibleChange}
			style={{ fontWeight: 1000 }}
		>
			<InfoCircleOutlined className="mr-2" style={{ color: COLORS.blue }} />
		</Tooltip>
	);
}

export default OwnershipPeriodTooltip;
