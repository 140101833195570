import { useEffect, useState } from 'react';
import { message } from 'antd';
import { useRnRCommonService } from '../../service/RnRCommon.service.jsx';
import { parse } from 'src/_shared/services/utils';

const useBadgeList = (badgeType, shouldRefresh, setShouldRefresh, getMyBadge = false) => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [badgeList, setBadgeList] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [pageLimit] = useState(18);
    const commonAPIService = useRnRCommonService();

    const getAllBadgeList = async (page, limit, refresh = false) => {
        try {
            setIsLoading(true);
            const response = await commonAPIService.getBadgesList({ page: page, limit: limit, visibility: false, type: badgeType });
            if (response?.code === 200) {
                const responseData = response.data;
                setShouldRefresh(false);
                setHasMore(responseData?.pagination?.hasNextPage);
                const badgeListFull = refresh ? responseData.records : prevData => [...prevData, ...responseData.records]
                setBadgeList(badgeListFull);
                if (page > 1) {
                    const lastCache = parse(localStorage.getItem(`badgeList-${badgeType}`))
                    const updatedCache = [...lastCache, ...responseData.records]
                    const dedupedCache = [...new Set(updatedCache)];
                    localStorage.setItem(`badgeList-${badgeType}`, JSON.stringify(dedupedCache));
                } else {
                    const dedupedCache = [...new Set(responseData.records)];
                    localStorage.setItem(`badgeList-${badgeType}`, JSON.stringify(dedupedCache));
                }
            } else {
                message.error('Failed to fetch badges list! Try again later.');
            }
        } catch (error) {
            console.error(error);
            message.error('Failed to fetch badges list! Try again later.');
        } finally {
            setIsLoading(false);
        }
    };
    const getMyBadgeList = async () => {
        setIsLoading(true);
        try {
            const response = await commonAPIService.getEmployeeRewardsList({ assignmentType: 'badgeAssignment' })
            if (response?.code === 200) {
                const dedupedCache = [...new Set(response?.data?.records || [])];
                localStorage.setItem(`badgeList-myBadges`, JSON.stringify(dedupedCache));
                setBadgeList(response?.data?.records || []);
            } else {
                message.error('Failed to fetch reward list');
            }
        } catch (error) {
            console.error(error);
            message.error('Failed to fetch reward list');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (shouldRefresh) {
            setCurrentPage(1);
            getAllBadgeList(1, pageLimit, true);
        }
    }, [shouldRefresh]);

    useEffect(() => {
        if (getMyBadge) {
            getMyBadgeList();
        } else {
            getAllBadgeList(currentPage, pageLimit);
        }
    }, [currentPage, pageLimit, getMyBadge]);

    const fetchMoreData = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };
    const cachedBadgeList = parse(localStorage.getItem(`badgeList-${getMyBadge ? "myBadges" : badgeType}`));
    return { isLoading, badgeList: badgeList.length > 0 ? badgeList : cachedBadgeList, hasMore, fetchMoreData };
};

export default useBadgeList;
