import { Input, message, Collapse } from 'antd';
import { useState, useEffect } from 'react';
import QRCode from 'qrcode';
import { ml } from 'src/_shared';
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterShareButton, WhatsappIcon, WhatsappShareButton,
	XIcon,
} from 'react-share';
import { queryUserJobShareByUserIdIndex } from 'src/_shared/api/graphql/custom/users/';
import gql from 'graphql-tag';
import { get } from 'lodash';


const { Search } = Input;
function ShareSendReferralForm(props) {
	const {
		client,
		currentUser,
		allMultiLingualData,
		jobId,
		job,
		languageCode,
		onUpdateJob,
		onCreateUserJobShare,
		onUpdateUserJobShare,
	} = props;
	const [shareButtonsDisabled, setShareButtonsDisabled] = useState(false);
	const [userAllJobShares, setUserAllJobShares] = useState([]);
	const [shareButtonClicked, setShareButtonClicked] = useState(null);
	const [activeKey, setActiveKey] = useState([]);
	const [generatedQRCode, setGeneratedQRCode] = useState('');
	const [previewUrl, setPreviewUrl] = useState('');

	useEffect(() => {
		const languageCodeDefault = (
			languageCode === 'US' ||
			languageCode === null ||
			languageCode === undefined
		) ? 'EN' : languageCode;

		const socialMediaSharePreviewUrl = `${
			currentUser?.company?.host
				? 'https://' + currentUser.company.host
				: window.location.origin
		}/share?referredBy=${
			currentUser.id
		}&jobId=${jobId}&languageCode=${languageCodeDefault || 'EN'}`;
		QRCode.toDataURL(socialMediaSharePreviewUrl).then(result => setGeneratedQRCode(result));
		setPreviewUrl(socialMediaSharePreviewUrl);
	},[languageCode]);

	const handleCollapseChange = (key) => {
		setActiveKey(key);
	};

	const handleClicked = (shareButton) => {
		setShareButtonsDisabled(true);
		setShareButtonClicked(shareButton);

		setTimeout(() => {
			setShareButtonsDisabled(false);
		}, 5500);
	}

	const getUserJobShares = async (
		policy = 'network-only',
		nextToken = null,
		userAllJobSharesData = []
	) => {
		try {
			const { data } = await client.query({
				query: gql(queryUserJobShareByUserIdIndex),
				variables: {
					userId: currentUser.id,
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const userJobShares = [...data.queryUserJobShareByUserIdIndex.items];
			const token = get(data, 'queryUserJobShareByUserIdIndex.nextToken', null);
			userAllJobSharesData = [...userAllJobSharesData, ...userJobShares];
			setUserAllJobShares(userAllJobSharesData);
			if (token) {
				await getUserJobShares(policy, token, userAllJobSharesData);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const updateShareCount = async () => {
		const { shares } = job;
		await getUserJobShares();
		const userJobSharesLength = userAllJobShares.length;
		const currentUserId = currentUser.id;
		const companyId = currentUser.companyId;
		let facebookSharesCount = 0;
		let facebookShareLastDate = '';
		let twitterSharesCount = 0;
		let twitterShareLastDate = '';
		let linkedinSharesCount = 0;
		let linkedinShareLastDate = '';
		let whatsAppSharesCount = 0;
		let whatsAppShareLastDate = '';

		await onUpdateJob({
			id: jobId,
			shares: shares + 1,
		}).catch((error) => console.error('Update Job Share Count Error:', error));
		switch (shareButtonClicked) {
			case 'facebook': {
				facebookSharesCount = 1;
				facebookShareLastDate = new Date().toISOString();

				break;
			}

			case 'twitter': {
				twitterSharesCount = 1;
				twitterShareLastDate = new Date().toISOString();

				break;
			}

			case 'linkedin': {
				linkedinSharesCount = 1;
				linkedinShareLastDate = new Date().toISOString();

				break;
			}

			case 'whatsapp': {
				whatsAppSharesCount = 1;
				whatsAppShareLastDate = new Date().toISOString();

				break;
			}
			// No default
		}

		if (userJobSharesLength > 0) {
			const currentUserJobShareDetails = userAllJobShares.filter(
				(item) => item.jobId === jobId
			);
			if (currentUserJobShareDetails.length > 0) {
				facebookSharesCount = currentUserJobShareDetails[0].facebookSharesCount;
				facebookShareLastDate =
					currentUserJobShareDetails[0].facebookShareLastDate;
				twitterSharesCount = currentUserJobShareDetails[0].twitterSharesCount;
				twitterShareLastDate =
					currentUserJobShareDetails[0].twitterShareLastDate;
				linkedinSharesCount = currentUserJobShareDetails[0].linkedinSharesCount;
				linkedinShareLastDate =
					currentUserJobShareDetails[0].linkedinShareLastDate;
				whatsAppSharesCount = currentUserJobShareDetails[0].whatsAppSharesCount;
				whatsAppShareLastDate =
					currentUserJobShareDetails[0].whatsAppShareLastDate;
				switch (shareButtonClicked) {
					case 'facebook': {
						facebookSharesCount += 1;
						facebookShareLastDate = new Date().toISOString();

						break;
					}

					case 'twitter': {
						twitterSharesCount += 1;
						twitterShareLastDate = new Date().toISOString();

						break;
					}

					case 'linkedin': {
						linkedinSharesCount += 1;
						linkedinShareLastDate = new Date().toISOString();

						break;
					}

					case 'whatsapp': {
						whatsAppSharesCount += 1;
						whatsAppShareLastDate = new Date().toISOString();

						break;
					}
					// No default
				}

				await onUpdateUserJobShare({
					id: currentUserJobShareDetails[0].id,
					facebookSharesCount,
					twitterSharesCount,
					linkedinSharesCount,
					whatsAppSharesCount,
					companyId,
					facebookShareLastDate,
					twitterShareLastDate,
					linkedinShareLastDate,
					whatsAppShareLastDate,
				}).catch((error) =>
					console.error('Update User Job Share Count Error:', error)
				);
			} else {
				await onCreateUserJobShare({
					userId: currentUserId,
					jobId,
					facebookSharesCount,
					twitterSharesCount,
					linkedinSharesCount,
					whatsAppSharesCount,
					companyId,
					facebookShareLastDate,
					twitterShareLastDate,
					linkedinShareLastDate,
					whatsAppShareLastDate,
				}).catch((error) =>
					console.error('Create User Job Share Count Error:', error)
				);
			}
		} else {
			await onCreateUserJobShare({
				userId: currentUserId,
				jobId,
				facebookSharesCount,
				twitterSharesCount,
				linkedinSharesCount,
				whatsAppSharesCount,
				companyId,
				facebookShareLastDate,
				twitterShareLastDate,
				linkedinShareLastDate,
				whatsAppShareLastDate,
			}).catch((error) =>
				console.error('Create User Job Share Count Error:', error)
			);
		}
	};

	return (
		<div style={{ border: '1px solid #dddddd', padding: '10px', backgroundColor: '#d8e7f3', borderRadius: '18px' }}>
			<div style={{ textAlign: 'center', fontSize: '1.22em', lineHeight: '1' }}>
				{ml('or', currentUser, allMultiLingualData)} <span style={{ fontWeight: '900' }}>{ml('Share Your Referral Link', currentUser, allMultiLingualData)}</span>
			</div>
			<div style={{ textAlign: 'center', color: 'var(--dove-gray)', marginBottom: '12px' }}>
				{ml('Anyone that uses your link will create a referral by you.', currentUser, allMultiLingualData)}
			</div>
			<Search
				className="referral-form-share-link custom-search-grp"
				style={{ marginBottom: 0, width: '70%' }}
				enterButton={ml('Copy', currentUser, allMultiLingualData)}
				value={previewUrl}
				onSearch={() => {
					navigator.clipboard
						.writeText(previewUrl)
						.then(() => {
							message.success(ml('Copied to clipboard', currentUser, allMultiLingualData));
						})
						.catch((error) => {
							console.error('Failed to copy to clipboard', error);
							message.error(ml('Failed to copy to clipboard', currentUser, allMultiLingualData));
						});
				}}
			/>
			<ul className="inline-social-icons" style={{
				listStyle: 'none',
				display: 'inline-flex',
				justifyContent: 'center',
				width: '30%',
				padding: 0,
				marginBottom: 0
			}}>
				<li
					className={
						shareButtonsDisabled ? 'ss-icon-disable' : null
					}
					style={{ margin: '0 4px' }}
					onClick={() => handleClicked('facebook')}
				>
					<FacebookShareButton
						style={{ cursor: 'pointer', textAlign: 'center' }}
						url={previewUrl}
						onShareWindowClose={updateShareCount}
					>
						<FacebookIcon round alt="Facebook" size={32} />
					</FacebookShareButton>
				</li>
				<li
					className={
						shareButtonsDisabled ? 'ss-icon-disable' : null
					}
					style={{ margin: '0 4px' }}
					onClick={() => handleClicked('twitter')}
				>
					<TwitterShareButton
						style={{ cursor: 'pointer', textAlign: 'center' }}
						url={previewUrl}
						onShareWindowClose={updateShareCount}
					>
						<XIcon round alt="X" size={32} />
					</TwitterShareButton>
				</li>
				<li
					className={
						shareButtonsDisabled ? 'ss-icon-disable' : null
					}
					style={{ margin: '0 4px' }}
					onClick={() => handleClicked('linkedin')}
				>
					<LinkedinShareButton
						style={{ cursor: 'pointer', textAlign: 'center' }}
						url={previewUrl}
						windowWidth={750}
						windowHeight={600}
						onShareWindowClose={updateShareCount}
					>
						<LinkedinIcon round alt="LinkedIn" size={32} />
					</LinkedinShareButton>
				</li>
				{!currentUser?.company?.hideWhatsApp && (
					<li
						className={
							shareButtonsDisabled ? 'ss-icon-disable' : null
						}
						style={{ margin: '0 4px' }}
						onClick={() => handleClicked('whatsapp')}
					>
						<WhatsappShareButton
							style={{ cursor: 'pointer', textAlign: 'center' }}
							url={previewUrl}
							onShareWindowClose={updateShareCount}
						>
							<WhatsappIcon round alt="Whatsapp" size={32} />
						</WhatsappShareButton>
					</li>
				)}
			</ul>
			<div>
				<Collapse defaultActiveKey={[0]} ghost onChange={handleCollapseChange}>
					<Collapse.Panel
						showArrow={false}
						header={
							<div
								style={{
									color: 'var(--theme-primary)',
									fontWeight: 900,
									textAlign: 'center',
									marginTop: '10px'
								}}
							>
								{activeKey.includes('1') ? ml('Hide QR Code', currentUser, allMultiLingualData) : ml('Show QR Code', currentUser, allMultiLingualData)}
							</div>
						}
						key="1"
					>
						{generatedQRCode &&
							<div className="share-qr-code">
								<img src={generatedQRCode} alt="QR Code" />
							</div>
						}
					</Collapse.Panel>
				</Collapse>
			</div>
		</div>
	)
}

export default ShareSendReferralForm;
