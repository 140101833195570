export const queryReferralAuditLogs = `
query GetAuditLogs($referralId: ID!) {
  queryAuditLogsByReferralId(referralId: $referralId) {
    items {
      oldValue
      newValue
      userEmail
      eventTime
      fieldName
    }
  }
}
`;
