import { useState, useMemo } from 'react';
import { Card, Modal, message } from 'antd';
import { ProgressIndicator } from 'src/_shared/components/referral-progress-indicator/';
import MyReferralsBonusDetailsModal from 'src/_shared/components/MyReferralsBonusDetailsModalComponent.jsx';
import {
	conditionalStatusStyle,
	formatDate,
	lambda,
	mapReferralStatus,
	ml,
} from 'src/_shared/services/utils.js';
import ReferralContact from './ReferralContact';
import ReferralJob from './ReferralJob';

const ReferralCardComponent = ({
	referral,
	currentUser,
	bonuses = [],
	currentTieredBonus,
	allMultiLingualData,
	currentCurrencyRate,
	currentCurrencySymbol,
	ownershipSettings,
}) => {
	const [visible, setVisible] = useState(false);
	const dateFormat = currentUser?.dateFormat ?? 'MM/DD/YYYY';
	const languageCode = currentUser?.languageCode ?? 'en';

	const ownershipSettingsWithExpiryDate = {
		...ownershipSettings,
		expiryDate: referral?.ownershipEndDate ? formatDate(
			referral.ownershipEndDate,
			languageCode,
			dateFormat
		) : null
	};

	const referralDate = formatDate(
		referral?.dateCreated || referral?.referralDate,
		languageCode,
		dateFormat
	);

	const bonusTiers = useMemo(() => {
		return bonuses
			.filter(
				(bonus) =>
					bonus.companyId === currentUser.companyId &&
					bonus.referralId === referral.id
			)
			.sort((a, b) => new Date(a.earnedDate) - new Date(b.earnedDate));
	}, [bonuses, currentUser.companyId, referral.id]);

	const myReferralBonus = useMemo(() => {
		return bonuses.filter(
			(bonus) =>
				bonus.referralId === referral.id && bonus.recipientType === 'employee'
		);
	}, [bonuses, referral.id]);

	const totalBonus = useMemo(() => {
		return myReferralBonus.reduce(
			(accumulator, current) => accumulator + current.amountDue,
			0
		);
	}, [myReferralBonus]);

	const handleCancel = () => setVisible(false);
	const showModal = () => setVisible(true);

	const handleResendReferral = async () => {
		const { id, name, brandColor, logo, whiteLabel } = currentUser.company;
		const company = {
			id,
			name,
			brandColor,
			logo,
			whiteLabel,
		};
		let endpoint = '';

		const host = window.location.hostname;
		if (referral.referralType === 'text') {
			endpoint = ['localhost', 'app.erinapp.com'].includes(host)
				? 'resend-referral'
				: 'dev-resend-referral';
		} else if (referral.referralType === 'email') {
			endpoint =
				host === 'localhost'
					? 'base-white-resend-referral-dev-app'
					: 'base-white-resend-referral-prod-app';
		}

		try {
			await lambda({ endpoint, variables: { referral, company } });
			message.success(ml('Referral resent successfully!', currentUser, allMultiLingualData));
		} catch (error) {
			message.error(ml('Error resending referral', currentUser, allMultiLingualData));
			console.error(error);
		}
	};
	// Will use the custom stage if the status on the referral is interviewing
	const customStatus = referral.status === 'interviewing' && referral.customStatus
		? referral.customStatus
		: referral.status;

	return (
		<Card className="referral-card my-referrals-referral-card">
			<div className="referral-card-content">
				<div className="referral-info-row">
					<ReferralContact
						referral={referral}
						contact={referral.contact}
						referralDate={referralDate}
						referralType={referral.referralType}
						ownershipSettings={ownershipSettingsWithExpiryDate}
						handleResendReferral={handleResendReferral}
						status={referral.status}
						allMultiLingualData={allMultiLingualData}
						currentUser={currentUser}
					/>

					<ReferralJob
						showModal={showModal}
						referral={referral}
						myReferralBonus={myReferralBonus}
						totalBonus={totalBonus}
						allMultiLingualData={allMultiLingualData}
						currentTieredBonus={currentTieredBonus}
						currentUser={currentUser}
					/>
				</div>
			</div>

			<div className="referral-footer-card">
				<div className="my-progressbar-wrap progressbar-wrap">
					<ProgressIndicator
						type="referral"
						points={5}
						currentUser={currentUser}
						referral={referral}
						status={referral?.status}
						allMultiLingualData={allMultiLingualData}
					/>
				</div>
				<div className="mt-4">
					{ml("Status", currentUser, allMultiLingualData)}:{' '}
					<strong>
						<span style={conditionalStatusStyle(referral.status)}>
							{ml(mapReferralStatus(customStatus, currentUser.company), currentUser, allMultiLingualData)}
						</span>
					</strong>
				</div>
			</div>

			<Modal
				title={ml("Bonus Details", currentUser, allMultiLingualData)}
				open={visible}
				footer={null}
				onCancel={handleCancel}
			>
				<MyReferralsBonusDetailsModal
					translatedTitle={referral?.job?.title || null}
					bonusesData={bonusTiers}
					visible={visible}
					currentTieredBonus={currentTieredBonus}
					status={referral.status}
					currentCurrencyRate={currentCurrencyRate}
					currentCurrencySymbol={currentCurrencySymbol}
					currentUser={currentUser}
					data={referral}
					handleCancel={handleCancel}
					allMultiLingualData={allMultiLingualData}
				/>
			</Modal>
		</Card>
	);
};

export default ReferralCardComponent;
