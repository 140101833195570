export const GetEmployeeDetails = `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      accountClaimId
      admin
      careerProfile
      cognitoId
      companyId
      dateCreated
      dateFormat
      subCompanyId
      subCompany {
        companyId
        id
        logo {
          bucket
          key
          region
        }
        name
      }
      company {
        id
        name
        defaultBonusAmount
        contactIncentiveBonus
        dashboardReferralPolicyText
        bonusEarnedNote
        allowSelfReferrals
        allowInternalMobility
        internalMobilityImage {
          bucket
          region
          key
        }
        websiteUrl
        confirmCompliance
        ssoGoogleDomain
        confirmContactEmails
        brandColor
        whiteLabel
        theme
        symbol {
          bucket
          region
          key
        }
        background {
          bucket
          region
          key
        }
        errorImage {
          bucket
          region
          key
        }
        pointsSettings
        enableGeneralReferrals
        referralStatus
        referralCustomStatuses
        allowSelfReferralsInternalLink
        disableManagerPermissionsByType
      }
      emailAddress
      employeeId
      role
      firstName
      lastName
      title
      location
      points
      currency
      languageCode
      avatar {
        bucket
        region
        key
      }
      departmentId
      department {
        id
        companyId
        name
        active
        totalUsers
      }
      userGroupId
      userGroup {
        id
        measurement
        name
        currency
      }
      managedDepartments {
        id
        userId
        departmentId
        department {
          id
          name
          companyId
          active
          totalUsers
        }
      }
      lastLogin
      lastMobileLogin
      lastNotificationCheck
      incentiveEligible
      totalReferrals
      active
    }
  }
`;
