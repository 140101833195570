import React, { useState } from 'react';
import RewardList from '../rewardListPresentation';
import useBadgeList from '../hooks/useGetBadgeList';
import useRewardDetails from '../hooks/useRewardDetails';

const RewardListContainer = (props) => {
    const {
        handleIconClickEvent,
        title,
        badgeType,
        shouldRefresh,
        setShouldRefresh,
        isDetailsShown,
        isPointsShown = false,
        getMyBadge = false,
        isIssuedVisible = false
    } = props;

    const [isViewRewardModalVisible, setIsViewRewardModalVisible] = useState(false);

    const { rewardDetails, isModalLoaderVisible, handleOpenViewReward } = useRewardDetails(setIsViewRewardModalVisible);

    const { isLoading, badgeList, hasMore, fetchMoreData } = useBadgeList(badgeType, shouldRefresh, setShouldRefresh, getMyBadge);

    const handleCloseViewReward = () => { setIsViewRewardModalVisible(false) }

    return (

    <>
        {badgeList?.length > 0  || badgeType === 'On-the-Spot' ? (
        <RewardList
          handleIconClickEvent={handleIconClickEvent}
          title={title}
          badgeType={badgeType}
          hasMore={hasMore}
          isLoading={isLoading}
          badgeList={badgeList}
          fetchMoreData={fetchMoreData}
          isDetailsShown={isDetailsShown}
          isPointsShown={isPointsShown}
          handleCloseViewReward={handleCloseViewReward}
          isViewRewardModalVisible={isViewRewardModalVisible}
          rewardDetails={rewardDetails}
          isModalLoaderVisible={isModalLoaderVisible}
          handleOpenViewReward={handleOpenViewReward}
          getMyBadge={getMyBadge}
          isIssuåedVisible={isIssuedVisible}
        />
        ) : null}
    </>
    );
};

export default RewardListContainer;
