import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { createReferral } from 'src/_shared/api/graphql/custom/referrals';
import uuid from 'uuid/v4';
import {
	createContact,
	deleteContact,
	listOnDeckContacts,
	queryContactsByUserIdIndex,
	updateContact,
} from '../../graphql/custom/contacts';
import { queryContactsByCompanyIdOnDeckStatusIndex } from '../../graphql/custom/contacts/queryContactsByCompanyIdOnDeckStatusGraphql.js';

const continuouslyFetchMore = async (fetchMore, nextToken) => {
	if (!nextToken) return;

	try {
		const result = await fetchMore({
			variables: { after: nextToken },
			updateQuery(prev, { fetchMoreResult }) {
				if (!fetchMoreResult) return prev;

				return {
					...prev,
					queryContactsByCompanyIdOnDeckStatusIndex: {
						...prev.queryContactsByCompanyIdOnDeckStatusIndex,
						items: [
							...prev.queryContactsByCompanyIdOnDeckStatusIndex.items,
							...fetchMoreResult.queryContactsByCompanyIdOnDeckStatusIndex
								.items,
						],
						nextToken:
							fetchMoreResult.queryContactsByCompanyIdOnDeckStatusIndex
								.nextToken,
					},
				};
			},
		});

		const newNextToken = get(
			result,
			'data.queryContactsByCompanyIdOnDeckStatusIndex.nextToken'
		);

		if (newNextToken) {
			await continuouslyFetchMore(fetchMore, newNextToken);
		}
	} catch (error) {
		console.error('Error in continuous fetch:', error);
	}
};

const createDeckStatusQuery = (deckStatus) => {
	const isOnDeck = deckStatus === 'onDeck';
	const prefix = isOnDeck ? 'on' : 'off';

	return graphql(gql(queryContactsByCompanyIdOnDeckStatusIndex), {
		options(props) {
			const companyId = get(props, 'currentUser.company.id');

			return {
				context: {
					headers: {
						'x-frame-options': 'deny',
					},
				},
				variables: {
					companyId,
					onDeckStatus: deckStatus,
					first: 1000,
					after: null,
				},
				fetchPolicy: 'cache-and-network',
				skip: !companyId,
			};
		},
		props({ data: query }) {
			const contacts = get(
				query,
				'queryContactsByCompanyIdOnDeckStatusIndex.items',
				[]
			);
			const nextToken = get(
				query,
				'queryContactsByCompanyIdOnDeckStatusIndex.nextToken'
			);
			const loading = get(query, 'loading', true);
			const error = get(query, 'error');

			// Start background fetching once we have initial data
			if (nextToken && contacts.length > 0 && !loading) {
				continuouslyFetchMore(query.fetchMore, nextToken);
			}

			return {
				[`${prefix}DeckContactsByStatus`]: contacts,
				[`${prefix}DeckLoading`]: loading,
				[`${prefix}DeckError`]: error,
				[`${prefix}DeckHasMore`]: Boolean(nextToken),
				[`fetchMore${prefix ? prefix.charAt(0).toUpperCase() + prefix.slice(1) : ''}Deck`]() {
					if (!nextToken) return;
					return query.fetchMore({
						variables: { after: nextToken },
						updateQuery(prev, { fetchMoreResult }) {
							if (!fetchMoreResult) return prev;
							return {
								...prev,
								queryContactsByCompanyIdOnDeckStatusIndex: {
									...prev.queryContactsByCompanyIdOnDeckStatusIndex,
									items: [
										...prev.queryContactsByCompanyIdOnDeckStatusIndex.items,
										...fetchMoreResult.queryContactsByCompanyIdOnDeckStatusIndex
											.items,
									],
									nextToken:
										fetchMoreResult.queryContactsByCompanyIdOnDeckStatusIndex
											.nextToken,
								},
							};
						},
					});
				},
			};
		},
	});
};

export const withListOnDeckContacts = (Component) => {
	return compose(
		// Original query remains for backward compatibility
		graphql(gql(listOnDeckContacts), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					filter: {
						companyId: { eq: props.currentUser.companyId },
					},
					limit: 10_000,
					nextToken: props.nextToken ? props.nextToken : null,
				},
				fetchPolicy: 'cache-and-network',
			}),
			props(response, props) {
				const onDeckContacts = get(
					response,
					['data', 'listOnDeckContacts', 'items'],
					get(props, 'contacts', [])
				);
				const nextToken = get(
					response,
					['data', 'listOnDeckContacts', 'nextToken'],
					null
				);
				const onFetchMore = makeOnFetchMore(response.data.fetchMore, nextToken);

				return { onDeckContacts, onFetchMore, nextToken };
			},
		}),
		// Queries for both deck statuses
		createDeckStatusQuery('onDeck'),
		createDeckStatusQuery('offDeck'),
		graphql(gql(queryContactsByUserIdIndex), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					userId: props.currentUser.id,
				},
				first: 1000,
				after: props.contactsNextToken ? props.contactsNextToken : null,
				fetchPolicy: 'cache-and-network',
			}),
			props(response, previous) {
				const contacts = get(
					response,
					['data', 'queryContactsByUserIdIndex', 'items'],
					get(previous, 'contacts', [])
				);

				const contactsNextToken = get(
					response,
					['data', 'queryContactsByUserIdIndex', 'nextToken'],
					null
				);
				const onFetchMoreContacts = makeOnFetchMoreContacts(
					response.data.fetchMore,
					contactsNextToken
				);

				return { contacts, onFetchMoreContacts, contactsNextToken };
			},
		}),

		graphql(gql(createContact), {
			props: (props) => ({
				ImportedCreateContact(input) {
					const optimisticResponseData = {
						id: uuid(),
						...input.input,
						referrals: null,
						__typename: 'Contact',
					};
					props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
						optimisticResponse: {
							__typename: 'Mutation',
							createContact: {
								__typename: 'createContact',
								...optimisticResponseData,
							},
						},
						update(proxy, { data: { createContact } }) {
							const data = proxy.readQuery({
								query: gql(listOnDeckContacts),
								variables: {
									filter: {
										companyId: { eq: props.ownProps.currentUser.companyId },
										onDeckStatus: { gt: 0 },
									},
									limit: 10_000,
									nextToken: props.ownProps.nextToken
										? props.ownProps.nextToken
										: null,
								},
								fetchPolicy: 'cache-and-network',
							});
							if (
								!data.listOnDeckContacts.items.find(
									(contact) => contact.id === createContact.id
								)
							) {
								data.listOnDeckContacts.items.push(createContact);
							}

							proxy.writeQuery({
								query: gql(listOnDeckContacts),
								context: {
									headers: {
										'x-frame-options': 'deny', // This header will reach the server
									},
								},
								variables: {
									filter: {
										companyId: { eq: props.ownProps.currentUser.companyId },
										onDeckStatus: { gt: 0 },
									},
									limit: 10_000,
									nextToken: props.ownProps.nextToken
										? props.ownProps.nextToken
										: null,
								},
								fetchPolicy: 'cache-and-network',
								data,
							});
						},
					});
				},
			}),
		}),
		graphql(gql(updateContact), {
			props: (props) => ({
				onUpdateContact(input) {
					const optimisticResponseData = {
						...input,
						__typename: 'Contact',
					};
					props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: { input: { ...input } },
						optimisticResponse: {
							__typename: 'Mutation',
							updateContact: {
								__typename: 'updateContact',
								...optimisticResponseData,
							},
						},
						update(proxy, { data: { updateContact } }) {
							const data = proxy.readQuery({
								query: gql(listOnDeckContacts),
								context: {
									headers: {
										'x-frame-options': 'deny', // This header will reach the server
									},
								},
								variables: {
									filter: {
										companyId: { eq: props.ownProps.currentUser.companyId },
										onDeckStatus: { gt: 0 },
									},
									limit: 10_000,
									nextToken: props.ownProps.nextToken
										? props.ownProps.nextToken
										: null,
								},
								fetchPolicy: 'cache-and-network',
							});
							if (
								!data.listOnDeckContacts.items.find(
									(contact) => contact.id === updateContact.id
								)
							) {
								data.listOnDeckContacts.items.push(updateContact);
							}

							proxy.writeQuery({
								query: gql(listOnDeckContacts),
								context: {
									headers: {
										'x-frame-options': 'deny', // This header will reach the server
									},
								},
								variables: {
									filter: {
										companyId: { eq: props.ownProps.currentUser.companyId },
										onDeckStatus: { gt: 0 },
									},
									limit: 10_000,
									nextToken: props.ownProps.nextToken
										? props.ownProps.nextToken
										: null,
								},
								fetchPolicy: 'cache-and-network',
								data,
							});
						},
					});
				},
			}),
		}),
		graphql(gql(updateContact), {
			props: (props) => ({
				onUpdateContactDeckStatus(input) {
					const optimisticResponseData = {
						...input.input,
						__typename: 'Contact',
					};
					return props.mutate({
						variables: { input: { ...input } },
						optimisticResponse: {
							__typename: 'Mutation',
							updateContact: {
								__typename: 'updateContact',
								...optimisticResponseData,
							},
						},
					});
				},
			}),
		}),
		graphql(gql(createReferral), {
			props: (props) => ({
				onCreateReferral(input) {
					const optimisticResponseData = {
						...input.input,
					};
					return props.mutate({
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							createReferral: {
								__typeName: 'createReferral',
								...optimisticResponseData,
							},
						},
					});
				},
			}),
		}),
		graphql(gql(deleteContact), {
			props: (props) => ({
				onDeleteContact(input) {
					const optimisticResponseData = {
						...props.contact,
						...input.input,
					};
					props.mutate({
						context: {
							headers: {
								'x-frame-options': 'deny', // This header will reach the server
							},
						},
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							deleteContact: {
								...optimisticResponseData,
							},
						},
						update(proxy, { data: { deleteContact } }) {
							const data = proxy.readQuery({
								query: gql(listOnDeckContacts),
								variables: {
									companyId: props.ownProps.currentUser.company.id,
									first: 2000,
									after: '',
								},
							});
							data.listOnDeckContacts.items =
								data.listOnDeckContacts.items.filter(
									(contact) => contact.id !== deleteContact.id
								);
							proxy.writeQuery({
								query: gql(listOnDeckContacts),
								data,
								variables: {
									companyId: props.ownProps.currentUser.company.id,
									first: 2000,
									after: '',
								},
							});
						},
					});
				},
			}),
		})
	)(Component);
};

const makeOnFetchMore = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				return {
					...previous,
					loading: false,
					listOnDeckContacts: {
						...previous.listOnDeckContacts,
						...fetchMoreResult.listOnDeckContacts,
						items: [
							...previous.listOnDeckContacts.items,
							...fetchMoreResult.listOnDeckContacts.items,
						],
					},
				};
			},
		});
	};
};

const makeOnFetchMoreContacts = (fetchMore, nextToken) => {
	if (!nextToken) {
		return null;
	}

	return () => {
		fetchMore({
			variables: { nextToken },
			updateQuery(previous, { fetchMoreResult }) {
				if (!fetchMoreResult) {
					return previous;
				}

				return {
					...previous,
					loading: false,
					queryContactsByUserIdIndex: {
						...previous.queryContactsByUserIdIndex,
						...fetchMoreResult.queryContactsByUserIdIndex,
						items: [
							...previous.queryContactsByUserIdIndex.items,
							...fetchMoreResult.queryContactsByUserIdIndex.items,
						],
					},
				};
			},
		});
	};
};
