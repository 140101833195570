import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useState } from 'react';
import { ml } from 'src/_shared/services/utils';

const items = [
	{ key: 'US', label: 'English' },
	{ key: 'AR', label: 'العربية' },
	{ key: 'CS', label: 'Čeština' },
	{ key: 'DE', label: 'Deutsch' },
	{ key: 'ES', label: 'Español' },
	{ key: 'FR', label: 'Français' },
	{ key: 'FR-CA', label: 'Français (Canada)' },
	{ key: 'IT', label: 'Italiano' },
	{ key: 'HU', label: 'Magyar' },
	{ key: 'NL', label: 'Nederlands' },
	{ key: 'PL', label: 'Polski' },
	{ key: 'PT', label: 'Português' },
	{ key: 'PT-BR', label: 'Português (Brasil)' },
	{ key: 'TL', label: 'Tagalog' },
	{ key: 'TR', label: 'Türkçe' },
	{ key: 'RU', label: 'Русский' },
	{ key: 'ZH-CN', label: '简体中文' },
	{ key: 'JA', label: '日本語' }
];

const ReferralLanguageSelector = (props) => {
	const { currentUser, allMultiLingualData, setReferralLanguage } = props;
	const [selectedLanguage, setSelectedLanguage] = useState(props.currentUser?.languageCode || 'US');

	const handleButtonClick = (event) => {
		setSelectedLanguage(event.key);
		setReferralLanguage(event.key);
	};

	return (
		<div
			className={ 'referral-language-selector'}
			style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}
		>
			<Dropdown
				menu={{
					items,
					selectable: true,
					defaultSelectedKeys: [selectedLanguage],
					onClick: handleButtonClick,
				}}
			>
				<div style={{ cursor: 'pointer', display: 'inline-block', fontSize: 12, marginTop: '2px' }}>
					{ml('Sending in', currentUser, allMultiLingualData) + ' '}<span style={{color: 'var(--theme-primary)'}}>{items.find(item => item.key === selectedLanguage)?.label}</span> <DownOutlined />
				</div>
			</Dropdown>
		</div>
	);
};

export default ReferralLanguageSelector;
