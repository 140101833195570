import { connect } from 'react-redux';
import { withGetCompany } from 'src/_shared/api/components/settings/';
import { compose } from 'src/_shared/services/utils.js';
import { dashboardActions, userActions } from 'src/actions.js';
import { CustomReportSettingsComponent } from './CustomReportSettings.jsx';

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		companyId: state.user.currentUser.companyId,
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
		updateUserCompany(values) {
			dispatch(userActions.updateUserCompany(values));
		},
	};
};

const CustomReportSettingsWithAPI = compose(withGetCompany)(
	CustomReportSettingsComponent
);

export const CustomReportSettings = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(CustomReportSettingsWithAPI);
