export const MY_REWARDS_API_ENDPOINTS = {
  GET_REWARD_POINTS_AND_BONUSES: 'api/v1/badge/assignments/rewards',
  EMPLOYEE_ADDRESS: 'api/v1/employees/address',

  GET_PENDING_PHYSICAL_AWARDS: 'api/v1/physical-award-activity/employees/pending-awards',
  MANAGE_PHYSICAL_AWARD_CONFIRMATION_STATUS: 'api/v1/physical-award-activity'
}
export const CONFIRMATION_STATUS = {
  CONFIRMED: 'Confirmed',
  DECLINED: 'Declined'
}