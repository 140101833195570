import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _ from 'lodash';
import get from 'lodash/get';
import { useCallback, useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { getUserDataById } from 'src/_shared/api/graphql/custom/users/';
import { SearchComponent } from 'src/_shared/components/search';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import fileIcon from '../../assets/erin_lightgray.png';
import {
	getSetErrorImageURL,
	logout,
	ml,
	searchData,
} from '../../services/utils';
import { ExportSelector } from '../export-selector/ExportSelector.jsx';
import OnDeckTable from './OnDeckTableComponent.jsx';
import { ToggleOnOffDeck } from './toggleOnOffDeck';

const filterOnDeckKey = 'onDeck';
const filterOffDeckKey = 'offDeck';

const OnDeckComponent = ({
	currentUser,
	client,
	location,
	allMultiLingualData,
	onDeckContactsByStatus,
	offDeckContactsByStatus,
	onDeckLoading,
	offDeckLoading,
	hasMoreContacts,
	onFetchMore,
	onDeckHasMore,
	offDeckHasMore,
	fetchMoreOnDeck,
	fetchMoreOffDeck,
	onUpdate,
	ImportedCreateContact,
	...props
}) => {
	// State management
	const [state, setState] = useState({
		company: get(currentUser, 'company'),
		filterOnDeckStatus: filterOnDeckKey,
		query: '',
		isLoadingMore: false,
		currentPage: 1,
		hasLoadedInitialData: false,
		resultData: null,
		errorImageURL: null,
	});

	// Memoized helper functions
	const getFilteredAndSortedContacts = useCallback(() => {
		const { query, filterOnDeckStatus } = state;

		const contacts =
			filterOnDeckStatus === filterOnDeckKey
				? onDeckContactsByStatus || []
				: offDeckContactsByStatus || [];

		const searchedContacts = searchData(
			['firstName', 'lastName', 'emailAddress'],
			query,
			contacts
		);

		return _.sortBy(searchedContacts, 'dateCreated').reverse();
	}, [
		state.query,
		state.filterOnDeckStatus,
		onDeckContactsByStatus,
		offDeckContactsByStatus,
	]);

	const getExportData = useCallback(
		(contacts) => {
			const headers = {
				candidate: ml('Candidate', currentUser, allMultiLingualData),
				info: ml('Info', currentUser, allMultiLingualData),
				email: ml('Email', currentUser, allMultiLingualData),
				phoneNumber: ml('Phone Number', currentUser, allMultiLingualData),
				referredBy: ml('Referred By', currentUser, allMultiLingualData),
				dateReferred: ml('Date Referred', currentUser, allMultiLingualData),
			};

			return contacts.map((item) => ({
				[headers.candidate]: `${get(item, 'firstName', '')} ${get(item, 'lastName', '')}`,
				[headers.info]: get(item, 'onDeckNote'),
				[headers.email]: get(item, 'emailAddress'),
				[headers.phoneNumber]: get(item, 'phoneNumber'),
				[headers.referredBy]: `${get(item, ['user', 'firstName'], '')} ${get(item, ['user', 'lastName'], '')}`,
				[headers.dateReferred]: dayjs(get(item, 'onDeckDate', '')).format(
					'M/D/YYYY'
				),
			}));
		},
		[currentUser, allMultiLingualData]
	);

	// Event handlers
	const handleTableChange = useCallback(
		(pagination) => {
			const hasMore =
				state.filterOnDeckStatus === filterOnDeckKey
					? onDeckHasMore
					: offDeckHasMore;

			const fetchMore =
				state.filterOnDeckStatus === filterOnDeckKey
					? fetchMoreOnDeck
					: fetchMoreOffDeck;

			if (
				pagination.current > state.currentPage &&
				hasMore &&
				!state.isLoadingMore
			) {
				setState((prev) => ({
					...prev,
					isLoadingMore: true,
					currentPage: pagination.current,
				}));

				fetchMore().then(() => {
					setState((prev) => ({ ...prev, isLoadingMore: false }));
				});
			} else {
				setState((prev) => ({ ...prev, currentPage: pagination.current }));
			}
		},
		[
			state.currentPage,
			state.filterOnDeckStatus,
			state.isLoadingMore,
			onDeckHasMore,
			offDeckHasMore,
			fetchMoreOnDeck,
			fetchMoreOffDeck,
		]
	);

	// Side effects
	useEffect(() => {
		const initializeComponent = async () => {
			// Google Analytics initialization
			const host = window.location.hostname;
			if (host === 'referrals.aus.com') {
				ReactGA.initialize('UA-128630809-2');
				ReactGA.pageview(window.location.pathname + window.location.search);
			} else if (host === 'app.erinapp.com') {
				ReactGA.initialize('UA-128630809-3');
				ReactGA.pageview(window.location.pathname + window.location.search);
			}

			// Get user data
			try {
				const userId = get(currentUser, 'id', null);
				if (userId) {
					const { data } = await client.query({
						query: gql(getUserDataById),
						variables: { id: userId },
						fetchPolicy: 'network-only',
					});
					setState((prev) => ({
						...prev,
						resultData: get(data, 'getUser', null),
					}));
				}
			} catch (error) {
				console.error(error);
			}

			// Check JWT and logout if needed
			const jwt = Cookies.get('jwt');
			if (state.resultData) {
				const expirationDoneByToken = get(
					state.resultData,
					'expirationDoneByToken',
					null
				);
				const expires = get(state.resultData, 'expires');
				if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
					logout(client);
				}
			}

			// Get error image URL
			const errorImageURL = await getSetErrorImageURL(
				currentUser?.company?.errorImage?.key
			);
			if (errorImageURL) {
				setState((prev) => ({ ...prev, errorImageURL }));
			}
		};

		initializeComponent();
	}, [currentUser, client, location.pathname]);

	// Handle loading states
	useEffect(() => {
		if (!onDeckLoading && !offDeckLoading) {
			setState((prev) => ({
				...prev,
				hasLoadedInitialData: true,
				isLoadingMore: false,
			}));
		}
	}, [onDeckLoading, offDeckLoading]);

	// Handle data updates
	useEffect(() => {
		if (props.onDeckContactsByStatus !== onDeckContactsByStatus) {
			setState((prev) => ({ ...prev, isLoadingMore: false }));

			const sortedContacts = getFilteredAndSortedContacts();

			if (
				sortedContacts.length === 0 &&
				hasMoreContacts &&
				!onDeckLoading &&
				!state.isLoadingMore
			) {
				setState((prev) => ({ ...prev, isLoadingMore: true }));
				onFetchMore();
			}
		}
	}, [
		onDeckContactsByStatus,
		props.onDeckContactsByStatus,
		hasMoreContacts,
		onDeckLoading,
		onFetchMore,
		getFilteredAndSortedContacts,
	]);

	const isLoading = onDeckLoading && offDeckLoading;
	const whiteLabel = get(state.company, 'whiteLabel');
	const sortedContacts = getFilteredAndSortedContacts();
	const exportTableData = getExportData(sortedContacts);

	if (isLoading || !state.hasLoadedInitialData) {
		return <Spinner company={state.company} />;
	}

	return (
		<main>
			<div className="page-title">
				<h2 className="page-heading">
					{ml('Referral Leads', currentUser, allMultiLingualData)}
				</h2>
				<ul className="info-action">
					<li>
						{ml('Active Referral Leads', currentUser, allMultiLingualData)}:{' '}
						{onDeckContactsByStatus?.length ?? 0}
					</li>
					<li>
						{ml('Inactive Referral Leads', currentUser, allMultiLingualData)}:{' '}
						{offDeckContactsByStatus?.length ?? 0}
					</li>
					<li>
						<ExportSelector
							title={ml('Export', currentUser, allMultiLingualData)}
							items={[
								{
									key: 1,
									label: (
										<Tooltip
											title={ml(
												`Instantly download an export of the filtered results.`,
												currentUser,
												allMultiLingualData
											)}
											placement="left"
										>
											{ml('Export View', currentUser, allMultiLingualData)}
										</Tooltip>
									),
									onClick: 'csv-export',
									data: exportTableData,
									reportType: 'referral-leads',
									companyName: state.company?.name,
								},
							]}
						/>
					</li>
				</ul>
			</div>
			<div className="referral-filter">
				<SearchComponent
					style={{ flex: 1 }}
					setQueryToState={(value) =>
						setState((prev) => ({ ...prev, query: value }))
					}
					searchQuery={state.query}
					placeholder={ml('Search', currentUser, allMultiLingualData)}
				/>
				<ToggleOnOffDeck
					filterOnDeckStatus={state.filterOnDeckStatus}
					offDeckJobsKey={filterOffDeckKey}
					activeText={ml('Active', currentUser, allMultiLingualData)}
					inactivetext={ml('Inactive', currentUser, allMultiLingualData)}
					offDeckLoading={offDeckLoading}
					onClick={(status) =>
						setState((prev) => ({
							...prev,
							filterOnDeckStatus: status,
							currentPage: 1,
						}))
					}
					onDeckJobsKey={filterOnDeckKey}
					onDeckLoading={onDeckLoading}
				/>
			</div>

			{sortedContacts.length > 0 ? (
				<div className="table-card">
					<OnDeckTable
						{...props}
						loading={state.isLoadingMore}
						currentUser={currentUser}
						allMultiLingualData={allMultiLingualData}
						languageCode={currentUser.languageCode}
						whiteLabel={whiteLabel}
						contacts={sortedContacts}
						pagination={{
							pageSize: 15,
							current: state.currentPage,
							total: sortedContacts.length,
							showSizeChanger: false,
						}}
						onDeckStatusTitle=""
						onChange={handleTableChange}
					/>
				</div>
			) : state.hasLoadedInitialData ? (
				<div className="no-content">
					{whiteLabel ? (
						<img
							src={state.errorImageURL}
							alt="error image"
							className="no-content-icon"
						/>
					) : (
						<img alt="erin-logo" className="no-content-icon" src={fileIcon} />
					)}
					<p className="no-content-text">
						There are no Referral Leads at this time.
					</p>
				</div>
			) : (
				<Spinner company={state.company} />
			)}
		</main>
	);
};

export default withApollo(OnDeckComponent);
